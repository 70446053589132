/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import librariesService from "../../../../services/LibrariesService";
import filtersService from "../../../../services/FiltersService";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

//COMPONENTS
import Input from "../../Elements/Forms/Input.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import SingleSelectionDropdown from "../../Elements/Forms/SingleSelectionDropdown.js";

const StyledSaveReportModal = styled(NanoFlex)`
  width: 300px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  .saveReportModalModalContent {
    .headerModal {
      position: relative;

      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 16px 24px;
      h5 {
        text-transform: uppercase;
      }
      .closeBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: auto;
        width: auto;

        svg {
          width: 10px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
    .formContainer {
      height: auto;
      padding: 24px;
      .formWrapper {
        margin-bottom: 16px;
        .titleForm {
          margin-bottom: 4px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bottomWrapper {
      padding: 24px;
      height: auto;
      border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    }
  }
`;

export class SaveReportModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      folders: null,
      ...this.getDefaultSelection(),
    };
  };

  getDefaultSelection = () => {
    return {
      folderSelected: null,
      folderError: false,
      reportName: "",
      nameError: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
    this.getFolders();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    this.setState(this.getDefaultSelection(), () => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  getFolders = () => {
    librariesService
      .getFolders()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ folders: data, selectedIndex: 0 });
      });
  };

  saveReport = (event) => {
    // Prevent page refresh
    event.preventDefault();

    let hasErrors = false;

    if (this.state.reportName.trim() === "") {
      hasErrors = true;
      this.setState({ nameError: true });
    }

    if (!this.state.folderSelected) {
      hasErrors = true;
      this.setState({ folderError: true });
    }

    if (hasErrors) return;

    let model = {
      userId: this._user.Id,
      reportName: this.state.reportName,
      reportType: this.props.reportType ?? 1,
      columns: this.props.filtersSelected.columns,
      lines: this.props.filtersSelected.lines,
      folderId: this.state.folderSelected ?? 0,
      filters: this.props.filtersSelected,
    };

    delete model.filters.filterRecap;

    filtersService
      .saveFilters(model)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState(this.getDefaultSelection(), () => {
          if (this.props.nextAction) this.props.nextAction();
        });
      });
  };

  render() {
    return (
      <StyledSaveReportModal justifyContent="flex-start">
        <NanoFlex className="saveReportModalModalContent" flexDirection="column">
          <NanoFlex className="headerModal">
            <Type.h5>{this.translation.global.saveReport}</Type.h5>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <form onSubmit={this.saveReport}>
            <NanoFlex className="formContainer" flexDirection="column">
              <NanoFlex className="formWrapper">
                <Input title={this.translation.librairies.reportName} placeholder={this.translation.librairies.reportName} error={this.state.nameError} onChangeAction={(v) => this.setState({ reportName: v })} />
              </NanoFlex>
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">{this.translation.librairies.librairie}</Type.p>
                <SingleSelectionDropdown
                  error={this.state.folderError}
                  options={this.state.folders?.map((item) => {
                    return { id: item.id, name: item.description };
                  })}
                  selected={this.state.folderSelected}
                  onChangeSelection={(e) => this.setState({ folderSelected: e?.id })}
                />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bottomWrapper">
              <ButtonCTA label={this.translation.global.save} type="submit" />
            </NanoFlex>
          </form>
        </NanoFlex>
      </StyledSaveReportModal>
    );
  }
}
