import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";

//Components
import { NavBar } from "./NavBar.js";

const StyledLayout = styled(NanoFlex)``;

export class Layout extends Component {
  render() {
    return (
      <StyledLayout>
        <NavBar render={this.props.render} />
        {React.cloneElement(this.props.children, { render: this.props.render })}
      </StyledLayout>
    );
  }
}
