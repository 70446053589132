import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";

// COMPONENTS
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import TopButton from "../../../Global/Elements/Buttons/TopButton.js";
import ToggleButton from "../../../Global/Elements/Buttons/ToggleButton.js";
import Modal from "../../../Global/Elements/Modals/Modal.js";
import { SaveReportModal } from "../../../Global/Elements/Modals/SaveReportModal.js";
//Imgs
import Excel from "../../../Global/icons/excel.svg";
import Pdf from "../../../Global/icons/pdf.svg";
import Save from "../../../Global/icons/save.svg";

const StyledOptionsFilters = styled(NanoFlex)`
  height: auto;
  padding: 16px 0;
  user-select: none;
  .infoWrapper {
    margin-bottom: 8px;
    .asideWrapper {
      .optionBtnWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .elementWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export class OptionsFilters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSaveReport: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledOptionsFilters flexDirection="column">
        <Modal show={this.state.openSaveReport} content={<SaveReportModal reportType={this._reportType.Palmares} filtersSelected={this.props.filtersSelected} cancelAction={() => this.setState({ openSaveReport: false })} nextAction={() => this.setState({ openSaveReport: false })} />} />
        <NanoFlex className="infoWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper" justifyContent="flex-start">
            <NanoFlex className="optionBtnWrapper">
              <TopButton values={[3, 5, 10, 50]} disabled={!this.props.hasData} selectedValue={this.props.top} onSelectedValueChange={(v) => this.props.onTopChange(v)} />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="asideWrapper" justifyContent="flex-end">
            <NanoFlex className="optionBtnWrapper">
              <ToggleButton title={this.translation.palmares.numberRespondents} disabled={!this.props.hasData} active={this.props.showRespondants} clickAction={() => this.props.onShowRespondants(!this.props.showRespondants)} />
            </NanoFlex>
            {/* <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Excel} />
            </NanoFlex> */}
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon
                src={Pdf}
                disabled={!this.props.hasData}
                clickAction={() => {
                  if (this.props.onPdfExport) this.props.onPdfExport();
                }}
              />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Save} clickAction={() => this.setState({ openSaveReport: true })} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledOptionsFilters>
    );
  }
}
