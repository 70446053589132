import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

import { ReactComponent as Lock } from "../../icons/lock.svg";
import { ReactComponent as LockOpen } from "../../icons/lockOpen.svg";

const StyledToggleButtonPrive = styled(NanoFlex)`
  height: auto;
  width: auto;
  .toggleButtonPriveWrapper {
    padding: 8px;
    user-select: none;
    cursor: pointer;
    background-color: ${(props) => (props.active ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
    .iconBtnWrapper {
      width: auto;
      margin-right: 8px;
      svg {
        width: 18px;
        height: auto;
        path {
          fill: ${(props) => (props.active ? props.theme.color.main.white : props.theme.color.main.font)};
        }
      }
    }
    .lockOpen {
      display: ${(props) => (props.active ? "none" : "flex")};
    }
    .lock {
      display: ${(props) => (props.active ? "flex" : "none")};
    }
    .textBtnWrapper {
      width: auto;
      p {
        color: ${(props) => (props.active ? props.theme.color.main.white : props.theme.color.main.font)};
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default class ToggleButtonPrive extends BaseComponent {
  
  render() {
    return (
      <StyledToggleButtonPrive flexDirection="column" active={this.props.active} onClick={this.props.clickAction}>
        <NanoFlex className="toggleButtonPriveWrapper">
          <NanoFlex className="iconBtnWrapper">
            <LockOpen className="lockOpen" />
            <Lock className="lock" />
          </NanoFlex>
          <NanoFlex className="textBtnWrapper">
            <Type.p>{this.translation.librairies.privateFolder}</Type.p>
          </NanoFlex>
        </NanoFlex>
      </StyledToggleButtonPrive>
    );
  }
}
