import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledMedal = styled(NanoFlex)`
width: auto;
    height: auto;
  .medalBlockWrapper {
    user-select: none;
    width: ${(props) => (props.gold ? "36px" : props.silver ? "32px" : props.bronze ? "28px" : "26px")};
    height: ${(props) => (props.gold ? "36px" : props.silver ? "32px" : props.bronze ? "28px" : "26px")};
    border-radius: 60px;
    -webkit-box-shadow: ${(props) => (props.gold ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 
    -moz-box-shadow: ${(props) => (props.gold ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 
    box-shadow:    ${(props) => (props.gold ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset -2px -2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 

    -webkit-animation: rotate-scale-down 0.65s linear both;
    animation: rotate-scale-down 0.65s linear both;
    background-color: ${(props) => (props.gold ? "#FFC604" : props.silver ? "#CACACA" : props.bronze ? "#DA8F4A" : "#F9F9F9")};
    border: ${(props) => (props.gold ? "1px solid #FED755" : props.silver ? "1px solid #AEAEAE" : props.bronze ? "1px solid #AD5604" : "2px solid white")};
    .borderMedal {
        width: ${(props) => (props.gold ? "32px" : props.silver ? "28px" : props.bronze ? "24px" : "22px")};
    height: ${(props) => (props.gold ? "32px" : props.silver ? "28px" : props.bronze ? "24px" : "22px")};
      border-radius: 60px;
      -webkit-box-shadow: ${(props) => (props.gold ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 
      -moz-box-shadow: ${(props) => (props.gold ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 
      box-shadow: ${(props) => (props.gold ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.silver ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : props.bronze ? "inset 2px 2px 6px 0px rgba(0, 0, 0, 0.58)" : "none")}; 
    border: ${(props) => (props.gold ? "0.5px solid #FED755" : props.silver ? "0.5px solid #E6E6E6" : props.bronze ? "0.5px solid #F0B680" : "none")};
      h3 {
        font-size: ${(props) => (props.gold ? "24px" : props.silver ? "20px" : props.bronze ? "16px" : "12px")};
        color: ${(props) => (props.gold ? "#614B00" : props.silver ? "#3A3A3A" : props.bronze ? "#612F01" : "#9A9A9A")};
        text-shadow:  ${(props) => (props.gold ? "1px 1px 4px #AB8400" : props.silver ? "1px 1px 4px #AEAEAE" : props.bronze ? "1px 1px 4px #AD5604" : "none")};
        line-height: normal;
      }
    }
  }

  /* ANIMATION */
  @-webkit-keyframes rotate-scale-down {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
      transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(0.5) rotateZ(180deg);
      transform: scale(0.5) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
      transform: scale(1) rotateZ(360deg);
    }
  }
  @keyframes rotate-scale-down {
    0% {
      -webkit-transform: scale(1) rotateZ(0);
      transform: scale(1) rotateZ(0);
    }
    50% {
      -webkit-transform: scale(0.5) rotateZ(180deg);
      transform: scale(0.5) rotateZ(180deg);
    }
    100% {
      -webkit-transform: scale(1) rotateZ(360deg);
      transform: scale(1) rotateZ(360deg);
    }
  }
`;

export default class Medal extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledMedal gold={this.props.gold} silver={this.props.silver}  bronze={this.props.bronze} simple={this.props.simple}>
        <NanoFlex className="medalBlockWrapper">
          <NanoFlex className="borderMedal">
            <Type.h3>
              <b>{this.props.place}</b>
            </Type.h3>
          </NanoFlex>
        </NanoFlex>
      </StyledMedal>
    );
  }
}
