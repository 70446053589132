import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import PrivateRoute from "./components/Global/PrivateRoute";
import RenderRoute from "./components/Global/RenderRoute";

/* Themes */
import defaultTheme from "./themes/default.json";

// STYLES
import "./styles/reset.scss";
import "./styles/config.scss";

/* Components */
import { Guidelines } from "./components/views/Guidelines/Guidelines.js";
import { Layout } from "./components/views/Shared/Layout.js";
import { Login } from "./components/views/Login/Login.js";
//Reports
import { Intermediaires } from "./components/views/Reports/Intermediaires/Intermediaires.js";
import { Definitifs } from "./components/views/Reports/Definitifs/Definitifs.js";
import { Palmares } from "./components/views/Reports/Palmares/Palmares.js";
import { TVReplay } from "./components/views/Reports/TVReplay/TVReplay.js";
import { Dynamique } from "./components/views/Reports/Dynamique/Dynamique.js";
import { Verbatim } from "./components/views/Reports/Verbatim/Verbatim.js";
import { Profile } from "./components/views/Reports/Profile/Profile.js";

//Librairies
import { Librairies } from "./components/views/Librairies/Librairies.js";

//Administration
import { Chaines } from "./components/views/Administration/Chaines.js";
import { Programmes } from "./components/views/Administration/Programmes.js";
import { Regles } from "./components/views/Administration/Regles.js";
import { Genre } from "./components/views/Administration/Genre.js";
import { Reports } from "./components/views/Administration/Reports.js";
import { Users } from "./components/views/Administration/Users.js";
import { WordCloudAdmin } from "./components/views/Administration/WordCloudAdmin/WordCloudAdmin.js";

//Export
import { ExportDailyEmissions } from "./components/views/Reports/Export/ExportDailyEmissions.js";
import { ExportPalmares } from "./components/views/Reports/Export/ExportPalmares.js";

//Tests
import { Sortable } from "./components/views/Tests/Sortable.js";
import { WordCloud } from "./components/views/Tests/WordCloud/WordCloud.js";
import { DnD } from "./components/views/Tests/DnD/DnD.js";

//ChartJs
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendToLogin: false,
    };
  }

  componentDidMount() {
    window.addEventListener("sendToLogin", this.sendToLoginEvent, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.sendToLogin !== this.state.sendToLogin) {
      this.setState({ sendToLogin: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("sendToLogin", this.sendToLoginEvent, false);
  }

  sendToLoginEvent = () => {
    this.setState({ sendToLogin: true });
  };

  render() {
    return (
      <ThemeProvider theme={defaultTheme}>
        <BrowserRouter>
          <Switch>
            {/* LOGIN */}
            <RenderRoute path="/login">
              <Login />
            </RenderRoute>
            {this.state.sendToLogin && <Redirect to="/login" />}
            {/* REPORTS */}
            {/* Intermediaires */}
            <PrivateRoute exact path="/intermediaires">
              <Layout>
                <Intermediaires />
              </Layout>
            </PrivateRoute>
            {/* Definitifs */}
            <PrivateRoute exact path={["/", "/definitifs"]}>
              <Layout>
                <Definitifs />
              </Layout>
            </PrivateRoute>
            {/* Palmares */}
            <PrivateRoute exact path="/palmares">
              <Layout>
                <Palmares />
              </Layout>
            </PrivateRoute>
            {/* TvReplay */}
            <PrivateRoute exact path="/tvReplay">
              <Layout>
                <TVReplay />
              </Layout>
            </PrivateRoute>
            {/* Dynamique */}
            <PrivateRoute exact path="/dynamique">
              <Layout>
                <Dynamique />
              </Layout>
            </PrivateRoute>
            {/* Verbatim */}
            <PrivateRoute exact path="/verbatim">
              <Layout>
                <Verbatim />
              </Layout>
            </PrivateRoute>
            {/* Librairies */}
            {/* Profile */}
            <PrivateRoute exact path={["/profile", "/profil"]}>
              <Layout>
                <Profile />
              </Layout>
            </PrivateRoute>
            <PrivateRoute exact path="/librairies">
              <Layout>
                <Librairies />
              </Layout>
            </PrivateRoute>
            {/* Administration */}
            {/* Chaines */}
            <PrivateRoute exact path={["/admin", "/admin/chaines"]}>
              <Layout>
                <Chaines />
              </Layout>
            </PrivateRoute>
            {/* Programmes */}
            <PrivateRoute exact path="/admin/programmes">
              <Layout>
                <Programmes />
              </Layout>
            </PrivateRoute>
            {/* Régles */}
            <PrivateRoute exact path="/admin/regles">
              <Layout>
                <Regles />
              </Layout>
            </PrivateRoute>
            {/* Genre */}
            <PrivateRoute exact path="/admin/genre">
              <Layout>
                <Genre />
              </Layout>
            </PrivateRoute>
            {/* Reports */}
            <PrivateRoute exact path="/admin/reports">
              <Layout>
                <Reports />
              </Layout>
            </PrivateRoute>
            {/* Users */}
            <PrivateRoute exact path="/admin/users">
              <Layout>
                <Users />
              </Layout>
            </PrivateRoute>
            {/* Wordcloud */}
            <PrivateRoute exact path="/admin/wordcloud">
              <Layout>
                <WordCloudAdmin />
              </Layout>
            </PrivateRoute>
            {/* Export */}
            <PrivateRoute exact path="/export/daily-emissions">
              <ExportDailyEmissions />
            </PrivateRoute>
            <PrivateRoute exact path="/export/palmares">
              <ExportPalmares />
            </PrivateRoute>
            {/* Sortable Test */}
            {window.location.hostname === "localhost" && (
              <PrivateRoute exact path="/tests/sortable">
                <Sortable />
              </PrivateRoute>
            )}
            {/* WordCloud Test */}
            {window.location.hostname === "localhost" && (
              <PrivateRoute exact path="/tests/wordcloud">
                <WordCloud />
              </PrivateRoute>
            )}
            {/* WordCloud Test */}
            {window.location.hostname === "localhost" && (
              <PrivateRoute exact path="/tests/dnd">
                <DnD />
              </PrivateRoute>
            )}
            {/* GUIDELINES */}
            {window.location.hostname === "localhost" && (
              <RenderRoute exact path="/guidelines">
                <Guidelines />
              </RenderRoute>
            )}
          </Switch>
        </BrowserRouter>
      </ThemeProvider>
    );
  }
}
