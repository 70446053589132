/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

//COMPONENTS

import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";

const StyledDeleteModal = styled(NanoFlex)`
  width: 300px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  .deleteModalContent {
    .headerModal {
      position: relative;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 16px 24px;
      height: auto;
      h5 {
        text-transform: uppercase;
      }
      .closeBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: auto;
        width: auto;

        svg {
          width: 10px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
    .messageWrapper {
      padding: 24px;
      text-align: center;
    }

    .bottomWrapper {
      padding: 24px;
      border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    }
  }
`;

export class DeleteModal extends BaseComponent {
  
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    if (this.props.cancelAction) this.props.cancelAction();
  };

  onNext = () => {
    if (this.props.nextAction) this.props.nextAction();
  };

  render() {
    return (
      <StyledDeleteModal justifyContent="flex-start">
        <NanoFlex className="deleteModalContent" flexDirection="column">
          <NanoFlex className="headerModal">
            <Type.h5>{this.props.title}</Type.h5>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="messageWrapper">
            <Type.p>{this.props.message}</Type.p>
          </NanoFlex>
          <NanoFlex className="bottomWrapper">
            <ButtonCTA label={this.translation.global.confirm} clickAction={this.onNext} />
          </NanoFlex>
        </NanoFlex>
      </StyledDeleteModal>
    );
  }
}
