import { Component } from "react";
import "moment/locale/en-gb";
import "moment/locale/fr";
import t from "./Global/translate.json";
import { BaseService } from "../services/BaseService.js";
import { getByPath } from "./Global/tools.js";

export default class BaseComponent extends Component {
  constructor(props) {
    super(props);

    this.setLanguage(this.getLanguage());

    this._user = new BaseService().getUser();
    this._isMobile = this.isMobile();
    this._isExport = this.isExport();
    this._reportType = {
      ReportsIntermediaires: 1,
      ReportsDefinitives: 2,
      ReportsDynamique: 3,
      TVReplay: 4,
      Palmares: 5,
      Verbatim: 6,
      Profile: 7
    };
  }

  componentDidMount() {
    window.addEventListener("languageChange", this.onLanguageChange, false);
    window.addEventListener("resize", this.onResize, false);
  }

  onLanguageChange = (e) => {
    //Set Language
    this.setLanguage(e.detail);
    //Force Component Update
    this.forceUpdate();
  };

  onResize = (e) => {
    let newIsMobile = this.isMobile();
    if (newIsMobile !== this._isMobile) {
      //Set Is Mobile
      this._isMobile = newIsMobile;
      //Force Component Update
      this.forceUpdate();
      //Trigger onIsMobileChange
      window.dispatchEvent(new Event("isMobileChange"));
    }
  };

  componentWillUnmount() {
    window.removeEventListener("languageChange", this.onLanguageChange, false);
    window.removeEventListener("resize", this.onResize, false);
  }

  isMobile = () => {
    return window.innerWidth <= 800;
  };

  isExport = () => {
    return localStorage.getItem("isExport") ? JSON.parse(localStorage.getItem("isExport")) : false;
  };

  setLanguage = (langId) => {
    let lang = null;
    switch (langId) {
      case 1:
        lang = "en";
        break;
      default:
        lang = "fr";
    }
    //Set Local Storage Language Key
    localStorage.setItem("language", lang);
    //Set Translation Variable used on the component
    this.translation = t[lang];
  };

  getLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "en":
        return 1;
      default:
        return null;
    }
  };

  getMomentLanguage = () => {
    let lang = localStorage.getItem("language");
    switch (lang) {
      case "en":
        return "en-gb";
      default:
        return "fr";
    }
  };

  getTranslatedRecapFilters = (filterRecap) => {
    if (!filterRecap) return [];

    let immutableRecapFilters = JSON.parse(JSON.stringify(filterRecap));

    immutableRecapFilters.forEach((item) => {
      item.translation = getByPath(item.translation, this.translation);
    });

    return immutableRecapFilters;
  };
}
