import React from "react";
import BaseComponent from "../../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../../Global/NanoFlex.js";
import Type from "../../../../Global/Typography.js";

//IMGS
import Harris from "../../../../Global/images/harris_logo.png";

const StyledLegendPalmares = styled(NanoFlex)`
  .legendPalmaresContent {
    padding-bottom: 8px;
    .logoWrapper {
      width: auto;
      margin-right: 32px;
      img {
        max-height: 56px;
        height: auto;
        object-fit: cover;
      }
    }
  }
`;

export class LegendPalmares extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledLegendPalmares alignItems="flex-start">
        <NanoFlex className="legendPalmaresContent" justifyContent="flex-start">
          <NanoFlex className="logoWrapper" justifyContent="flex-start">
            <img src={Harris} alt="Harris Interactive Logo" />
          </NanoFlex>
          <NanoFlex className="legendInfo" flexDirection="column" alignItems="flex-start">
            {this.translation.palmares.legends.map((v, i) => (
              <Type.h6 key={i}>{v}</Type.h6>
            ))}
          </NanoFlex>
        </NanoFlex>
      </StyledLegendPalmares>
    );
  }
}
