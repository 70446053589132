import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

// COMPONENTS
import { PivotConfiguration } from "./PivotConfiguration.js";
import { PivotTable } from "./PivotTable.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";

const StyledPivot = styled(NanoFlex)`
  height: auto;
  max-height: calc(100vh - 320px);
  transition: ${(props) => props.theme.transition};
  .noResults {
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    h3 {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
  }
`;

export class Pivot extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    let showNoResults = !this.props.data?.result?.rows || this.props.data?.result?.rows.length === 0;
    return (
      <StyledPivot className="pivot" flexDirection="column" justifyContent="flex-start">
        {!this.props.isProfile && <PivotConfiguration selectedTreeColumns={this.props.selectedTreeColumns} selectedTreeLines={this.props.selectedTreeLines} onSelectedTreeChange={this.props.onSelectedTreeChange} />}
        {this.props.loading && (
          <NanoFlex>
            <Loading />
          </NanoFlex>
        )}
        {/* NO RESULTS */}
        {!this.props.loading && showNoResults && (
          <NanoFlex className="noResults">
            <Type.h3>
              <b>{this.translation.global.noResults}</b>
            </Type.h3>
          </NanoFlex>
        )}
        {!this.props.loading && !showNoResults && (
          <PivotTable isProfile={this.props.isProfile ?? false} isVerbatim={this.props.isVerbatim ?? false} data={this.props.data} selectedTreeColumns={this.props.selectedTreeColumns} onSelectedTreeColumnsChange={(col) => this.props.onSelectedTreeChange(col, this.props.selectedTreeLines)} />
        )}
      </StyledPivot>
    );
  }
}
