import React from "react";
import { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import Settings from "../../Settings.json";

const StyledInputDate = styled(NanoFlex)`
  .title {
    margin-bottom: 4px;
  }
  .inputDateWrapper {
    border: 1px solid ${(props) => props.theme.color.main.font};
    padding: 8px 6px;
    background-color: ${(props) => props.theme.color.main.white};
    .inputContainer {
      input {
        border: none;
        text-align: center;
        width: 100%;
        color: ${(props) => props.theme.color.main.font};
        padding: 0;
        font-size: 14px;
        background-color: ${(props) => props.theme.color.main.white};
        &::placeholder {
          color: ${(props) => props.theme.color.colorsPalette.grey};
          font-size: 14px;
        }
      }
      .inputYear {
        width: 38px;
      }

      &:after {
        content: "/";
        display: block;
        font-size: 14px;
        color: ${(props) => props.theme.color.main.font};
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
`;

class InputDate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      momentDate: props.date ? moment(props.date, Settings.moment.date) : null,
    };

    this.preventOnDateChange = false;
    this.preventOnDateChangeFromProps = false;
    this.canMove = true;
    this.dateInput = React.createRef();
  }

  componentDidMount() {
    this.setInputsFromState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date || (this.props.date !== (this.state.momentDate ? this.state.momentDate?.format(Settings.moment.date) : null) && !this.preventOnDateChangeFromProps)) {
      this.preventOnDateChange = true;
      this.setState({ momentDate: this.props.date ? moment(this.props.date, Settings.moment.date) : null }, () => {
        this.preventOnDateChange = false;
      });
    }

    if (this.state.momentDate !== prevState.momentDate) {
      this.setInputsFromState();
      if (this.props.onDateChange && !this.preventOnDateChange) {
        this.props.onDateChange(this.state.momentDate ? this.state.momentDate.format(Settings.moment.date) : null);
      }
    }
  }

  setInputsFromState = () => {
    if (this.state.momentDate) {
      this.dateInput.current.value = this.state.momentDate.format(Settings.inputDate.outputFormat);
    } else {
      this.dateInput.current.value = "";
    }
  };

  onBlur = (e) => {
    //If Value is Null set momentDate as null
    if (!e.target.value) {
      this.setState({ momentDate: null });
      return;
    }

    let date = this.state.momentDate ? moment(this.state.momentDate) : null;
    if (!date) {
      date = moment().set("date", 1).set("month", 0);
    }

    Settings.inputDate.validFormats.forEach((v) => {
      date = moment(e.target.value, v);
      if (!date.isValid()) {
        date = null;
      } else {
        return;
      }
    });

    this.preventOnDateChangeFromProps = true;
    this.setState({ momentDate: date }, () => {
      this.preventOnDateChangeFromProps = false;
      if (!this.state.momentDate) {
        this.dateInput.current.value = "";
      }
    });
  };

  render() {
    return (
      <StyledInputDate className="inputDateContainer" flexDirection="column" alignItems="flex-start">
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}
        <NanoFlex className="inputDateWrapper">
          <NanoFlex className="inputContainer">
            <input
              ref={this.dateInput}
              type="text"
              placeholder="DD-MM-YYYY"
              maxLength="10"
              defaultValue={this.state.momentDate}
              onBlur={(e) => {
                this.onBlur(e);
              }}
            />
          </NanoFlex>
        </NanoFlex>
      </StyledInputDate>
    );
  }
}

// Set default props
InputDate.defaultProps = {};

export default InputDate;

// --- // Documentation // --- //
/*
import InputDate from './../Nano/InputDate.js';

<InputDate />
*/
