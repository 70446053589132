import { BaseService } from "./BaseService";

export class LibrariesService extends BaseService {
  getFolders() {
    return this.request("api/Libraries/GetFolders", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getReportById(id) {
    return this.request(`api/Libraries/GetReportById/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  createFolder(model) {
    return this.request("api/Libraries/CreateFolder", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  deleteReport(id) {
    return this.request(`api/Libraries/DeleteReport/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }

  deleteReports(ids) {
    return this.request(`api/Libraries/deleteReports`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
      body: JSON.stringify(ids),
    });
  }

  deleteFolder(id) {
    return this.request(`api/Libraries/DeleteFolder/${id}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }
}

const librariesService = new LibrariesService();

export default librariesService;
