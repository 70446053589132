import React from "react";
import styled from "styled-components";

import Type from "../../Typography.js";
import NanoFlex from "../../NanoFlex";

import { ReactComponent as Check } from "../../icons/check.svg";

const multiple = 8;

const StyledCheckbox = styled.div`
  width: ${(props) => (props.check ? `100%` : `auto`)};
  .checkboxWrapper {
    width: 16px;
    height: 16px;
    border: 1px solid ${(props) => props.theme.color.main.font};
    cursor: pointer;
    user-select: none;
    padding: 2px;
    svg {
      path {
        fill: ${(props) => props.theme.color.main.green};
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
  .dropDownLine {
    height: ${multiple * 4 + "px"}; /* 32px */
    padding: ${multiple + "px"}; /* 8px */
    user-select: none;
    background-color: ${(props) => (props.toggle ? props.theme.color.gradientGreen.green04 : props.theme.color.main.white)};

    p {
      margin-left: ${multiple + "px"}; /* 8px */
      //font-weight: ${(props) => (props.toggle ? `bold` : `normal`)};
      color: ${(props) => props.theme.color.main.font};
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      background-color: ${(props) => (props.toggle ? props.theme.color.gradientGreen.green03 : props.theme.color.colorsPalette.lighterGrey)};
    }
  }

  .radio-outline {
    border: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    width: ${multiple * 2 + "px"};
    height: ${multiple * 2 + "px"};
    padding: 3px;
    border-radius: 100%;
    cursor: pointer;
    .radio-core {
      background-color: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
      width: ${multiple * 2 + "px"};
      background: ${(props) => (props.toggle ? props.theme.color.main.green : props.theme.color.main.white)};
      border-radius: 50px;
    }
  }
`;

const Checkbox = (props) => {
  if (props.check) {
    return (
      <StyledCheckbox className="checkLine" check={props.check} toggle={props.toggle}>
        <NanoFlex className="dropDownLine" justifyContent={"flex-start"} onClick={(e) => props.onChangeAction(props.id, props.label, e)}>
          <Type.p title={props.label}>{props.label}</Type.p>
        </NanoFlex>
      </StyledCheckbox>
    );
  } else {
    return (
      <StyledCheckbox>
        <NanoFlex justifyContent={"flex-start"}>
          <NanoFlex className="checkboxWrapper" onClick={(e) => props.onChangeAction(props.id, props.label, e)}>
            {props.toggle && <Check />}
          </NanoFlex>
        </NanoFlex>
      </StyledCheckbox>
    );
  }
};

// Set default props
Checkbox.defaultProps = {
  id: 0,
  label: "",
  labelSize: "h6",
  toggle: false,
  check: false,
};

export default Checkbox;

// --- // Documentation // --- //
/*
import Checkbox from './../Nano/Checkbox.js';

<Checkbox
    id={0}
    toggle={this.state.toggleCheckbox}
    label={'Checkbox Test'}
    onChangeAction={(id) => this.setState({ toggleCheckbox: !this.state.toggleCheckbox })}
    check
/>
*/
