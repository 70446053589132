/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Type from "../../../Global/Typography.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import { Redirect } from "react-router-dom";
import pivotService from "./../../../../services/PivotService";
import { formatTime } from "../../../Global/tools.js";

// COMPONENTS
import UsersBlock from "../../../Global/Elements/Blocks/UsersBlock.js";

const StyledEmissionCard = styled(NanoFlex)`
  width: 240px;
  height: 200px;
  min-height: 200px;
  background-color: ${(props) => (props.empty ? props.theme.color.colorsPalette.lightGrey : props.alert ? props.theme.color.colorsPalette.lightRed : props.theme.color.main.white)};
  margin-bottom: 4px;
  user-select: none;
  .cardWrapper {
    display: ${(props) => (props.empty ? "none" : "flex")};
    border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    border-radius: 0 0 8px 8px;
    cursor: pointer;
    .headerCard {
      padding: 8px;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      height: auto;
    }
    .bodyCard {
      padding: 8px;
      .elementsWrapper {
        height: auto;
        .titleWrapper {
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          p {
          }
        }
        .elementBlock {
          width: auto;
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
        .leftElement {
        }
      }
      .backgroundElement {
        padding: 4px 0;
        background-color: ${(props) => (props.alert ? props.theme.color.main.white : props.theme.color.colorsPalette.lighterGrey)};
        h6 {
          text-align: center;
        }
      }
    }
    &:hover {
      transition: ${(props) => props.theme.transition};
      box-shadow: ${(props) => props.theme.boxShadow};
    }
  }
  .cardEmpty {
    display: ${(props) => (props.empty ? "flex" : "none")};
    border: 1px solid ${(props) => props.theme.color.colorsPalette.lighterGrey2};
    border-radius: 0 0 8px 8px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    h5 {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }

  .btnVerbatim {
    text-decoration: none;
    h6 {
      color: ${(props) => props.theme.color.main.green};
      text-decoration: underline;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default class EmissionCard extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onEmissionClick = (id, name, type) => {
    pivotService
      .getPivotSettings(type)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        sessionStorage.setItem("isRedirect", true);
        //Set pivot configuration to load
        let settings = JSON.parse(data);
        sessionStorage.setItem("jsonLinesByReport", JSON.stringify(settings[1]));
        sessionStorage.setItem("jsonColumnsByReport", JSON.stringify(settings[0]));
        //Set emission selected to load
        this.props.filtersSelected.emissionsSelected.push({ id: parseInt(id), name: name });
        this.props.filtersSelected.channelsSelected = [this.props.data.channel.id];
        sessionStorage.setItem("filtersByReport", JSON.stringify(this.props.filtersSelected));
        //Navigate to page
        if (type === "Verbatim") {
          this.setState({ redirect: "/Verbatim" });
        } else {
          this.setState({ redirect: "/Dynamique" });
        }
      });
  };

  render() {
    let card = this.props.data;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <StyledEmissionCard className="emissionCard" empty={this.props.empty} alert={card?.score.sample < 60}>
        {!this.props.empty && (
          <NanoFlex className="cardWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" onClick={(id) => this.onEmissionClick(card.programId, card.name, "ProgrammesTVSoiree")}>
            <NanoFlex className="headerCard" justifyContent="flex-end">
              <Type.p>
                <b>{card.genre?.name}</b>
              </Type.p>
            </NanoFlex>
            <NanoFlex className="bodyCard" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
              {/* ELEMENT WRAPPER */}
              <NanoFlex className="elementsWrapper" justifyContent="space-between">
                <NanoFlex className="leftElement" justifyContent="flex-start">
                  <Link to="#" className="btnVerbatim" onClick={(id) => this.onEmissionClick(card.programId, card.name, "Verbatim")}>
                    <Type.h6>
                      <b>Verbatim</b>
                    </Type.h6>
                  </Link>
                </NanoFlex>
                <NanoFlex className="rightElement" justifyContent="flex-end">
                  <Type.h6>
                    {formatTime(card.startTime)} - {formatTime(card.endTime)}
                  </Type.h6>
                </NanoFlex>
              </NanoFlex>
              {/* ELEMENT WRAPPER */}
              <NanoFlex className="elementsWrapper">
                <NanoFlex className="titleWrapper">
                  <Type.p>{card.name}</Type.p>
                </NanoFlex>
              </NanoFlex>
              {/* ELEMENT WRAPPER */}
              {this.props.viewDetails && (
                <NanoFlex className="elementsWrapper backgroundElement">
                  <NanoFlex flexDirection="column">
                    <Type.h6>{this.translation.global.impact}</Type.h6>
                    <Type.h6>
                      <b>{card.idX_Emotionnel ?? "-"}</b>
                    </Type.h6>
                  </NanoFlex>
                  <NanoFlex flexDirection="column">
                    <Type.h6>Originalité</Type.h6>
                    <Type.h6>
                      <b>{card.idX_Originalite ?? "-"}</b>
                    </Type.h6>
                  </NanoFlex>
                  <NanoFlex flexDirection="column">
                    <Type.h6>Education</Type.h6>
                    <Type.h6>
                      <b>{card.idX_Education ?? "-"}</b>
                    </Type.h6>
                  </NanoFlex>
                </NanoFlex>
              )}
              {/* ELEMENT WRAPPER */}
              <NanoFlex className="elementsWrapper" justifyContent="flex-end">
                {card.score.value && (
                  <NanoFlex className="elementBlock">
                    <Type.h6>
                      {this.translation.global.noteQualiTV} <b>{card.score.value}</b>
                    </Type.h6>
                  </NanoFlex>
                )}
                {card.sat.value && (
                  <NanoFlex className="elementBlock">
                    <UsersBlock red={card.score.sample < 60} number={card.score.sample} />
                  </NanoFlex>
                )}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
        {/* EMPTY CARD */}
        <NanoFlex className="cardEmpty">
          <Type.h5>
            <b>Aucun Programme</b>
          </Type.h5>
        </NanoFlex>
        {/* END EMPTY CARD */}
      </StyledEmissionCard>
    );
  }
}
