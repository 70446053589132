import { BaseService } from "./BaseService";

export class PalmaresService extends BaseService {
  getPalmaresReport(model) {
    return this.request("api/Palmares/GetPalmaresReport", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getPalmaresPdf(filter) {
    return this.request("api/Palmares/GetPalmaresPdf", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
}

const palmaresService = new PalmaresService();

export default palmaresService;
