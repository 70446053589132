/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// IMGS
import { ReactComponent as EditIcon } from "../../Global/icons/edit.svg";

// COMPONENTS
//import { Filters } from "./Filters.js";
import { AdminNavBar } from "./AdminNavBar.js";
import CalendarRange from "../../Global/Elements/Forms/CalendarRange.js";
import Input from "../../Global/Elements/Forms/Input.js";
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown.js";
import ButtonCTA from "../../Global/Elements/Buttons/ButtonCTA.js";
import ButtonWithIcon from "../../Global/Elements/Buttons/ButtonWithIcon.js";
import Delete from "../../Global/icons/delete.svg";

const StyledRegles = styled(NanoFlex)`
  min-width: 0;
  .filters {
    .baseFilter {
      width: 256px;
      .baseFilterWrapper {
        .topWrapper {
          .filtersSection {
            height: calc(100vh - 195px);
          }
        }
        .bottomWrapper {
          .collapseBtn {
            display: none;
          }
        }
      }
    }
  }
  .reglesContent {
    min-width: 0;
    height: auto;
    .contentWrapper {
      padding: 32px;
      .tableContainer {
        border: 1px solid ${(props) => props.theme.color.main.font};
        overflow: hidden;
        .headerTable {
          padding: 16px 8px;
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          height: auto;
          box-shadow: ${(props) => props.theme.boxShadow};
          position: sitcky;
          z-index: 1;
          .headerCol {
            h6 {
              text-transform: uppercase;
            }
          }
        }
        .tableListWrapper {
          height: calc(100vh - 180px);
          overflow: auto;
          padding-top: 4px;
          .lineTable {
            padding: 8px;
            height: auto;

            .lineCol {
              height: auto;
              overflow: hidden;
              p {
                width: calc(100% - 16px);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .editCol {
              cursor: pointer;

              svg {
                width: 16px;
                height: auto;

                path {
                  transition: all 0.2s ease-in-out;
                  fill: ${(props) => props.theme.color.colorsPalette.grey};
                }
              }

              &:hover {
                svg {
                  path {
                    transition: all 0.2s ease-in-out;
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
              }
            }
            &:nth-child(even) {
              background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
            }
          }
          .selectedLine {
            background-color: ${(props) => props.theme.color.gradientGreen.green04};
            .editCol {
              svg {
                transform: rotate(360deg) scaleX(-1);
                path {
                  fill: ${(props) => props.theme.color.main.green};
                }
              }
              &:hover {
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.green};
                  }
                }
              }
            }
          }
        }
        .xs {
          width: 48px;
        }
        .s {
          flex: 2;
        }
        .m {
          flex: 4;
        }
      }
      .formEditWrapper {
        width: 344px;
        background-color: ${(props) => props.theme.color.main.white};
        border: 1px solid ${(props) => props.theme.color.main.font};
        border-left: 0;
        .headerWrapper {
          background-color: ${(props) => (props.isCollapsed ? "unset" : props.theme.color.main.green)};
          height: auto;
          padding: 16px;
          h5 {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.main.white};
          }
        }
        form {
          height: 100%;
          width: 100%;

          .formContainer {
            padding: 16px;
            height: calc(100vh - 184px);
            overflow: auto;
            .formTop {
              height: auto;
              .formWrapper {
                margin-bottom: 12px;
                .titleForm {
                  margin-bottom: 4px;
                }
                &:last-child {
                  margin-bottom: 0;
                }
              }
              .adminBtn {
                margin-bottom: 16px;
              }
            }
            .formBottom {
              height: auto;
              margin-top: 32px;
              .deleteBtn {
                height: auto;
                margin-bottom: 8px;
                .buttonWithIcon {
                  width: 100%;
                  .buttonWithIconWrapper {
                    width: 100%;
                    justify-content: center;
                    .titleBtn {
                      width: auto;
                      order: 2;
                      margin-top: 4px;
                    }
                    .iconBtn {
                      width: auto;
                      margin-left: 0;
                      margin-right: 8px;
                    }
                    &:hover {
                      background-color: ${(props) => props.theme.color.colorsPalette.red};
                      .titleBtn {
                        p {
                          color: ${(props) => props.theme.color.main.white};
                        }
                      }
                      .iconBtn {
                        img {
                          filter: brightness(100);
                        }
                      }
                    }
                  }
                }
              }
              .filterBtn {
                height: auto;
              }
            }
          }
        }
      }
    }
  }
`;

export class Regles extends BaseComponent {
  render() {
    return (
      <StyledRegles>
        {/* <Filters onFilterChange={(f) => this.setState({ filter: f })} /> */}
        <NanoFlex className="reglesContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <AdminNavBar render={this.props.render} />
          <NanoFlex className="contentWrapper" alignItems="stretch" alignContent="stretch">
            {/* TABLE */}
            <NanoFlex className="tableContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* HEADER TABLE */}
              <NanoFlex className="headerTable" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="headerCol s" justifyContent="flex-start">
                  <Type.h6>
                    <b>Du</b>
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="headerCol s" justifyContent="flex-start">
                  <Type.h6>
                    <b>Au</b>
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="headerCol m" justifyContent="flex-start">
                  <Type.h6>
                    <b>Titre</b>
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="headerCol m" justifyContent="flex-start">
                  <Type.h6>
                    <b>Nouveau Titre</b>
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="headerCol m" justifyContent="flex-start">
                  <Type.h6>
                    <b>Chaîne</b>
                  </Type.h6>
                </NanoFlex>
                <NanoFlex className="headerCol xs" justifyContent="flex-start"></NanoFlex>
              </NanoFlex>
              {/* END HEADER TABLE */}
              {/* TABLE LIST */}
              <NanoFlex className="tableListWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {/* LINE */}
                <NanoFlex className="lineTable selectedLine" justifyContent="flex-start">
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Nouveau Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Tf1</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol xs editCol" justifyContent="flex-start">
                    <EditIcon />
                  </NanoFlex>
                </NanoFlex>
                {/* END LINE */}
                {/* LINE */}
                <NanoFlex className="lineTable" justifyContent="flex-start">
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Nouveau Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Tf1</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol xs editCol" justifyContent="flex-start">
                    <EditIcon />
                  </NanoFlex>
                </NanoFlex>
                {/* END LINE */}
                {/* LINE */}
                <NanoFlex className="lineTable" justifyContent="flex-start">
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Nouveau Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Tf1</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol xs editCol" justifyContent="flex-start">
                    <EditIcon />
                  </NanoFlex>
                </NanoFlex>
                {/* END LINE */}
                {/* LINE */}
                <NanoFlex className="lineTable" justifyContent="flex-start">
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Nouveau Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Tf1</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol xs editCol" justifyContent="flex-start">
                    <EditIcon />
                  </NanoFlex>
                </NanoFlex>
                {/* END LINE */}
                {/* LINE */}
                <NanoFlex className="lineTable" justifyContent="flex-start">
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol s" justifyContent="flex-start">
                    <p>00-00-0000</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Nouveau Titre Name</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol m" justifyContent="flex-start">
                    <p>Tf1</p>
                  </NanoFlex>
                  <NanoFlex className="lineCol xs editCol" justifyContent="flex-start">
                    <EditIcon />
                  </NanoFlex>
                </NanoFlex>
                {/* END LINE */}
              </NanoFlex>
              {/* END TABLE LIST */}
            </NanoFlex>
            {/* END TABLE */}
            {/* FORM EDIT */}
            <NanoFlex className="formEditWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="headerWrapper">
                <Type.h5>
                <b>{this.translation.global.edit}</b>
                </Type.h5>
              </NanoFlex>
              <form>
                <NanoFlex className="formContainer" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
                  <NanoFlex className="formTop" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                      <CalendarRange onChangeSelection={(obj) => console.log(obj)} />
                    </NanoFlex>
                    <NanoFlex className="formWrapper">
                      <Input title="Nouveau titre" placeholder="Nouveau titre" />
                    </NanoFlex>
                    <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                      <Type.p className="titleForm">Chaîne</Type.p>
                      <SingleSelectionDropdown
                        placeholder="Placeholder"
                        options={[
                          { id: 1, name: "teste" },
                          { id: 2, name: "teste 2" },
                        ]}
                      />
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="formBottom" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                    <NanoFlex className="deleteBtn">
                      <ButtonWithIcon label={this.translation.global.delete} src={Delete} />
                    </NanoFlex>
                    <NanoFlex className="filterBtn">
                      <ButtonCTA label={this.translation.global.confirm} />
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </form>
            </NanoFlex>
            {/* END FORM EDIT */}
          </NanoFlex>
        </NanoFlex>
      </StyledRegles>
    );
  }
}
