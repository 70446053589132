/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import { Redirect } from "react-router-dom";

//Imgs
import { ReactComponent as Trophy } from "../../../Global/icons/trophy.svg";

const StyledTrophyBlock = styled(NanoFlex)`
  .trophyBlockWrapper {
    user-select: none;
    cursor: pointer;
    .iconBtnWrapper {
      margin-right: 4px;
      svg {
        height: 18px;
        width: auto;
        path {
          fill: ${(props) => props.theme.color.colorsPalette.yellow};
        }
      }
    }
    .textBtnWrapper {
      padding: 4px;
      background-color: ${(props) => props.theme.color.colorsPalette.lightYellow};
      h6 {
        line-height: unset;
        /* color: ${(props) => props.theme.color.main.white}; */
      }
    }
    &:hover {
      .textBtnWrapper {
        background-color: ${(props) => props.theme.color.colorsPalette.yellow};
        h6 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
`;

export default class TrophyBlock extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  navigateToPalmares = () => {
    //Set filters and columns/lines to load
    sessionStorage.setItem("filtersByReport", JSON.stringify(this.props.filtersSelected));
    //Navigate to page
    this.setState({ redirect: "/Palmares" });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <StyledTrophyBlock flexDirection="column">
        <NanoFlex className="trophyBlockWrapper" onClick={this.navigateToPalmares}>
          <NanoFlex className="iconBtnWrapper">
            <Trophy />
          </NanoFlex>
          <NanoFlex className="textBtnWrapper">
            <Type.h6>{this.translation.global.palmares}</Type.h6>
          </NanoFlex>
        </NanoFlex>
      </StyledTrophyBlock>
    );
  }
}
