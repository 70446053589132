import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

const StyledDetailViewButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  .detailViewButtonWrapper {
    min-width: 260px;
    .btnWrapper {
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      cursor: pointer;
      padding: 6px 16px;
      pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
      opacity: ${(props) => (props.disabled ? "0.4" : "1")};
      min-with: 150px;
      .textBtnWrapper {
        p {
          line-height: initial;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .detailedWrapper {
      background-color: ${(props) => (props.detailed ? props.theme.color.main.green : props.simple ? props.theme.color.colorsPalette.lightGrey : props.theme.color.colorsPalette.lightGrey)};
      border-radius: 60px 0 0 60px;
      border-right: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
      .textBtnWrapper {
        p {
          color: ${(props) => (props.detailed ? props.theme.color.main.white : props.simple ? props.theme.color.main.font : props.theme.color.main.font)};
          font-weight: ${(props) => (props.detailed ? "bold" : props.simple ? "normal" : "normal")};
        }
      }
    }

    .simpleWrapper {
      background-color: ${(props) => (props.detailed ? props.theme.color.colorsPalette.lightGrey : props.simple ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
      border-radius: 0 60px 60px 0;
      .textBtnWrapper {
        p {
          color: ${(props) => (props.detailed ? props.theme.color.main.font : props.simple ? props.theme.color.main.white : props.theme.color.main.font)};
          font-weight: ${(props) => (props.detailed ? "nomal" : props.simple ? "bold" : "normal")};
        }
      }
    }
  }
`;

export default class DetailViewButton extends BaseComponent {

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledDetailViewButton flexDirection="column" detailed={this.props.detailed} simple={this.props.simple} disabled={this.props.disabled} onClick={this.props.clickAction}>
        <NanoFlex className="detailViewButtonWrapper">
          <NanoFlex className="btnWrapper detailedWrapper" justifyContent="flex-start" onClick={this.props.onDetailedClick}>
            <NanoFlex className="textBtnWrapper">
              <Type.p>Vue Détaillée</Type.p>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="btnWrapper simpleWrapper" justifyContent="flex-start" onClick={this.props.onSimpleClick}>
            <NanoFlex className="textBtnWrapper">
              <Type.p>Vue simple</Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledDetailViewButton>
    );
  }
}
