import { BaseService } from "./BaseService";

export class AdministrationService extends BaseService {
  getChannels() {
    return this.request("api/Administration/GetChannels", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getGenres() {
    return this.request("api/Administration/GetGenres", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  //Report Pivot Settings

  getPivotSettings() {
    return this.request("api/Administration/GetPivotSettings", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  savePivotSettings(model) {
    return this.request("api/Administration/SavePivotSettings", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(model),
    });
  }

  //User
  getUsers() {
    return this.request("api/Administration/GetUsers", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  createUser(model) {
    return this.request("api/Administration/CreateUser", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  saveUser(model) {
    return this.request("api/Administration/SaveUser", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(model),
    });
  }

  deleteUser(userId) {
    return this.request(`api/Administration/DeleteUser/${userId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }

  //Words
  getWords() {
    return this.request("api/Administration/GetWords", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  importWords(file, toReplace) {
    const formData = new FormData();
    formData.append("file", file);
    return this.request(`api/Administration/ImportWords/${toReplace}`, {
      headers: {
        accept: "*/*",
      },
      method: "POST",
      body: formData,
    });
  }

  exportWords() {
    return this.request(`api/Administration/ExportWords`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  createWord(model) {
    return this.request("api/Administration/CreateWord", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  deleteWord(wordId) {
    return this.request(`api/Administration/DeleteWord/${wordId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }

  //Group Words
  getCompleteGroupWords() {
    return this.request("api/Administration/GetCompleteGroupWords", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  createGroupWord(model) {
    return this.request("api/Administration/CreateGroupWord", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  saveGroupWord(model) {
    return this.request("api/Administration/SaveGroupWord", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(model),
    });
  }

  deleteGroupWord(wordId) {
    return this.request(`api/Administration/DeleteGroupWord/${wordId}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "DELETE",
    });
  }
}

const administrationService = new AdministrationService();

export default administrationService;
