/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

//Imgs
import { ReactComponent as People } from "../../../Global/icons/people.svg";

const StyledUsersBlock = styled(NanoFlex)`
  height: auto;
  width: auto;
  .userBlockWrapper {
    padding: 4px 8px;
    user-select: none;
    background-color: ${(props) => (props.grey ? props.theme.color.colorsPalette.lightGrey : props.red ? props.theme.color.colorsPalette.red : props.theme.color.main.green)};
    .iconBtnWrapper {
      width: auto;
      margin-right: 4px;
      svg {
        width: 16px;
        height: auto;
        path {
          fill: ${(props) => (props.grey ? props.theme.color.main.green : props.red ? props.theme.color.main.white : props.theme.color.main.white)};
        }
      }
    }
    .textBtnWrapper {
      width: auto;
      h6 {
        color: ${(props) => (props.grey ? props.theme.color.main.font : props.red ? props.theme.color.main.white : props.theme.color.main.white)};
      }
    }
  }
`;

export default class UsersBlock extends Component {
  render() {
    return (
      <StyledUsersBlock className="userBlock" flexDirection="column" grey={this.props.grey} red={this.props.red}>
        <NanoFlex className="userBlockWrapper">
          <NanoFlex className="iconBtnWrapper">
            <People />
          </NanoFlex>
          <NanoFlex className="textBtnWrapper">
            <Type.h6>{this.props.number}</Type.h6>
          </NanoFlex>
        </NanoFlex>
      </StyledUsersBlock>
    );
  }
}
