/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled, { css } from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import SortBy from "../../../Global/Elements/Buttons/SortBy.js";
import { flattenElements, findRecursive } from "../../../Global/tools.js";
import * as _ from "lodash";

const StyledPivotTable = styled(NanoFlex)`
  overflow: auto;
  margin-top: 8px;
  border: 1px solid ${(props) => props.theme.color.main.font};
  .tableContainer {
    position: relative;
    table {
      width: 100%;
      tbody {
        scroll-behavior: smooth;
        tr {
          padding: 8px;
          td {
            margin: 0 auto;
            padding: 6px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-height: 28px;
            height: auto;
            /* max-width: 100px; */
            /* max-width: 140px; */
            border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
            vertical-align: middle;
            h6 {
              /* white-space: normal; */
            }
          }
          .titleHeader {
            h6 {
              font-weight: bold;
            }
          }
          .sortHeader {
            cursor: pointer;
            position: relative;
            h6 {
              margin-right: 16px;
            }
            .sortBy {
              position: absolute;
              right: 3px;
              top: 2px;
              transform: scale(0.7);
            }
            &:hover {
              background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            }
          }

          .titleRow {
            text-align: left;
            background-color: ${(props) => props.theme.color.main.white};
            border-bottom: 2px solid ${(props) => props.theme.color.main.font};
            h6 {
              font-weight: bold;
            }
          }

          .titleOption {
            text-align: left;
            min-width: 160px;
            max-width: 260px;
          }
          &:nth-child(odd) {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
          }

          .highLight {
            h6 {
              color: ${(props) => props.theme.color.colorsPalette.red};
            }
          }
          /* FIXED HEADER */
          ${({ headerCount }) =>
    headerCount &&
    Array(headerCount)
      .fill()
        .map(
            (r, i) => css`
                &:nth-child(${i + 1}) {
                  background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
                  position: sticky;
                  top: ${i * 28}px;
                  }
                `
      )}/* END FIXED HEADER */
        }
      }
    }
  }
`;

export class PivotTable extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  toggleSortColumn = (code) => {
    let immutableData = JSON.parse(JSON.stringify(this.props.selectedTreeColumns));
    var toSort = findRecursive(immutableData, (a) => a.code.toUpperCase() === code.toUpperCase());

    switch (toSort.sortOrientation?.toUpperCase()) {
      case "ASC":
        toSort.sortOrientation = "DESC";
        break;
      case "DESC":
        //Move all Forward Positions back 1 position
        let sortPosition = toSort.sortPosition + 1;
        let nextSort;
        while ((nextSort = findRecursive(immutableData, (a) => a.sortPosition === sortPosition))) {
          nextSort.sortPosition--;
          sortPosition++;
        }

        toSort.sortOrientation = null;
        toSort.sortPosition = null;
        break;
      default:
        toSort.sortOrientation = "ASC";
        toSort.sortPosition = this.getSortColumns().length;
        break;
    }

    this.props.onSelectedTreeColumnsChange(immutableData);
  };

  getSortColumns = () => {
    /*Get Immutable Tree Columns*/
    let immutableData = JSON.parse(JSON.stringify(this.props.selectedTreeColumns));
    /*Flatten and remove empty Sort's*/
    let elements = flattenElements(immutableData).filter((f) => (f.sortPosition || f.sortPosition === 0) && f.sortOrientation);
    /*Order by SortPosition*/
    return _.sortBy(elements, (t) => t.sortPosition, "ASC");
  };

  handleTdStyle = (col, colIndex, columnCount) => {
    return {
      minWidth: !this.props.isProfile ? 10 * (col.colLength ?? 0) + "px" : "auto",
      width: this.props.isVerbatim ? (colIndex === 0 ? 33 : colIndex === 1 ? 25 : 42 / columnCount) + "%" : "",
      whiteSpace: this.props.isVerbatim ? (colIndex === 0 || colIndex === 1 ? "normal" : "nowrap") : "",
    }
  }

  cellClick = (cell) => {
    if (cell.code) this.toggleSortColumn(cell.code);
  };

  render() {
    let sortColumns = this.getSortColumns();
    let headerCount = this.props.data?.result?.rows?.filter((r) => r.columns[0].isHeader)?.length ?? 0;
    let columnCount = this.props.data?.result?.rows[0]?.columns?.length

    return (
      <StyledPivotTable className="pivotTable" headerCount={headerCount}>
        <NanoFlex className="tableContainer" justifyContent="flex-start" alignItems="flex-start">
          <table>
            <tbody>
              {this.props.data?.result?.rows?.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {row.columns.map((col, colIndex) => {
                      let sortColumn = sortColumns.find((s) => s.code?.toUpperCase() === col.code?.toUpperCase());
                      return (
                        <td
                          key={colIndex}
                          rowSpan={col.rowspan}
                          colSpan={col.colspan}
                          className={`${col.isHeader ? "titleHeader" : ""} ${col.isTitleRow ? "titleRow" : ""} ${col.isOption ? "titleOption" : ""} ${col.code ? "sortHeader" : ""} ${col.highLight === "1" ? "highLight" : ""}`}
                          style={this.handleTdStyle(col, colIndex, columnCount)}
                          title={col.text}
                          onClick={() => this.cellClick(col)}>
                          <Type.h6>{col.text === "" && col.isHeader ? <Type.h6 style={{ color: "#F9F9F9" }}>-</Type.h6> : col.text}</Type.h6>
                          {col.code && <SortBy sortUp={sortColumn?.sortOrientation?.toUpperCase() === "ASC"} sortDown={sortColumn?.sortOrientation?.toUpperCase() === "DESC"} />}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </NanoFlex>
      </StyledPivotTable>
    );
  }
}
