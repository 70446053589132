import { BaseService } from "./BaseService";
 
export class AccountService extends BaseService {
  async authenticate(username, password) {
    return this.request("api/Account/Authenticate", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ username, password }),
    });
  }
}
 
const accountService = new AccountService();
 
export default accountService;