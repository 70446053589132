import { BaseService } from "./BaseService";

export class GridService extends BaseService {
  getDailyEmissions(filter) {
    return this.request("api/Grid/GetDailyEmissions", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
  getDailyEmissionsExcel(filter) {
    return this.request("api/Grid/GetDailyEmissionsExcel", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
  getDailyEmissionsPdf(filter) {
    return this.request("api/Grid/GetDailyEmissionsPdf", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  }
}

const gridService = new GridService();

export default gridService;
