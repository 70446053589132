import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";

//SERVICES
import accountService from "../../../services/AccountService.js";

// COMPONENTS
import ButtonCTA from "../../Global/Elements/Buttons/ButtonCTA.js";
import Input from "../../Global/Elements/Forms/Input.js";

//Imgs
import LogoFranceTv from "../../Global/images/francetv-black.svg";

const StyledLogin = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.colorsPalette.grey};
  .loginWrapper {
    width: auto;
    height: auto;
    padding: 40px;
    background-color: ${(props) => props.theme.color.main.white};
    box-shadow: ${(props) => props.theme.boxShadow};
    .headerWrapper {
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
      padding-bottom: 40px;
      margin-bottom: 24px;
      .logoWrapper {
        width: auto;
        max-width: 300px;
        height: auto;
        max-height: 300px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .formContainer {
      .formWrapper {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .btnContainer {
        margin-top: 24px;
        .cta {
          .ctaWrapper {
            padding: 16px;
          }
        }
      }
    }
  }
`;

export class Login extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    if (accountService.getToken() !== null) {
      accountService.removeToken();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  login = async (event) => {
    // Prevent page refresh
    event.preventDefault();

    const response = await accountService.authenticate(this.state.username, this.state.password);
    const data = await response.json();

    if (response.ok) {
      // Set User
      accountService.setToken(data);
      sessionStorage.removeItem("filtersByReport");
      sessionStorage.removeItem("jsonLinesByReport");
      sessionStorage.removeItem("jsonColumnsByReport");
      // Redirect to Homepage
      this.props.render.history.push("/");    
    } else {
      this.setState({ error: true });
    }
  };

  render() {
    return (
      <StyledLogin className="login">
        <NanoFlex className="loginWrapper" flexDirection="column">
          <NanoFlex className="headerWrapper">
            <NanoFlex className="logoWrapper">
              <img src={LogoFranceTv} alt="France TV Logo" />
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="formContainer" flexDirection="column">
            <form onSubmit={this.login}>
              <NanoFlex className="formWrapper">
                <Input title={this.translation.global.user} placeholder={this.translation.global.user} error={this.state.error} defaultValue={this.state.username} onChangeAction={(val) => this.setState({ username: val })} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input eye type="password" title={this.translation.global.password} placeholder={this.translation.global.password} error={this.state.error} defaultValue={this.state.password} onChangeAction={(val) => this.setState({ password: val })} />
              </NanoFlex>
              <NanoFlex className="btnContainer">
                <ButtonCTA label={this.translation.global.entrer} type="submit" />
              </NanoFlex>
            </form>
          </NanoFlex>
        </NanoFlex>
      </StyledLogin>
    );
  }
}
