import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import gridService from "./../../../../services/GridService";
import * as _ from "lodash";

// COMPONENTS
import Loading from "../../../Global/Elements/Loading/Loading.js";
import { Results } from "./../Grid/Results.js";

const StyledExportDailyEmissions = styled(NanoFlex)`
  .resultsContainer {
    display: none;
  }
  .visibleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    .resultsWrapper {
      background-color: ${(props) => props.theme.color.main.white};
      justify-content: center;
      align-items: center;
      .arrowBtnWrapper {
        display: none;
      }
      .emissionsWrapper {
        height: auto;
        overflow: none;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media print {
    @page {
      size: landscape;
    }
  }
`;

export class ExportDailyEmissions extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
      results: null,
      currentChunk: 0,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    window.addEventListener("setFilters", this.onSetFilters, false);
    window.addEventListener("nextChunk", this.onNextChunk, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter)) {
      if (this.state.filter) {
        this.getDailyEmissions();
      } else {
        this.setState({ results: null });
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    window.removeEventListener("setFilters", this.onSetFilters, false);
    window.removeEventListener("nextChunk", this.onNextChunk, false);
  }

  onSetFilters = (e) => {
    this.setState({ filter: e.detail });
  };

  onNextChunk = () => {
    this.setState({ currentChunk: this.state.currentChunk + 1 });
  };

  getDailyEmissions = () => {
    gridService
      .getDailyEmissions(this.state.filter)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ results: data });
      });
  };

  getChunks = () => {
    /*Limit to a max of 4 channels with 3 cards per page */
    let data = [];
    var chunks = _.chunk(this.state.results.channels, 4);

    chunks.forEach((chunk) => {
      let idx = 0;
      while (true) {
        let newChunk = [];
        chunk.forEach((channel) => {
          let immutableChannel = JSON.parse(JSON.stringify(channel));
          immutableChannel.emissions = _.chunk(immutableChannel.emissions, 3)[idx] ?? [];
          newChunk.push(immutableChannel);
        });

        if (newChunk.filter((c) => c.emissions.length > 0).length === 0) break;

        data.push(newChunk);

        idx++;
      }
    });

    return data;
  };

  render() {
    let loading = !this.state.filter || this.state.results === null;
    if (loading) {
      return (
        <StyledExportDailyEmissions justifyContent="flex-start">
          <NanoFlex className="definitifsWrapper">
            <Loading />
          </NanoFlex>
        </StyledExportDailyEmissions>
      );
    }
    let chunks = this.getChunks(); //_.chunk(this.state.results.channels, 3);
    return (
      <StyledExportDailyEmissions justifyContent="flex-start">
        {chunks.map((c, i) => {
          let immutableResults = JSON.parse(JSON.stringify(this.state.results));
          immutableResults.channels = chunks[i];
          return (
            <NanoFlex key={`chunk_${i}`} className={`resultsContainer ${this.state.currentChunk === i ? "visibleContainer" : ""}`} data-next-page={chunks[i + 1] ? true : false}>
              <Results results={immutableResults} viewDetails={this.state.filter?.viewDetails ?? true} filtersSelected={this.state.filter} />
            </NanoFlex>
          );
        })}
      </StyledExportDailyEmissions>
    );
  }
}
