/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import librariesService from "../../../../services/LibrariesService";
//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";

//COMPONENTS
import Input from "../../Elements/Forms/Input.js";
import ToggleButtonPrive from "../../Elements/Buttons/ToggleButtonPrive.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";

const StyledAddLibrairieModal = styled(NanoFlex)`
  width: 300px;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);

  .addLibrairieModalContent {
    .headerModal {
      position: relative;

      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 16px 24px;
      h5 {
        text-transform: uppercase;
      }
      .closeBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: auto;
        width: auto;

        svg {
          width: 10px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
    .formContainer {
      height: auto;
      padding: 24px;
      .formWrapper {
        margin-bottom: 16px;
        .titleForm {
          margin-bottom: 4px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .bottomWrapper {
      padding: 24px;
      border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    }
  }
`;

export class AddLibrairieModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      description: "",
      descriptionError: false,
      isPersonal: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    this.setState(this.getDefaultState(), () => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  onCreate = (event) => {
    // Prevent page refresh
    event.preventDefault();
    if (this.state.description?.trim() !== "") {
      librariesService
        .createFolder({
          description: this.state.description,
          isPersonal: this.state.isPersonal,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState(this.getDefaultState(), () => {
            if (this.props.nextAction) this.props.nextAction();
          });
        });
    } else {
      this.setState({ descriptionError: true });
    }
  };

  render() {
    return (
      <StyledAddLibrairieModal justifyContent="flex-start">
        <NanoFlex className="addLibrairieModalContent" flexDirection="column">
          <NanoFlex className="headerModal">
            <Type.h5>{this.translation.librairies.addLibrairies}</Type.h5>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <form onSubmit={this.onCreate}>
            <NanoFlex className="formContainer" flexDirection="column">
              <NanoFlex className="formWrapper">
                <Input title={this.translation.librairies.description} placeholder={this.translation.librairies.description} defaultValue={this.state.description} onChangeAction={(val) => this.setState({ description: val })} error={this.state.descriptionError} />
              </NanoFlex>
              <NanoFlex className="formWrapper" justifyContent="flex-end">
                <ToggleButtonPrive active={this.state.isPersonal} clickAction={() => this.setState({ isPersonal: !this.state.isPersonal })} />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="bottomWrapper">
              <ButtonCTA label={this.translation.librairies.add} type="submit" />
            </NanoFlex>
          </form>
        </NanoFlex>
      </StyledAddLibrairieModal>
    );
  }
}
