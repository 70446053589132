/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Type from "../../Typography.js";
import NanoFlex from "../../NanoFlex.js";

import { ReactComponent as Eye } from "../../icons/eye.svg";
import { ReactComponent as EyeClosed } from "../../icons/eyeClosed.svg";
import { ReactComponent as Edit } from "../../icons/edit.svg";

const StyledInput = styled(NanoFlex)`
  min-width: 240px;
  height: auto;
  position: relative;

  .title {
    color: ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    margin-bottom: 4px;
  }
  .inputWrapper {
    font-family: ${(props) => props.theme.font};
  }
  input {
    border: 1px solid ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    padding: 8px;
    width: 100%;
    font-size: 14px;
    color: ${(props) => props.theme.color.main.font};
    font-family: ${(props) => props.theme.font};
    background-color: ${(props) => (props.error ? "#FFFDDC" : props.disabled ? props.theme.color.colorsPalette.lighterGrey : props.theme.color.main.white)};
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    text-align: ${(props) => (props.center ? "center" : "left")};
    &::placeholder {
      font-family: ${(props) => props.theme.font};
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.color.main.font};
    }
  }
  .eye {
    position: absolute;
    bottom: 1px;
    right: 1px;
    height: 33px;
    width: 40px;
    user-select: none;
    background-color: ${(props) => props.theme.color.main.white};
    svg {
      cursor: pointer;
      path {
        fill: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
  }
  .editable {
    position: absolute;
    bottom: 1px;
    right: 1px;
    height: 33px;
    width: 32px;
    user-select: none;
    background-color: ${(props) => props.theme.color.main.white};
    svg {
      cursor: pointer;
      transform: ${(props) => (props.editable ? "unset" : "rotate(360deg) scaleX(-1)")};
      path {
        fill: ${(props) => (props.editable ? props.theme.color.main.green : props.theme.color.main.font)};
      }
    }
  }
`;

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEye: false,
    };

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue || this.props.forceSelection) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  toggleEye = () => {
    this.setState({ showEye: !this.state.showEye });
  };

  onInputKeyDown = (e) => {
    if (this.props.isNumeric) {
      var key = e.keyCode || e.which;
      if (
        !(
          (!e.shiftKey &&
            !e.altKey &&
            !e.ctrlKey &&
            // numbers
            key >= 48 &&
            key <= 57) ||
          // Numeric keypad
          (key >= 96 && key <= 105) ||
          // Backspace and Tab and Enter
          key === 8 ||
          key === 9 ||
          key === 13 ||
          // Home and End
          key === 35 ||
          key === 36 ||
          // left and right arrows
          key === 37 ||
          key === 39 ||
          // up and down arrows
          key === 38 ||
          key === 40 ||
          // Del and Ins
          key === 46 ||
          key === 45
        )
      ) {
        // input is INVALID
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
    }
  };

  toggleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  render() {
    return (
      <StyledInput className="inputContainer" error={this.props.error} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" disabled={this.props.disabled || (this.props.editable && !this.state.showEdit)} editable={this.state.showEdit} center={this.props.center}>
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}

        <NanoFlex className="inputWrapper">
          <input
            ref={this.inputRef}
            type={this.props.eye ? (this.state.showEye ? "text" : "password") : "text"}
            placeholder={this.props.placeholder}
            {...(!this.props.forceValue ? { defaultValue: this.props.defaultValue } : null)}
            {...(this.props.forceValue ? { value: this.props.defaultValue } : null)}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                let val = e.target.value;
                if (this.props.isNumeric) val = Number(val);
                this.props.onChangeAction(val);
              }
            }}
            onKeyPress={(e) => {
              if (this.props.onKeyPress) {
                this.props.onKeyPress(e);
              }
            }}
            onKeyDown={(e) => {
              this.onInputKeyDown(e);
              if (e.returnValue !== false) {
                if (this.props.onKeyDown) {
                  return this.props.onKeyDown(e);
                }
              }
            }}
            name={this.props.name ?? Math.random().toString(36).substring(7)}
            {...(!this.props.enableAutoComplete ? { autoComplete: /chrome/.test(navigator.userAgent.toLowerCase()) ? "new-password" : "off" } : null)}
          />
          {this.props.eye && this.state.showEye && (
            <NanoFlex className="eye" onClick={this.toggleEye}>
              <Eye />
            </NanoFlex>
          )}
          {this.props.eye && !this.state.showEye && (
            <NanoFlex className="eye" onClick={this.toggleEye}>
              <EyeClosed />
            </NanoFlex>
          )}
          {this.props.editable && (
            <NanoFlex className="editable" onClick={this.toggleEdit}>
              <Edit />
            </NanoFlex>
          )}
        </NanoFlex>

        {this.props.menssage && <Type.h6 className="menssage">{this.props.menssage}</Type.h6>}
      </StyledInput>
    );
  }
}

// Set default props
Input.defaultProps = {
  id: 0,
  type: "text",
  title: null,
  defaultValue: "",
  placeholder: "placeholder",
  menssage: null,
  eye: false,
  error: false,
};

export default Input;

// --- // Documentation // --- //
/*
import Input from './../Nano/Input.js';

<Input
    id={0}
    title='Username'
    value={state or prop Value}
    placeholder='Username'
    error={false}
    onChangeAction={() => console.log('onChangeAction')}
/>

<Input
    id={0}
    type={this.state.showPassword ? 'text' : 'password'}
    title='Password'
    value={state or prop Value}
    placeholder=''
    eye={true}
    showPassword={this.state.showPassword}
    error={this.state.error !== null}
    onClickEyeToggle={() => this.setState({ showPassword: !this.state.showPassword })}
    onChangeAction={(currentValue, id, title, type) => this.passwordChange(currentValue)}
/>
*/
