/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

// COMPONENTS
import EmissionList from "./EmissionList.js";
import BaseComponent from "../../../BaseComponent.js";

//Imgs
import { ReactComponent as ArrowLeft } from "./../../../Global/icons/arrowLeft.svg";
import { ReactComponent as ArrowRight } from "./../../../Global/icons/arrowRight.svg";

const StyledResults = styled(NanoFlex)`
  .resultsWrapper {
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    .arrowBtnWrapper {
      width: auto;
      padding: 16px;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .leftArrow {
      border-right: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }
    .rightArrow {
      border-left: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
    }
    .disabledArrow {
      pointer-events: none;
      opacity: 0.2;
    }
    .emissionsWrapper {
      padding: 0 4px 4px 4px;
      height: calc(100vh - 332px);
      overflow-x: hidden;
      scroll-behavior: smooth;
      transition: ${(props) => props.theme.transition};
      .noResults {
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    h3 {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
      }
    }
  }
`;

export class Results extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabledLeft: true,
      disabledRight: false,
    };

    this.gridContainer = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    /*Re-Validate Disabled Arrows on Results Change*/
    if (JSON.stringify(prevProps.results) !== JSON.stringify(this.props.results)) {
      this.validateDisableArrows();
    }
  }

  scrollGrid = (position) => {
    this.gridContainer.current.scrollLeft += position;
    this.validateDisableArrows();
  };

  validateDisableArrows = () => {
    this.setState({ disabledLeft: this.gridContainer.current.scrollLeft === 0, disabledRight: this.gridContainer.current.scrollLeft === this.gridContainer.current.scrollWidth - this.gridContainer.current.clientWidth });
  };

  render() {
    let emissions = this.props.results?.channels;
    return (
      <StyledResults>
        <NanoFlex className="resultsWrapper" justifyContent="space-between">
          <NanoFlex className={`arrowBtnWrapper leftArrow ${this.state.disabledLeft ? "disabledArrow" : ""}`} onClick={() => this.scrollGrid(-120)}>
            <ArrowLeft />
          </NanoFlex>
          <NanoFlex ref={this.gridContainer} className="emissionsWrapper" justifyContent="flex-start" alignItems="flex-start">
            {/* EMISSION - TO REPEAT */}
            {emissions &&
              emissions.map((v) => {
                //SET WICH IS THE MAXIMUM EMISSIONS BY CHANNEL
                v.maxCardsList = Math.max.apply(
                  Math,
                  emissions.map((m) => {
                    return m.emissions.length;
                  })
                );
                return <EmissionList data={v} viewDetails={this.props.viewDetails} key={`emissionList_${v.id}}`} filtersSelected={this.props.filtersSelected} />;
              })}

            {/* NO RESULTS */}
            {(!emissions || emissions.length === 0) && (
              <NanoFlex className="noResults">
                <Type.h3><b>{this.translation.global.noResults}</b></Type.h3>
              </NanoFlex>
            )}
          </NanoFlex>
          <NanoFlex className={`arrowBtnWrapper rightArrow ${this.state.disabledRight ? "disabledArrow" : ""}`} onClick={() => this.scrollGrid(120)}>
            <ArrowRight />
          </NanoFlex>
        </NanoFlex>
      </StyledResults>
    );
  }
}
