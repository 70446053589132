import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

//IMGS
import { ReactComponent as Thumb } from "../../icons/thumbsUp.svg";

const StyledPluDepluButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  .pluDepluButtonWrapper {
    .btnWrapper {
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      cursor: pointer;
      padding: 6px 16px;
      pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
      .iconBtnWrapper {
        width: auto;
        margin-right: 8px;
        svg {
          width: 14px;
          height: auto;
        }
      }
      .textBtnWrapper {
        p {
          line-height: initial;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .pluWrapper {
      background-color: ${(props) => (props.plu ? props.theme.color.main.green : props.deplu ? props.theme.color.colorsPalette.lightGrey : props.theme.color.colorsPalette.lightGrey)};
      border-radius: 60px 0 0 60px;
      border-right: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
      .iconBtnWrapper {
        svg {
          path {
            fill: ${(props) => (props.plu ? props.theme.color.main.white : props.deplu ? props.theme.color.main.font : props.theme.color.main.font)};
          }
        }
      }
      .textBtnWrapper {
        p {
          color: ${(props) => (props.plu ? props.theme.color.main.white : props.deplu ? props.theme.color.main.font : props.theme.color.main.font)};
          font-weight: ${(props) => (props.plu ? "bold" : props.deplu ? "normal" : "normal")};
        }
      }
    }

    .depluWrapper {
      background-color: ${(props) => (props.plu ? props.theme.color.colorsPalette.lightGrey : props.deplu ? props.theme.color.colorsPalette.red : props.theme.color.colorsPalette.lightGrey)};
      border-radius: 0 60px 60px 0;
      .iconBtnWrapper {
        svg {
          transform: scaleY(-1);
          path {
            fill: ${(props) => (props.plu ? props.theme.color.main.font : props.deplu ? props.theme.color.main.white : props.theme.color.main.font)};
          }
        }
      }
      .textBtnWrapper {
        p {
          color: ${(props) => (props.plu ? props.theme.color.main.font : props.deplu ? props.theme.color.main.white : props.theme.color.main.font)};
          font-weight: ${(props) => (props.plu ? "nomal" : props.deplu ? "bold" : "normal")};
        }
      }
    }
  }
`;

export default class PluDepluButton extends BaseComponent {
  
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledPluDepluButton flexDirection="column" plu={this.props.plu} deplu={this.props.deplu} disabled={this.props.disabled} onClick={this.props.clickAction}>
        <NanoFlex className="pluDepluButtonWrapper">
          <NanoFlex className="btnWrapper pluWrapper" justifyContent="flex-start" onClick={this.props.onPluClick}>
            <NanoFlex className="iconBtnWrapper">
              <Thumb />
            </NanoFlex>
            <NanoFlex className="textBtnWrapper">
              <Type.p>Plu</Type.p>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="btnWrapper depluWrapper" justifyContent="flex-start" onClick={this.props.onDepluClick}>
            <NanoFlex className="iconBtnWrapper">
              <Thumb />
            </NanoFlex>
            <NanoFlex className="textBtnWrapper">
              <Type.p>Deplu</Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledPluDepluButton>
    );
  }
}
