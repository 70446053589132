import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

//IMGS
// import { ReactComponent as Thumb } from "../../icons/thumbsUp.svg";

const StyledSwitchEmissionDiffusion = styled(NanoFlex)`
  height: auto;
  .switchWrapper {
    .btnWrapper {
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      cursor: pointer;
      padding: 8px 16px;
      pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
      opacity: ${(props) => (props.disabled ? "0.4" : "1")};

      .textBtnWrapper {
        p {
          line-height: initial;
          text-transform: uppercase;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
    .emissionSide {
      border-radius: 60px 0 0 60px;
      background-color: ${(props) => (props.emission ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
      .textBtnWrapper {
        p {
          color: ${(props) => (props.emission ? props.theme.color.main.white : props.theme.color.main.font)};
        }
      }
    }
    .difussionSide {
      border-radius: 0 60px 60px 0;
      background-color: ${(props) => (props.diffusion ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
      .textBtnWrapper {
        p {
          color: ${(props) => (props.diffusion ? props.theme.color.main.white : props.theme.color.main.font)};
        }
      }
    }
  }
`;

export default class SwitchEmissionDiffusion extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSwitchEmissionDiffusion flexDirection="column" emission={!this.props.aggregateEmissions} diffusion={this.props.aggregateEmissions} disabled={this.props.disabled}>
        <NanoFlex className="switchWrapper">
          <NanoFlex className="btnWrapper emissionSide" justifyContent="flex-start" onClick={()=>this.props.onAggregateEmissionsChange(false)}>
            <NanoFlex className="textBtnWrapper">
              <Type.p>{this.translation.global.emission}</Type.p>
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="btnWrapper difussionSide" justifyContent="flex-start" onClick={()=>this.props.onAggregateEmissionsChange(true)}>
            <NanoFlex className="textBtnWrapper">
              <Type.p>{this.translation.global.difussion}</Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledSwitchEmissionDiffusion>
    );
  }
}
