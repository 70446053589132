import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import Settings from "../../Settings.json";
import { dateComparison } from "./DateRangePicker.js";
import moment from "moment";
import { Popper, Reference, Manager } from "react-popper";

import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import DateRangePicker from "./DateRangePicker.js";
import InputDate from "./InputDate.js";

import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";

//const multiple = 8;

let securityBlocker = true;
const securityBlockerAction = () => {
  securityBlocker = false;
  setTimeout(() => {
    securityBlocker = true;
  }, 300);
};

const checkInvalidDate = (d) => (d !== "Invalid date" ? d : null);

const mock = {
  startDate: "27-02-2020",
  endDate: "27-04-2020",
};

const StyledCalendarRange = styled.div`
  user-select: none;
  .inputDateForm {
    margin-right: 8px;
    p {
      margin-bottom: 4px;
    }
  }

  .icon {
    padding-top: 12px;
    width: 20px;
    cursor: pointer;
    svg path {
      fill: ${(props) => (props.isOpen ? props.theme.color.main.green : props.theme.color.main.font)};
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .dropdown-content {
    position: absolute;
    border: 1px solid ${(props) => props.theme.color.main.font};
    left: 45%;
    margin: 20px 0 0 0;
  }

  .popUpContainer {
    z-index: 9;
    background-color: ${(props) => props.theme.color.main.white};
    .datePickerWrapper {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
  }
  .iconWrapper {
    width: auto;
  }
`;

class CalendarRange extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      onClickCounter: 0,

      isOpenDropdown: false,
    };

    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState(
      this.updateDates({
        startDate: this.props.startDate,
        endDate: this.props.endDate,
      })
    );
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.startDate !== this.state.startDate || prevState.endDate !== this.state.endDate) {
      // if (prevState.startDate === null && prevState.endDate === null) {
      //   this.setState({ endDate: this.state.startDate ?? this.state.endDate, startDate: this.state.startDate ?? this.state.endDate });
      // } else {
      let range = this.updateDates({ startDate: this.state.startDate, endDate: this.state.endDate });

      if (range.startDate !== this.state.startDate || range.endDate !== this.state.endDate) {
        this.setState(range);
      } else {
        //Validate min max date
        const newDate = {
          startDate: checkInvalidDate(this.state.startDate) !== null ? this.state.startDate : null,
          endDate: checkInvalidDate(this.state.endDate) !== null ? this.state.endDate : null,
        };
        this.props.onChangeSelection(newDate);
      }
      // }
    }

    if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate || this.props.forceSelection) {
      this.setState(
        this.updateDates({
          startDate: this.props.startDate,
          endDate: this.props.endDate,
        })
      );
    }

    if (prevProps.minDate?.isSame(this.props.minDate) === false || prevProps.maxDate?.isSame(this.props.maxDate) === false) {
      this.setState(this.updateDates({ startDate: this.state.startDate, endDate: this.state.endDate }));
    }

    if (this.state.isOpenDropdown !== prevState.isOpenDropdown && this.props.isOpenDropdownChanged) {
      this.props.isOpenDropdownChanged(this.state.isOpenDropdown);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  updateDates = (inputDate) => {
    const input = { ...inputDate };
    const dateFormat = Settings.moment.date;

    if (this.props.minDate && moment(input.startDate, dateFormat) < this.props.minDate) {
      input.startDate = moment(this.props.minDate, dateFormat).format(dateFormat);
    }

    if (this.props.maxDate && moment(input.startDate, dateFormat) > this.props.maxDate) {
      input.startDate = moment(this.props.maxDate, dateFormat).format(dateFormat);
    }

    if (this.props.minDate && moment(input.endDate, dateFormat) < this.props.minDate) {
      input.endDate = moment(this.props.minDate, dateFormat).format(dateFormat);
    }

    if (this.props.maxDate && moment(input.endDate, dateFormat) > this.props.maxDate) {
      input.endDate = moment(this.props.maxDate, dateFormat).format(dateFormat);
    }

    const start = input.startDate ? moment(input.startDate, dateFormat).format(dateFormat) : null;
    const end = input.endDate ? moment(input.endDate, dateFormat).format(dateFormat) : null;

    return {
      startDate: checkInvalidDate(start),
      endDate: checkInvalidDate(end),
    };
  };

  updateMainState = (newStartDate, newEndDate) => {
    if (!securityBlocker) return;
    securityBlockerAction();

    if (this.state.startDate === null && this.state.endDate === null) {
      this.setState({
        startDate: newStartDate ?? newEndDate,
        endDate: newEndDate ?? newStartDate,
      });
    } else {
      if (dateComparison(newStartDate, newEndDate) && this.state.onClickCounter > 1) {
        this.setState({
          startDate: newStartDate,
          endDate: newStartDate,
        });
      } else {
        this.setState({
          startDate: newStartDate,
          endDate: newEndDate,
        });
      }
    }
  };

  updateStartDate = (newData) => {
    if (newData !== null) {
      let startDate = moment(newData, Settings.moment.date);
      let endDate = moment(this.state.endDate, Settings.moment.date);

      if (startDate > endDate) {
        this.setState({ startDate: newData, endDate: newData, onClickCounter: 2 });
      } else {
        if (newData !== this.state.startDate) {
          this.setState({ startDate: newData, onClickCounter: 2 });
        }
      }
    } else {
      this.setState({ startDate: newData, onClickCounter: 2 });
    }
  };

  updateEndDate = (newData) => {
    if (newData !== null) {
      let startDate = moment(this.state.startDate, Settings.moment.date);
      let endDate = moment(newData, Settings.moment.date);

      if (startDate > endDate) {
        this.setState({ startDate: newData, endDate: newData, onClickCounter: 2 });
      } else {
        if (newData !== this.state.endDate) {
          this.setState({ endDate: newData, onClickCounter: 2 });
        }
      }
    } else {
      this.setState({ endDate: newData, onClickCounter: 2 });
    }
  };

  handleOutsideClick = (e) => {
    /*
      Has onClickOutsideClose Enabled
      Has isToggle Enabled
      Is Tooltip Open
      Is Click Outside Tooltip
      */
    if (this.calendarRef && this.calendarRef.current && this.state.isOpenDropdown && !this.calendarRef.current.contains(e.target)) {
      this.setState({ isOpenDropdown: false });
    }
  };

  render() {
    return (
      <StyledCalendarRange isOpen={this.state.isOpenDropdown}>
        <NanoFlex>
          <NanoFlex className="inputDateForm" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Type.p>{this.translation.filters.from}</Type.p>
            <InputDate date={this.state.startDate} onDateChange={(newData) => this.updateStartDate(newData)} />
          </NanoFlex>
          <NanoFlex className="inputDateForm" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Type.p>{this.translation.filters.to}</Type.p>
            <InputDate date={this.state.endDate} onDateChange={(newData) => this.updateEndDate(newData)} />
          </NanoFlex>
          <NanoFlex className="iconWrapper">
            <div ref={this.calendarRef}>
              <Manager>
                <Reference>
                  {(targetProps) => {
                    return (
                      <div
                        ref={targetProps.ref}
                        onClick={() => {
                          this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
                        }}>
                        <NanoFlex className="icon">
                          <CalendarIcon />
                        </NanoFlex>
                      </div>
                    );
                  }}
                </Reference>
                {this.state.isOpenDropdown && (
                  <Popper placement={this._isMobile ? "top" : "bottom"}>
                    {({ ref, style, placement, arrowProps }) => (
                      <div className="popUpContainer" ref={ref} style={style} data-placement={placement}>
                        <NanoFlex className="datePickerWrapper" alignItems="flex-start">
                          <DateRangePicker
                            multipleDateRangePickers
                            mainViewMonth={this.state.startDate}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            minDate={this.props.minDate}
                            maxDate={this.props.maxDate}
                            onClickCounter={this.state.onClickCounter}
                            onUpdateClickCounter={(newCounter) => this.setState({ onClickCounter: newCounter })}
                            onDateChange={(newStartDate, newEndDate) => this.updateMainState(newStartDate, newEndDate)}
                          />
                          {/* {!this._isMobile && (
                            <DateRangePicker
                              multipleDateRangePickers
                              secondDefaultMonth
                              mainViewMonth={this.state.startDate}
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              minDate={this.props.minDate}
                              maxDate={this.props.maxDate}
                              onClickCounter={this.state.onClickCounter}
                              onUpdateClickCounter={(newCounter) => this.setState({ onClickCounter: newCounter })}
                              onDateChange={(newStartDate, newEndDate) => this.updateMainState(newStartDate, newEndDate)}
                            /> 
                          )}*/}
                        </NanoFlex>
                      </div>
                    )}
                  </Popper>
                )}
              </Manager>
            </div>
          </NanoFlex>
        </NanoFlex>
      </StyledCalendarRange>
    );
  }
}

export default CalendarRange;

// Set default props
CalendarRange.defaultProps = {
  inputDate: mock,
};

// --- // Documentation // --- //
/*
import Calendar from './../Micro/Calendar.js';

<Calendar
    inputDate={{
        startDate: "27-02-2020",
        endDate: "27-04-2020"
    }}
    onChangeSelection={(obj) => console.log(obj)}
/>
*/
