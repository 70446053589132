import React from "react";
import { Redirect } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import librariesService from "../../../services/LibrariesService";

//COMPONENTS
import { ListLibrairies } from "./ListLibrairies.js";
import { TableLibrairies } from "./TableLibrairies.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

const StyledLibrairies = styled(NanoFlex)`
  min-width: 0;
`;

export class Librairies extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      folders: null,
      selectedIndex: 0,
      redirect: null,
      loading: false,
    };

    sessionStorage.setItem("lastPathname", window.location.pathname);
  }

  componentDidMount() {
    super.componentDidMount();

    this.getFolders(true);
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFolders = (firstLoad) => {
    librariesService
      .getFolders()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ folders: data, selectedIndex: firstLoad || this.state.selectedIndex > data.length - 1 ? 0 : this.state.selectedIndex });
      });
  };

  onReportClick = (id) => {
    this.setState({ loading: true }, () => {
      librariesService
        .getReportById(id)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState({ loading: false }, () => {
            if (data) {
              //Set filters and columns/lines to load
              sessionStorage.setItem("filtersByReport", JSON.stringify(data.filters));
              sessionStorage.setItem("jsonLinesByReport", data.jsonLines);
              sessionStorage.setItem("jsonColumnsByReport", data.jsonColumns);
              //Navigate to page
              if (data.reportType === this._reportType.ReportsIntermediaires) this.setState({ redirect: "/Intermediaires" });
              if (data.reportType === this._reportType.ReportsDefinitives) this.setState({ redirect: "/Definitifs" });
              if (data.reportType === this._reportType.ReportsDynamique) this.setState({ redirect: "/Dynamique" });
              if (data.reportType === this._reportType.TVReplay) this.setState({ redirect: "/TvReplay" });
              if (data.reportType === this._reportType.Palmares) this.setState({ redirect: "/Palmares" });
              if (data.reportType === this._reportType.Verbatim) this.setState({ redirect: "/Verbatim" });
              if (data.reportType === this._reportType.Profile) this.setState({ redirect: "/Profil" });
            }
          });
        });
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    let isLoading = this.state.folders === null || this.state.loading;
    return (
      <StyledLibrairies>
        {isLoading && (
          <NanoFlex>
            <Loading />
          </NanoFlex>
        )}
        {!isLoading && (
          <NanoFlex className="librairiesWrapper">
            <ListLibrairies folders={this.state.folders} selectedIndex={this.state.selectedIndex} onSelectedIndexChange={(i) => this.setState({ selectedIndex: i })} refetchFolders={this.getFolders} />
            <TableLibrairies folder={this.state.folders[this.state.selectedIndex]} refetchFolders={this.getFolders} onReportClick={this.onReportClick} />
          </NanoFlex>
        )}
      </StyledLibrairies>
    );
  }
}
