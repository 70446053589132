import React, { Component } from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import pivotService from "./../../../../services/PivotService";
import { downloadBlob } from "./../../../Global/tools.js";

// COMPONENTS
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import PluDepluButton from "../../../Global/Elements/Buttons/PluDepluButton.js";
import DetailViewButton from "../../../Global/Elements/Buttons/DetailViewButton.js";
import TopButton from "../../../Global/Elements/Buttons/TopButton.js";
import { SaveReportModal } from "../../../Global/Elements/Modals/SaveReportModal.js";
import Modal from "../../../Global/Elements/Modals/Modal.js";

//Imgs
import Excel from "../../../Global/icons/excel.svg";
// import Pdf from "../../../Global/icons/pdf.svg";
import Save from "../../../Global/icons/save.svg";

const StyledOptionsFilters = styled(NanoFlex)`
  height: auto;
  padding: 16px 0;
  user-select: none;
  .infoWrapper {
    margin-bottom: 8px;
    .asideWrapper {
      .optionBtnWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .elementWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .formWrapper {
        max-width: 232px;
      }
      .cta {
        width: auto;
        .ctaWrapper {
          font-size: 14px;
          border-radius: 60px;
          padding: 8px 16px;
          letter-spacing: 0.5px;
        }
      }
      .asideBlock {
        width: auto;
        margin-bottom: 8px;
        .titleWrapper {
          width: auto;
          margin-right: 8px;
        }
        .infoContainer {
          width: auto;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export class OptionsFilters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSaveReport: false,
      // questionSelected: this.props.questionSelected,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.questionSelected !== this.props.questionSelected) {
    //   this.setState({ questionSelected: this.props.questionSelected });
    // }
    // if (prevState.questionSelected !== this.state.questionSelected && this.props.onQuestionSelectedChange) {
    //   this.props.onQuestionSelectedChange(this.state.questionSelected);
    // }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onExcelExport = () => {
    pivotService
      .getPivotExcel({
        ...this.props.filtersSelected,
        ...{ columnsLines: this.props.pivotSettings },
      })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((blob) => {
        if (blob) {
          downloadBlob(blob, `Report-verbatim.xlsx`);
        }
      });
  };

  onPluClick = () => {
    this.props.onQuestionSelectedChange(this.props.questionSelected !== 1 ? 1 : null);
  };

  onDepluClick = () => {
    this.props.onQuestionSelectedChange(this.props.questionSelected !== 2 ? 2 : null);
  };

  onDetailedClick = () => {
    this.props.onSelectedViewChange(1);
  };

  onSimpleClick = () => {
    this.props.onSelectedViewChange(2);
  };

  render() {
    return (
      <StyledOptionsFilters flexDirection="column">
        <Modal show={this.state.openSaveReport} content={<SaveReportModal reportType={6} filtersSelected={this.props.filtersSelected} cancelAction={() => this.setState({ openSaveReport: false })} nextAction={() => this.setState({ openSaveReport: false })} />} />
        <NanoFlex className="infoWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper" justifyContent="flex-start">
            <NanoFlex className="optionBtnWrapper">
              <PluDepluButton plu={this.props.questionSelected === 1} deplu={this.props.questionSelected === 2} disabled={!this.props.hasData} onPluClick={this.onPluClick} onDepluClick={this.onDepluClick} />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <TopButton values={[10, 15, 20]} allowNull selectedValue={this.props.top} disabled={!this.props.hasData} onSelectedValueChange={(v) => this.props.onTopChange(v)} />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <DetailViewButton detailed={this.props.selectedView === 1} simple={this.props.selectedView === 2} onDetailedClick={this.onDetailedClick} onSimpleClick={this.onSimpleClick} />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="asideWrapper" justifyContent="flex-end">
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Excel} clickAction={this.onExcelExport} disabled={!this.props.hasData} />
            </NanoFlex>
            {/* <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Pdf} />
            </NanoFlex> */}
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Save} clickAction={() => this.setState({ openSaveReport: true })} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledOptionsFilters>
    );
  }
}
