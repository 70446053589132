import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import { formatTime } from "../../tools.js";
import * as _ from "lodash";
import moment from "moment";
import Settings from "../../Settings.json";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";
import { ReactComponent as Plus } from "../../icons/plus.svg";
import { ReactComponent as Minus } from "../../icons/minus.svg";
import { ReactComponent as Check } from "../../icons/check.svg";

//COMPONENTS
import { SearchBar } from "../../Elements/Forms/SearchBar.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";
import SortBy from "../../Elements/Buttons/SortBy.js";

const StyledDiffusionModal = styled(NanoFlex)`
  width: 70vw;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  user-select: none;
  .diffusionModalContent {
    .headerModal {
      position: relative;
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 16px 24px;

      h5 {
        text-transform: uppercase;
      }
      .closeBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: auto;
        width: auto;

        svg {
          width: 10px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
    .contentModal {
      .topWrapper {
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        height: auto;
        padding: 16px;
      }
      .listWrapper {
        padding: 16px;
        max-height: 60vh;
        height: 60vh;
        overflow: auto;

        .listContainer {
          .headerList {
            background-color: ${(props) => props.theme.color.main.font};
            border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
            padding: 0px 8px;
            height: auto;
            .headerItem {
              height: auto;
              padding: 8px 0;
              margin-right: 8px;
              p {
                text-transform: uppercase;
                color: ${(props) => props.theme.color.main.white};
              }
              &:last-child {
                margin-right: 0;
              }
            }

            .selectAll {
              width: auto;
              cursor: pointer;
            }
          }
          .sortByItem {
            cursor: pointer;
            .sortContainer {
              width: auto;
            }
          }
          .listItems {
            overflow: auto;
            .itemLine {
              opacity: 0.6;
              height: auto;
              padding: 8px 8px 8px 7px;
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
              background-color: ${(props) => props.theme.color.main.white};
              cursor: pointer;
              .itemInfo {
                margin-right: 8px;
                text-align: center;
                width: auto;
                overflow: hidden;
                p {
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                &:last-child {
                  margin-right: 0;
                }
              }
              .itemIcon {
                width: 26px;
                padding: 7px 6px 6px 6px;
                height: 26px;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
              }
              .colL {
                text-align: left;
              }
              &:hover {
                background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              }
            }
            .selectedItem {
            opacity: 1;
            .itemIcon {
              width: 26px;
                padding: 7px 6px 6px 6px;
                height: 26px;
                background-color: ${(props) => props.theme.color.main.green};
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.white};
                  }
                }
              }
          }
          }
          .noResultsWrapper {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            h3 {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.colorsPalette.grey};
            }
          }

          .itemIcon {
            width: 26px;
                padding: 7px 6px 6px 6px;
                height: 26px;
            background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            svg {
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            /* .minusIcon {
              display: none;
            } */
          }
          .selectedItem {
            opacity: 1;
            .itemIcon {
                width: 26px;
                padding: 7px 6px 6px 6px;
                height: 26px;
                background-color: ${(props) => props.theme.color.main.green};
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.white};
                  }
                }
              }
          }
          .colL {
            flex: 4;
          }
          .colS {
            flex: 1;
          }
        }
      }
    }
    .bottomWrapper {
      padding: 24px;
      height: auto;
      border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
      .cta {
        max-width: 200px;
      }
    }
  }
`;

export class DiffusionModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      term: "",
      sortDirection: "", //Asc|Desc|Null
      selectedIds: this.props.selectedIds ? this.props.selectedIds : [],
    };
  };

  componentDidMount() {
    super.componentDidMount();

    /*Prevent different IsAllSelected States from Props to State */
    this.validateIsAllSelectedProp();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedIds) !== JSON.stringify(this.props.selectedIds)) {
      this.setState({ selectedIds: this.props.selectedIds ? this.props.selectedIds : [] });
      this.validateIsAllSelectedProp();
    }
    if (JSON.stringify(prevProps.diffusions) !== JSON.stringify(this.props.diffusions)) {
      this.validateIsAllSelectedProp();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onSort = () => {
    let direction = null;

    switch (this.state.sortDirection) {
      case null:
        direction = "asc";
        break;
      case "asc":
        direction = "desc";
        break;
      case "desc":
        direction = null;
        break;
    }

    this.setState({ sortDirection: direction });
  };

  getFilteredResults = () => {
    if (this.props.diffusions) {
      let options = this.state.sortDirection ? _.orderBy(this.props.diffusions, (d) => d.name, this.state.sortDirection) : this.props.diffusions;

      if (this.state.term.trim() !== "") {
        let upperTerm = this.state.term.trim().toUpperCase();
        return options.filter((v) => {
          let upperName = v.name.trim().toUpperCase();
          return upperName.includes(upperTerm);
        });
      } else {
        return options;
      }
    } else return null;
  };

  onOptionClick = (v) => {
    if (this.state.selectedIds.includes(v)) {
      this.setState({
        selectedIds: this.state.selectedIds.filter((id) => {
          return id !== v;
        }),
      });
    } else {
      this.setState({ selectedIds: [...this.state.selectedIds, ...[v]] });
    }
  };

  onSelectAllClick = () => {
    /*Get Filtered Results and ids */
    let results = this.getFilteredResults();
    let resultsIds = results.map((v) => v.id);
    /*Validate if is All Selected */
    if (this.isAllSelected(results)) {
      /*Get Ids Excluding current filtered results ids */
      let excludedSelectedIds = this.state.selectedIds.filter((v) => {
        return resultsIds.includes(v) === false;
      });
      this.setState({ selectedIds: excludedSelectedIds });
    } else {
      /*Merge filtered results ids and state selected ids and Distinct */
      let selectedIds = [...new Set([...resultsIds, ...this.state.selectedIds])];
      this.setState({
        selectedIds: selectedIds,
      });
    }
  };

  validateIsAllSelectedProp = () => {
    if (this.props.isAllSelected === true) {
      this.setState({
        selectedIds:
          this.props.diffusions?.map((v) => {
            return v.id;
          }) ?? [],
      });
    }
  };

  isAllSelected = (results) => {
    let resultsIds = results.map((v) => v.id);
    let filteredSelectedIds = this.state.selectedIds.filter((v) => {
      return resultsIds.includes(v);
    });
    return filteredSelectedIds.length === results.length;
  };

  onCancel = () => {
    this.setState(this.getDefaultState(), () => {
      if (this.props.cancelAction) this.props.cancelAction();
    });
  };

  onNextAction = () => {
    if (this.props.nextAction) {
      let isAllSelected = this.isAllSelected(this.props.diffusions);
      this.props.nextAction(!isAllSelected ? this.state.selectedIds : [], isAllSelected);
    }
  };

  render() {
    let filteredResults = this.getFilteredResults() ?? [];
    return (
      <StyledDiffusionModal justifyContent="flex-start">
        <NanoFlex className="diffusionModalContent" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="headerModal">
            <Type.h5>{this.translation.global.diffusion}</Type.h5>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="contentModal" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="topWrapper" justifyContent="flex-start">
              <SearchBar term={this.state.term} onTermChange={(v) => this.setState({ term: v })} />
            </NanoFlex>
            <NanoFlex className="listWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="listContainer" flexDirection="column">
                <NanoFlex className="headerList">
                  <NanoFlex className={`headerItem selectAll ${this.isAllSelected(filteredResults) ? "selectedItem" : ""}`} onClick={this.onSelectAllClick}>
                    <NanoFlex className="itemIcon">
                      {/* <Plus className="plusIcon" />
                      <Minus className="minusIcon" /> */}
                      <Check />
                    </NanoFlex>
                  </NanoFlex>

                  <NanoFlex className="headerItem sortByItem colL" justifyContent="space-between" onClick={this.onSort}>
                    <Type.p>
                      <b>{this.translation.global.diffusion}</b>
                    </Type.p>
                    <NanoFlex className="sortContainer">
                      <SortBy sortUp={this.state.sortDirection === "asc"} sortDown={this.state.sortDirection === "desc"} />
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="headerItem colS">
                    <Type.p>
                      <b>{this.translation.diffusion.n}</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="headerItem colS">
                    <Type.p>
                      <b>{this.translation.diffusion.satMoy}</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="headerItem colS">
                    <Type.p>
                      <b>{this.translation.diffusion.beginningHour}</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="headerItem colS">
                    <Type.p>
                      <b>{this.translation.diffusion.date}</b>
                    </Type.p>
                  </NanoFlex>
                  <NanoFlex className="headerItem colS">
                    <Type.p>
                      <b>{this.translation.diffusion.channel}</b>
                    </Type.p>
                  </NanoFlex>
                </NanoFlex>
                {/* NO RESULTS */}
                {(!filteredResults || filteredResults.length === 0) && (
                  <NanoFlex className="noResultsWrapper">
                    <Type.h3>
                      <b>{this.translation.global.noResults}</b>
                    </Type.h3>
                  </NanoFlex>
                )}
                {/* ITEMS LIST */}
                {filteredResults && filteredResults.length > 0 && (
                  <NanoFlex className="listItems" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                    {/* ITEM */}
                    {filteredResults.map((d) => (
                      <NanoFlex key={d.id} className={`itemLine ${(this.state.selectedIds ? this.state.selectedIds.includes(d.id) : false) ? "selectedItem" : ""}`} onClick={() => this.onOptionClick(d.id)}>
                        <NanoFlex className="itemInfo itemIcon">
                          {/* <Plus className="plusIcon" />
                          <Minus className="minusIcon" /> */}
                          <Check />
                        </NanoFlex>
                        <NanoFlex className="itemInfo colL">
                          <Type.p>{d.name}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="itemInfo colS">
                          <Type.p>{d.sat?.sample}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="itemInfo colS">
                          <Type.p>{d.sat?.value}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="itemInfo colS">
                          <Type.p>{formatTime(d.startTime)}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="itemInfo colS">
                          <Type.p>{moment(d.date).format(Settings.inputDate.outputFormat)}</Type.p>
                        </NanoFlex>
                        <NanoFlex className="itemInfo colS">
                          <Type.p>{d.channel?.name}</Type.p>
                        </NanoFlex>
                      </NanoFlex>
                    ))}
                    {/* END ITEM */}
                  </NanoFlex>
                )}
                {/* END ITEMS LIST */}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="bottomWrapper">
            <ButtonCTA label={this.translation.librairies.add} clickAction={this.onNextAction} />
          </NanoFlex>
        </NanoFlex>
      </StyledDiffusionModal>
    );
  }
}
