/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import librariesService from "../../../services/LibrariesService";
import { elementMatchesAny } from "../../Global/tools";

//Imgs
import { ReactComponent as ArrowLeft } from "../../Global/icons/arrowLeft.svg";
import AddFolder from "../../Global/icons/addFolder.svg";
import { ReactComponent as OpenFolder } from "../../Global/icons/librairies.svg";
import { ReactComponent as ClosedFolder } from "../../Global/icons/closedFolder.svg";
import { ReactComponent as OpenFolderPrivate } from "../../Global/icons/openFolderPrivate.svg";
import { ReactComponent as ClosedFolderPrivate } from "../../Global/icons/closedFolderPrivate.svg";
import { ReactComponent as Delete } from "../../Global/icons/delete.svg";

// COMPONENTS
import ButtonWithIcon from "../../Global/Elements/Buttons/ButtonWithIcon.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { AddLibrairieModal } from "../../Global/Elements/Modals/AddLibrairieModal.js";
import { DeleteModal } from "../../Global/Elements/Modals/DeleteModal.js";

const StyledListLibrairies = styled(NanoFlex)`
  width: ${(props) => (props.isCollapsed ? "60px" : "220px")};
  transition: ${(props) => props.theme.transition};
  .listLibrairiesWrapper {
    position: relative;
    box-shadow: ${(props) => props.theme.boxShadow};
    transition: ${(props) => props.theme.transition};
    background-color: ${(props) => (props.isCollapsed ? props.theme.color.main.font : props.theme.color.main.white)};
    .topWrapper {
      width: ${(props) => (props.isCollapsed ? "auto" : "100%")};
      height: ${(props) => (props.isCollapsed ? "auto" : "100%")};
      transform: ${(props) => (props.isCollapsed ? "rotate(-90deg)" : "unset")};
      .headerWrapper {
        background-color: ${(props) => (props.isCollapsed ? "unset" : props.theme.color.main.green)};
        height: auto;
        padding: 16px;
        h5 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .listContainer {
        height: calc(100vh - 152px);
        display: ${(props) => (props.isCollapsed ? "none" : "flex")};
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        padding: 8px 8px 0 8px;
        overflow: auto;
        .listWrapper {
          padding: 8px;
          height: auto;
        }
        .itemList {
          cursor: pointer;
          height: auto;
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          margin-bottom: 4px;
          overflow: auto;
          .iconWrapper {
            width: auto;
            padding: 8px;
            svg {
              width: 16px;
              height: auto;
              max-height: 10px;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            .openFolder {
              display: none;
            }
            .closedFolder {
              display: flex;
            }
            .openFolderPrivate {
              display: none;
            }
            .closedFolderPrivate {
              display: flex;
            }
          }
          .folderTitle {
            user-select: none;
            width: calc(100% - 58px);
            padding: 8px;
            padding-left: 0;
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .deleteBtn {
            width: auto;
            padding: 8px;
            svg {
              width: 10px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.colorsPalette.grey};
              }
            }
            &:hover {
              svg {
                path {
                  fill: ${(props) => props.theme.color.colorsPalette.red};
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        .openedItem {
          background-color: ${(props) => props.theme.color.main.white};
          /* border-bottom: 2px solid ${(props) => props.theme.color.main.green}; */
          cursor: default;
          .iconWrapper {
            svg {
              path {
                fill: ${(props) => props.theme.color.main.green};
              }
            }
            .openFolder {
              display: flex;
            }
            .closedFolder {
              display: none;
            }
            .openFolderPrivate {
              display: flex;
            }
            .closedFolderPrivate {
              display: none;
            }
          }
          .folderTitle {
            p {
              font-weight: bold;
            }
          }
          .deleteBtn {
            cursor: pointer;
          }
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .bottomWrapper {
      height: auto;
      .addBtn {
        display: ${(props) => (props.isCollapsed ? "none" : "flex")};
        padding: 16px;
        .buttonWithIcon {
          width: 100%;
          .buttonWithIconWrapper {
            width: 100%;
            justify-content: center;
            .titleBtn {
              width: auto;
              order: 2;
              margin-top: 4px;
            }
            .iconBtn {
              width: auto;
              margin-left: 0;
              margin-right: 8px;
            }
          }
        }
      }
      .collapseBtn {
        height: ${(props) => (props.isCollapsed ? "auto" : "100%")};
        position: ${(props) => (props.isCollapsed ? "absolute" : "unset")};
        bottom: 0;
        cursor: pointer;
        padding: 8px 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        svg {
          width: auto;
          height: 14px;
          transform: rotate(${(props) => (props.isCollapsed ? "180deg" : "0")});
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
`;

export class ListLibrairies extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      openAddLibrairie: false,
      folderToDelete: null,
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  onFolderDelete = () => {
    librariesService
      .deleteFolder(this.state.folderToDelete)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        if (this.props.refetchFolders) this.props.refetchFolders();
        this.setState({ folderToDelete: null });
      });
  };

  render() {
    return (
      <StyledListLibrairies isCollapsed={this.state.isCollapsed}>
        <NanoFlex className="listLibrairiesWrapper" flexDirection="column">
          <NanoFlex className="topWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="headerWrapper">
              <Type.h5>
                <b>{this.translation.global.librairies}</b>
              </Type.h5>
            </NanoFlex>
            {/* LIST */}
            <NanoFlex className="listContainer" flexDirection="column" justifyContent="flex-start">
              {(!this.props.folders || this.props.folders.length === 0) && (
                <NanoFlex className="listWrapper">
                  <Type.h5>{this.translation.global.noResults}</Type.h5>
                </NanoFlex>
              )}
              {this.props.folders && this.props.folders.length > 0 && (
                <NanoFlex className="listWrapper" flexDirection="column" justifyContent="flex-start">
                  {/* LIST ITEM*/}
                  {this.props.folders?.map((f, i) => (
                    <NanoFlex
                      key={`folder_` + f.id}
                      className={`itemList ${i === this.props.selectedIndex ? "openedItem" : ""}`}
                      onClick={(e) => {
                        if (elementMatchesAny(e.target, [".deleteBtn"])) return;
                        this.props.onSelectedIndexChange(i);
                      }}>
                      {!f.isPersonal && (
                        <NanoFlex className="iconWrapper">
                          <OpenFolder className="openFolder" />
                          <ClosedFolder className="closedFolder" />
                        </NanoFlex>
                      )}
                      {f.isPersonal && (
                        <NanoFlex className="iconWrapper">
                          <OpenFolderPrivate className="openFolderPrivate" />
                          <ClosedFolderPrivate className="closedFolderPrivate" />
                        </NanoFlex>
                      )}
                      <NanoFlex className="folderTitle" justifyContent="flex-start">
                        <Type.p title={f.description}>{f.description}</Type.p>
                      </NanoFlex>
                      <NanoFlex className="deleteBtn" onClick={() => this.setState({ folderToDelete: f.id })}>
                        <Delete />
                      </NanoFlex>
                    </NanoFlex>
                  ))}
                  {/* END LIST ITEM*/}
                  <Modal show={this.state.folderToDelete} content={<DeleteModal title={this.translation.librairies.deleteFolder} message={this.translation.librairies.messageDeleteFolder} cancelAction={() => this.setState({ folderToDelete: null })} nextAction={this.onFolderDelete} />} />
                </NanoFlex>
              )}
            </NanoFlex>
            {/* END LIST */}
          </NanoFlex>
          <NanoFlex className="bottomWrapper" flexDirection="column">
            <NanoFlex className="addBtn">
              <ButtonWithIcon label={this.translation.librairies.addLibrairies} src={AddFolder} clickAction={() => this.setState({ openAddLibrairie: true })} />
              <Modal
                show={this.state.openAddLibrairie}
                content={
                  <AddLibrairieModal
                    cancelAction={() => this.setState({ openAddLibrairie: false })}
                    nextAction={() => {
                      this.setState({ openAddLibrairie: false }, () => {
                        if (this.props.refetchFolders) this.props.refetchFolders();
                      });
                    }}
                  />
                }
              />
            </NanoFlex>
            <NanoFlex className="collapseBtn" onClick={this.toggleCollapse}>
              <ArrowLeft />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledListLibrairies>
    );
  }
}
