import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

const ButtonCTAStyled = styled(NanoFlex)`
  height: auto;

  .ctaWrapper {
    width: ${(props) => (props.smallCta ? "auto" : "100%")};
    height: auto;
    padding: 8px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    text-transform: uppercase;
    //border-radius: 50px;
    border: none;
    border: 0;
    background-color: ${(props) => (props.grey ? props.theme.color.colorsPalette.grey : props.yellow ? props.theme.color.colorsPalette.yellow : props.theme.color.main.green)};
    transition: ${(props) => props.theme.transition};
    text-transform: uppercase;
    color: ${(props) => (props.grey ? props.theme.color.main.font : props.yellow ? props.theme.color.main.font : props.theme.color.main.white)};
    font-size: 18px;
    font-weight: bold;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ButtonCTA = (props) => {
  return (
    <ButtonCTAStyled className="cta" grey={props.grey} yellow={props.yellow} smallCta={props.smallCta} disabled={props.disabled}>
      <button className="ctaWrapper" type={props.type} onClick={() => props.clickAction(props.id)}>
        {props.label}
      </button>
    </ButtonCTAStyled>
  );
};

// Set default props
ButtonCTA.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  clickAction: () => null,
};

export default ButtonCTA;

/*
  Doc
  import ButtonCTA from './Buttons/ButtonCTA.js';
  
  <ButtonCTA label="Contacte-nos"/>

  */
