import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import moment from "moment";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import { buildColumnsLinesPivot, buildPivotColumnsLines, buildPivotLines, buildPivotColumns, convertElementsToTree, getDateTime } from "../../../Global/tools.js";
import pivotService from "./../../../../services/PivotService";

// COMPONENTS
import Loading from "../../../Global/Elements/Loading/Loading.js";
import { Filters } from "../Dynamique/Filters.js";
import { RecapFilters } from "./../Grid/RecapFilters.js";
import { OptionsFilters } from "./OptionsFilters.js";
import { WordCloudBlock } from "./WordCloudBlock.js";
import { Pivot } from "./../Pivot/Pivot.js";

const StyledVerbatim = styled(NanoFlex)`
  min-width: 0;
  .verbatimContent {
    padding: 32px;
    min-width: 0;
    .verbatimWrapper {
      .pivot {
        height: ${(props) => (props.showWordCloud ? "calc(100vh - 470px)" : "calc(100vh - 206px)")};
        .pivotConfiguration {
          display: none;
        }
        .pivotTable {
          margin-top: 0;
          td {
            width: 60px;
            max-width: 200px;
          }
        }
      }
    }
    .noResults {
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      h3 {
        text-transform: uppercase;
        color: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
  }
`;

export class Verbatim extends BaseComponent {
  constructor(props, prevProps) {
    super(props, prevProps);

    let prevColumns = null
    let prevLines = null
    let prevFilters = null

    if (window.location.pathname === sessionStorage.getItem("lastPathname") || sessionStorage.getItem("lastPathname") === "/librairies" || sessionStorage.getItem("isRedirect") === "true") {
      prevColumns = JSON.parse(sessionStorage.getItem("jsonColumnsByReport"));
      prevLines = JSON.parse(sessionStorage.getItem("jsonLinesByReport"));
      prevFilters = JSON.parse(sessionStorage.getItem("filtersByReport"));
    }

    let d = moment().startOf("day");
    this.state = {
      filter: prevFilters ?? null,
      start: prevFilters !== null && prevFilters.startTime && new Date(prevFilters.startTime).getHours() !== 0 ? new Date(prevFilters.startTime) : d.set("hour", 0).toDate(),
      end: prevFilters !== null && prevFilters.endTime && new Date(prevFilters.endTime).getHours() !== 0 ? new Date(prevFilters.endTime) : d.set("hour", 24).toDate(),
      results: null,
      wordCloudResult: null,
      loading: false,
      loadingWordCloud: false,
      pivotLoading: false,
      pivotSettingsLoading: false,
      isConfigurationCollapsed: true,
      selectedTreeColumns: prevColumns !== null ? convertElementsToTree(prevColumns.elements) : [],
      selectedTreeLines: prevLines !== null ? convertElementsToTree(prevLines.elements) : [],
      hideCounts: false,
      diffusionsSelected: [],
      questionSelected: 1,
      top: prevFilters?.top ?? 250,
      wordsSelected: prevFilters?.wordsSelected ?? [],
      isVerbatim: true,
      selectedView: prevFilters?.selectedView ?? 1,
    };

    this._requestId = 1;
    this._clearWordCloudSelected = false;

    sessionStorage.setItem("lastPathname", window.location.pathname)
    sessionStorage.setItem("isRedirect", false)
  }

  componentDidMount() {
    super.componentDidMount();

    if (sessionStorage.getItem("jsonColumnsByReport") === null && sessionStorage.getItem("jsonLinesByReport") === null) {
      this.getPivotSettings();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter)) {
      this._clearWordCloudSelected = this.state.filter.isCleared;
    }
    if (
      (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter) && this.state.pivotSettingsLoading === false) ||
      prevState.hideCounts !== this.state.hideCounts ||
      JSON.stringify(prevState.diffusionsSelected) !== JSON.stringify(this.state.diffusionsSelected) ||
      prevState.questionSelected !== this.state.questionSelected ||
      prevState.top !== this.state.top ||
      JSON.stringify(prevState.wordsSelected) !== JSON.stringify(this.state.wordsSelected) ||
      JSON.stringify(prevState.selectedView) !== JSON.stringify(this.state.selectedView)
    ) {
      if (this.state.filter) {
        this.getAllComponentData();
      } else {
        this.setState({ results: null, wordCloudResult: null, wordsSelected: null });
      }
    }

    if (
      JSON.stringify(prevState.selectedTreeColumns) !== JSON.stringify(this.state.selectedTreeColumns) ||
      JSON.stringify(prevState.selectedTreeLines) !== JSON.stringify(this.state.selectedTreeLines) ||
      JSON.stringify(prevState.start) !== JSON.stringify(this.state.start) ||
      JSON.stringify(prevState.end) !== JSON.stringify(this.state.end)
    ) {
      /*Ignore 1sec timeout on first load!*/
      if (this.state.pivotSettingsLoading) {
        this.setState({ pivotSettingsLoading: false }, () => {
          if (this.state.filter) {
            this.getAllComponentData();
          }
        });
      } else {
        this._requestId++;
        var requestId = this._requestId;
        setTimeout(() => {
          if (requestId === this._requestId) {
            if (this.state.filter) {
              this.getAllComponentData();
            }
          }
        }, 1000);
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilter = () => {
    let selectedTreeColumnsTotal = this.state.selectedView === 2 ? this.state.selectedTreeColumns.filter((v) => v.name === "Plu" || v.name === "Deplu" || v.name === "Programme" || v.name === "Date" || v.name === "Note Quali TV" || v.name === "Emission") : this.state.selectedTreeColumns
    return {
      ...this.state.filter,
      ...{
        filterRecap: this.getTranslatedRecapFilters(this.state.filter.filterRecap),
        columnsLines: JSON.stringify(buildPivotColumnsLines(selectedTreeColumnsTotal, this.state.selectedTreeLines, "verbatim")),
        columns: JSON.stringify(buildPivotColumns(this.state.selectedTreeColumns)),
        lines: JSON.stringify(buildPivotLines(this.state.selectedTreeLines)),
        hideCounts: this.state.hideCounts,
        diffusionsSelected: this.state.diffusionsSelected,
        startTime: getDateTime(this.state.start),
        endTime: getDateTime(this.state.end),
        question: this.state.questionSelected,
        top: this.state.top,
        wordsSelected: this.state.wordsSelected,
        selectedView: this.state.selectedView
      },
    };
  };

  getAllComponentData = () => {
    this.getPivotEmissions();
    //this.getPivotSettings();
    this.getWordCloud();
  };

  getPivotSettings = () => {
    this.setState({ pivotSettingsLoading: true }, () => {
      pivotService
        .getPivotSettings("Verbatim")
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          let settings = buildColumnsLinesPivot(JSON.parse(data));
          this.setState({ selectedTreeColumns: settings.columns, selectedTreeLines: settings.lines });
        });
    });
  };

  getPivotEmissions = (firstLoad) => {
    this.setState({ loading: firstLoad ? true : false, pivotLoading: firstLoad ? false : true }, () => {
      pivotService
        .getPivotEmissions(this.getFilter())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState({ results: data, loading: false, pivotLoading: false });
          //sessionStorage.removeItem("filtersByReport");
          sessionStorage.removeItem("jsonLinesByReport");
          sessionStorage.removeItem("jsonColumnsByReport");
        });
    });
  };

  getWordCloud = () => {
    this.setState({ loadingWordCloud: true }, () => {
      pivotService
        .getWordCloud(this.getFilter())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState({ wordCloudResult: data, loadingWordCloud: false, wordsSelected: this._clearWordCloudSelected ? [] : this.state.wordsSelected });
          this._clearWordCloudSelected = false;
        });
    });
  };

  render() {
    let loading = this.state.loading;
    let pivotSettingsSelected = JSON.stringify(buildPivotColumnsLines(this.state.selectedTreeColumns, this.state.selectedTreeLines, "verbatim").categories);
    let showWordCloud = this.state.questionSelected !== null;
    return (
      <StyledVerbatim justifyContent="flex-start" showWordCloud={showWordCloud}>
        <Filters filtersSelected={this.state.filter} isVerbatim={!this.state.filter} onFilterChange={(f) => this.setState({ filter: f })} isActive={this.state.filter ? true : false} />
        <NanoFlex className="verbatimContent">
          {loading && (
            <NanoFlex className="verbatimWrapper">
              <Loading />
            </NanoFlex>
          )}
          {!this.state.filter && (
            <NanoFlex className="noResults">
              <Type.h3>
                <b>{this.translation.global.noResults}</b>
              </Type.h3>
            </NanoFlex>
          )}
          {!loading && this.state.filter && (
            <NanoFlex className="verbatimWrapper" flexDirection="column" justifyContent="flex-start">
              <RecapFilters selectedFilters={this.getFilter()} />
              <OptionsFilters
                selectedInterval={[moment(this.state.start).format("HH:mm"), moment(this.state.end).format("HH:mm")]}
                filtersSelected={this.getFilter()}
                pivotSettings={pivotSettingsSelected}
                hideCounts={this.state.hideCounts}
                hasData={this.state.results?.result?.rows.length > 1}
                onHideCountsChange={(h) => this.setState({ hideCounts: h })}
                diffusions={this.state.results?.diffusions}
                diffusionsSelected={this.state.diffusionsSelected}
                onDiffusionsSelectedChange={(d) => this.setState({ diffusionsSelected: d })}
                onQuestionSelectedChange={(q) => this.setState({ questionSelected: q })}
                questionSelected={this.state.questionSelected}
                onSelectedViewChange={(q) => this.setState({ selectedView: q })}
                selectedView={this.state.selectedView}
                top={this.state.top}
                onTopChange={(t) => this.setState({ top: t })}
              />
              {showWordCloud && (
                <WordCloudBlock loading={this.state.loadingWordCloud} filtersSelected={this.getFilter()} data={this.state.wordCloudResult} wordsSelected={this.state.wordsSelected} questionSelected={this.state.questionSelected} onWordsSelectedChange={(w) => this.setState({ wordsSelected: w })} />
              )}
              <Pivot
                loading={this.state.pivotLoading || this.state.pivotSettingsLoading}
                selectedTreeColumns={this.state.selectedTreeColumns}
                selectedTreeLines={this.state.selectedTreeLines}
                onSelectedTreeChange={(columns, lines) => this.setState({ selectedTreeColumns: columns, selectedTreeLines: lines })}
                data={this.state.results}
                isVerbatim={this.state.isVerbatim}
              />
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledVerbatim>
    );
  }
}
