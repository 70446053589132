import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../BaseComponent.js";
import NanoFlex from "./../../Global/NanoFlex.js";
import SortableTree from "./../../Global/Elements/Sortable/SortableTree.js";

const StyledSortable = styled(NanoFlex)``;

export class Sortable extends BaseComponent {


  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledSortable>
        <SortableTree />
      </StyledSortable>
    );
  }
}
