/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../../../Global/NanoFlex.js";
import Type from "../../../../../Global/Typography.js";

// COMPONENTS
import ButtonSimple from "../../../../../Global/Elements/Buttons/ButtonSimple.js";

//Imgs
import LogoFranceTv from "../../../../../Global/images/francetv-black.svg";
import { ReactComponent as Trophy } from "../../../../../Global/icons/trophy.svg";

const StyledHeaderListBlock = styled(NanoFlex)`
  height: auto;
  .headerListBlock {
    .headerWrapper {
      height: auto;
      .logoWrapper {
        width: auto;
        max-width: 160px;
        height: auto;
        max-height: 160px;
        padding-right: 16px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .headerTitle {
        padding: 8px 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        border-left: 4px solid ${(props) => props.theme.color.colorsPalette.yellow};
        h5 {
          text-transform: uppercase;
          margin-bottom: 8px;
        }
        .highlightTitle {
          margin-bottom: 4px;

          svg {
            margin-left: 4px;
            width: auto;
            height: 14px;
            margin-bottom: 2px;
            path {
              fill: ${(props) => props.theme.color.colorsPalette.yellow};
            }
          }

          h5 {
            text-transform: uppercase;
            margin-bottom: 0;
          }
        }
        p {
          margin-bottom: 8px;
          text-transform: uppercase;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
      .selectBtnsWrapper {
        margin-left: 8px;
        width: auto;
        min-width: 288px;
        height: auto;
        padding: 8px;
        background-color: ${(props) => props.theme.color.main.white};
        border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        .headerBlock {
          margin-bottom: 8px;
          p {
            text-transform: uppercase;
            b {
              color: ${(props) => props.theme.color.main.font};
            }
          }
        }

        .columnsWrapper {
          .columnBtns {
            margin-right: 4px;
            .selectBtn {
              margin-bottom: 4px;
              &:last-child {
                margin-bottom: 0;
              }
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export class HeaderListBlock extends BaseComponent {
  render() {
    return (
      <StyledHeaderListBlock className="headerList">
        <NanoFlex className="headerListBlock" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <NanoFlex className="headerWrapper" alignItems="stretch">
            <NanoFlex className="logoWrapper">
              <img src={LogoFranceTv} alt="France TV Logo" />
            </NanoFlex>
            <NanoFlex className="headerTitle" flexDirection="column" alignItems="flex-start">
              <Type.h5>
                <b>{this.translation.palmares.resultsQualiTV}</b>
              </Type.h5>
              <NanoFlex className="highlightTitle" justifyContent="flex-start">
                <Type.h5>
                  <b>{this.translation.global.palmares}</b>
                </Type.h5>
                <Trophy />
              </NanoFlex>
              <Type.p>
                <b>{this.translation.palmares.ofPrograms}</b>
              </Type.p>
              <Type.p>{this.translation.palmares.evalues}</Type.p>
            </NanoFlex>

            {!this._isExport && (
              <NanoFlex className="selectBtnsWrapper" flexDirection="column">
                <NanoFlex className="headerBlock">
                  <Type.p>
                    <b>{this.translation.palmares.settings}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="columnsWrapper">
                  <NanoFlex className="columnBtns" flexDirection="column">
                    <NanoFlex className="selectBtn" onClick={() => this.props.onReportChange(6)}>
                      <ButtonSimple disabled={!this.props.hasData} selected={this.props.report === 6} label={this.translation.global.noteQualiTV} />
                    </NanoFlex>
                    <NanoFlex className="selectBtn" onClick={() => this.props.onReportChange(7)}>
                      <ButtonSimple disabled={!this.props.hasData} selected={this.props.report === 7} label={this.translation.palmares.origin} />
                    </NanoFlex>
                  </NanoFlex>
                  <NanoFlex className="columnBtns" flexDirection="column">
                  <NanoFlex className="selectBtn" onClick={() => this.props.onReportChange(9)}>
                      <ButtonSimple disabled={!this.props.hasData} selected={this.props.report === 9} label={this.translation.global.impact} />
                    </NanoFlex>
                    <NanoFlex className="selectBtn" onClick={() => this.props.onReportChange(8)}>
                      <ButtonSimple disabled={!this.props.hasData} selected={this.props.report === 8} label={this.translation.palmares.education} />
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
            )}
          </NanoFlex>
        </NanoFlex>
      </StyledHeaderListBlock>
    );
  }
}
