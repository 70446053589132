import React from "react";
import BaseComponent from "../../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../../Global/NanoFlex.js";
import Type from "./../../../../Global/Typography.js";
import administrationService from "../../../../../services/AdministrationService.js";
import * as _ from "lodash";

//COMPONENTS
import Input from "../../../../Global/Elements/Forms/Input.js";
import ButtonIcon from "../../../../Global/Elements/Buttons/ButtonIcon.js";
import { SearchBar } from "../../../../Global/Elements/Forms/SearchBar.js";
import { OptionsFilters } from "./OptionsFilters.js";
import { WordCloudDnD } from "./../../../../Global/Elements/WordCloud/WordCloudDnD.js";
//Imgs
import Plus from "../../../../Global/icons/plus.svg";

const StyledGroupsWords = styled(NanoFlex)`
  padding: 32px;
  .groupsWordsWrapper {
    height: auto;
    .topOptions {
      height: auto;
      .asideOptions {
        margin-right: 16px;
        .inputAddWordWrapper {
          height: auto;
          .inputAddWordContainer {
            .inputContainer {
              min-width: calc(240px - 35px);
            }
          }
          .addBtn {
            width: auto;

            .buttonWithIcon {
              .buttonWithIconWrapper {
                height: 35px;
                width: 35px;
                border: 1px solid ${(props) => props.theme.color.main.font};
                border-left: 0;
              }
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }

      .searchOption {
        width: auto;
        height: auto;
      }
    }
    .listGroupsContainer {
      margin-top: 16px;
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      padding: 16px;
      overflow: auto;
      height: calc(100vh - 238px);
      .listGroupsWrapper {
        overflow: auto;
        padding: 16px;
        background-color: ${(props) => props.theme.color.main.white};
      }
    }
  }
`;

export class GroupsWords extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      newWord: "",
      items: [],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getCompleteGroupWords();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getNextId = () => {
    return (_.maxBy(this.state.items ?? [], (i) => i.idGroup)?.idGroup ?? 0) + 1;
  };

  getCompleteGroupWords = () => {
    administrationService
      .getCompleteGroupWords()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ items: data });
      });
  };

  saveGroupWord = (model) => {
    administrationService
      .saveGroupWord(model)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {});
  };

  onDragEnd = (result) => {
    let immutableItems = JSON.parse(JSON.stringify(this.state.items));

    /*Remove Item*/
    let removeGroup = immutableItems[Number(result.source.droppableId)];
    let [removed] = removeGroup.words.splice(result.source.index, 1);
    /*Add Item */
    if (result.destination) {
      //Is Valid
      let addGroup = immutableItems[Number(result.destination.droppableId)];
      removed.idGroup = addGroup.idGroup;
      addGroup.words.splice(result.destination.index, 0, removed);
    }

    //Remove group if empty at the end
    if (removeGroup.words.length === 0) {
      immutableItems.splice(Number(result.source.droppableId), 1);
    }

    //Validate DropOut after Remove Group
    if (!result.destination) {
      //Is Drop Out
      //Get Next Id by Max+1
      let nextGroup = this.getNextId();
      removed.idGroup = nextGroup;
      immutableItems.splice(0, 0, {
        idGroup: nextGroup,
        words: [removed],
      });
    }

    /*Save*/
    this.saveGroupWord(removed);

    /*Set State*/
    this.setState({ items: immutableItems });
  };

  createWord = () => {
    let newWord = this.state.newWord;
    this.setState({ newWord: "" }, () => {
      administrationService
        .createGroupWord({
          idGroup: this.getNextId(),
          word: newWord,
        })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.getCompleteGroupWords();
        });
    });
  };

  deleteWord = (id) => {
    administrationService
      .deleteGroupWord(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.getCompleteGroupWords();
      });
  };

  render() {
    return (
      <StyledGroupsWords justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="groupsWordsWrapper" flexDirection="column">
          <OptionsFilters />
          <NanoFlex className="topOptions" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="asideOptions" alignItems="flex-start">
              <NanoFlex className="inputAddWordWrapper" alignItems="flex-end">
                <NanoFlex className="inputAddWordContainer">
                  <Input title={this.translation.admin.wordcloud.addWordGroup} placeholder={this.translation.admin.wordcloud.addWordGroup} defaultValue={this.state.newWord} onChangeAction={(v) => this.setState({ newWord: v })} />
                </NanoFlex>
                <NanoFlex className="addBtn" alignItems="flex-end">
                  <ButtonIcon disabled={this.state.newWord.trim() === ""} src={Plus} clickAction={this.createWord} />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="asideOptions searchOption" alignItems="flex-end">
              <SearchBar />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="listGroupsContainer">
            <NanoFlex className="listGroupsWrapper" justifyContent="flex-start" alignItems="flex-start">
              <WordCloudDnD items={this.state.items} onDragEnd={this.onDragEnd} onDelete={this.deleteWord} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledGroupsWords>
    );
  }
}
