import { BaseService } from "./BaseService";

export class FiltersService extends BaseService {
  getDateRange() {
    return this.request("api/Filters/GetDateRange", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getDateRangeDefinitifs() {
    return this.request("api/Filters/GetDateRangeDefinitifs", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getDateRangeIntermediaires() {
    return this.request("api/Filters/GetDateRangeIntermediaires", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getDateRangeTvReplay() {
    return this.request("api/Filters/GetDateRangeTvReplay", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getDayparts() {
    return this.request("api/Filters/GetDayparts", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getChannels() {
    return this.request("api/Filters/GetChannels", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getEmissions = (filter) => {
    return this.request("api/Filters/GetEmissions", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(filter),
    });
  };

  getGenres() {
    return this.request("api/Filters/GetGenres", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getPlatforms() {
    return this.request("api/Filters/GetPlatforms", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getAges() {
    return this.request("api/Filters/GetAges", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getGenders() {
    return this.request("api/Filters/GetGenders", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getCSP() {
    return this.request("api/Filters/GetCSP", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  saveFilters(model) {
    return this.request("api/Filters/SaveFilters", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }
}

const filtersService = new FiltersService();

export default filtersService;
