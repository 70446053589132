/* eslint-disable react/jsx-pascal-case */
import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Type from "../../Typography.js";
import NanoFlex from "../../NanoFlex.js";

//Imgs
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as Less } from "../../icons/less.svg";

const StyledInputIcon = styled(NanoFlex)`
  height: auto;
  position: relative;
  pointer-events: ${(props) => (props.disabled ? "none" : "unset")};

  .title {
    color: ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    margin-bottom: 4px;
  }
  .inputWrapper {
    font-family: ${(props) => props.theme.font};
    .iconWrapper {
      background-color: ${(props) => props.theme.color.main.font};
      width: auto;
      height: 35px;
      padding: 0 6px;
      svg {
        margin-top: 2px;
        margin-left: 2px;
        width: 12px;
        height: auto;
        path {
          fill: ${(props) => props.theme.color.main.white};
        }
      }
      .moreIcon {
        display: ${(props) => (props.less ? "none" : "flex")};
      }
      .lessIcon {
        display: ${(props) => (props.less ? "flex" : "none")};
      }
    }
  }
  input {
    border: 1px solid ${(props) => (props.error ? props.theme.color.colorsPalette.red : props.theme.color.main.font)};
    padding: 8px;
    width: 100%;
    font-size: 14px;
    color: ${(props) => props.theme.color.main.font};
    font-family: ${(props) => props.theme.font};
    background-color: ${(props) => (props.error ? "#FFFDDC" : props.disabled ? props.theme.color.colorsPalette.lighterGrey : props.theme.color.main.white)};
    text-align: center;
    &::placeholder {
      font-family: ${(props) => props.theme.font};
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }

    &:focus {
      border: 1px solid ${(props) => props.theme.color.main.font};
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

class InputIcon extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultValue !== prevProps.defaultValue || this.props.forceSelection) {
      this.inputRef.current.value = this.props.defaultValue;
    }
  }

  validateValue = (value) => {
    if (this.props.maxLength && value.toString().length <= this.props.maxLength) return value;
    else {
      this.inputRef.current.value = this.props.defaultValue;
      return this.props.defaultValue;
    }
  };

  render() {
    return (
      <StyledInputIcon className="inputContainer" error={this.props.error} flexDirection="column" justifyContent="flex-start" alignItems="flex-start" disabled={this.props.disabled} less={this.props.less}>
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}

        <NanoFlex className="inputWrapper">
          <NanoFlex className="iconWrapper">
            <More className="moreIcon" />
            <Less className="lessIcon" />
          </NanoFlex>
          <input
            ref={this.inputRef}
            type="number"
            placeholder={this.props.placeholder}
            {...(!this.props.forceValue ? { defaultValue: this.props.defaultValue } : null)}
            {...(this.props.forceValue ? { value: this.props.defaultValue } : null)}
            onChange={(e) => {
              if (this.props.onChangeAction) {
                let val = e.target.value;
                this.props.onChangeAction(val ? this.validateValue(Number(val)) : null);
              }
            }}
            onBlur={(e) => {
              if (this.props.onBlurAction) {
                let val = e.target.value;
                this.props.onBlurAction(val ? this.validateValue(Number(val)) : null);
              }
            }}
            onKeyPress={(e) => {
              if (this.props.onKeyPress) {
                this.props.onKeyPress(e);
              }
            }}
            onKeyDown={(e) => {
              if (e.returnValue !== false) {
                if (this.props.onKeyDown) {
                  return this.props.onKeyDown(e);
                }
              }
            }}
            name={this.props.name ?? Math.random().toString(36).substring(7)}
            {...(!this.props.enableAutoComplete ? { autoComplete: /chrome/.test(navigator.userAgent.toLowerCase()) ? "new-password" : "off" } : null)}
          />
        </NanoFlex>

        {this.props.menssage && <Type.h6 className="menssage">{this.props.menssage}</Type.h6>}
      </StyledInputIcon>
    );
  }
}

// Set default props
InputIcon.defaultProps = {
  id: 0,
  type: "number",
  title: null,
  defaultValue: "",
  placeholder: "",
  menssage: null,
};

export default InputIcon;
