import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled, { css } from "styled-components";
import Settings from "../../Settings.json";
import moment from "moment";

import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

import { ReactComponent as ArrowRight } from "../../icons/arrowRight.svg";
import { ReactComponent as ArrowLeft } from "../../icons/arrowLeft.svg";

const multiple = 8;
const minTouchBlock = multiple * 4; // 24
const weekendIndexArrays = {
  saturday: [5, 12, 19, 26, 33, 40],
  sunday: [6, 13, 20, 27, 34, 41],
};
const allWeekendIndex = weekendIndexArrays.saturday.concat(weekendIndexArrays.sunday);
const dayFormat = Settings.moment.date;
const justDayNumber = Settings.moment.day;
const monthLabelFormat = Settings.moment.monthLabel;
const yearFormat = Settings.moment.year;
const comparisonMonthYearFormat = Settings.moment.monthYear;
const now = moment();
const currentDay = now.format(dayFormat);

const StyledBlockBox = styled.div`
  width: ${minTouchBlock + "px"};
  height: ${minTouchBlock + "px"};
  cursor: pointer;

  background-color: ${(props) => {
    if (!props.content) return props.theme.color.main.white;

    if (props.selected || props.inBetweenSelection) return props.theme.color.gradientGreen.green02;

    if (props.alternative) {
      return props.theme.color.colorsPalette.lightGrey;
    } else {
      return props.theme.color.main.white;
    }
  }};

  opacity: ${(props) => (props.inBetweenSelection ? 0.6 : 1)};

  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:hover {
    opacity: 0.7;
  }
`;

const StyledMultipleDatePicker = styled(NanoFlex)`
  width: 280px;
  background-color: ${(props) => props.theme.color.main.white};
  padding: ${multiple + "px"};
  /* min-height: 272px; */
  align-items: stretch;
  align-content: stretch;
  user-select: none;
  .datePickerContainer {
    .datePicker {
      align-items: flex-start;
      height: auto;
    }
  }
  .topWrapper {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Likely future */
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    height: 32px;
    .blockBox {
      width: ${multiple * 4 + "px"};
      height: 32px;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      p {
        line-height: initial;
        svg {
          height: 10px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.green};
          }
        }
      }
    }
  }
  .monthWrapper {
    width: 60%;
    height: 32px;
  }
  .date-titles {
    height: 32px;
  }
  .yearWrapper {
    width: 40%;
    height: 32px;
  }

  .date-table {
    .dateTableWrapper {
      height: auto;
      justify-content: flex-start;
    }
  }

  .searchListBlock {
    height: auto;
    justify-content: flex-start;
  }

  .dateTableWrapper {
    .week-table {
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      border-right: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
      margin-right: 4px;
      .weekHeader {
        width: 32px;
        height: 32px;
        p {
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }
      .weekNumberList {
        .weekNumber {
          width: 32px;
          height: 32px;
          p {
            color: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }

  /* @media only screen and (max-width: 800px) {
    width: 276px;
  } */
`;

const BlockBox = (props) => (
  <StyledBlockBox className="blockBox" onClick={() => (props.blockBoxAction ? props.blockBoxAction(props.data.display) : null)} alternative={props.alternative} content={props.data.display} selected={props.selected} disabled={props.data.disabled}>
    <NanoFlex>
      {props.bold ? (
        <Type.p white={props.selected}>
          <b>{props.data.display}</b>
        </Type.p>
      ) : (
        <Type.p white={props.selected}>{props.data.display}</Type.p>
      )}
    </NanoFlex>
  </StyledBlockBox>
);

// Set default props
BlockBox.defaultProps = {
  data: null,
  bold: false,
};
const BlockBoxList = (props) =>
  !props.data
    ? null
    : props.data.map((data, dataIndex) => {
        return <BlockBox key={dataIndex} data={data} alternative={props.alternativeList ? props.alternativeList.includes(dataIndex) : false} bold={props.bold} selected={props.selectedDates && props.selectedDates.includes(data.date)} blockBoxAction={props.blockBoxListAction} />;
      });

class MultipleDatePicker extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: false,

      mainDate: moment(this.props.selectedDates && this.props.selectedDates.length > 0 ? this.props.selectedDates[this.props.selectedDates.length - 1] : currentDay, dayFormat)
        .startOf("month")
        .format(dayFormat),

      selectedDates: this.props.selectedDates ?? [],

      minDate: this.props.minDate ? this.props.minDate : null,
      maxDate: this.props.maxDate ? this.props.maxDate : null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.validateMinMaxDate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.mainDate !== this.state.mainDate ||
      JSON.stringify(prevState.selectedDates) !== JSON.stringify(this.state.selectedDates) ||
      JSON.stringify(prevProps.selectedDates) !== JSON.stringify(this.props.selectedDates) ||
      prevState.minDate !== this.state.minDate ||
      prevState.maxDate !== this.state.maxDate
    ) {
      this.validateMinMaxDate();
    }

    if (prevProps.minDate !== this.props.minDate) {
      this.setState({ minDate: this.props.minDate });
    }

    if (prevProps.maxDate !== this.props.maxDate) {
      this.setState({ maxDate: this.props.maxDate });
    }

    if (JSON.stringify(prevProps.selectedDates) !== JSON.stringify(this.props.selectedDates)) {
      this.setState({ selectedDates: this.props.selectedDates ?? [] });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  goOneMonthBack = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).subtract(1, "months").format(dayFormat) });
  };

  goOneMonthForward = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).add(1, "months").format(dayFormat) });
  };

  goOneYearBack = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).subtract(1, "years").format(dayFormat) });
  };

  goOneYearForward = () => {
    this.setState({ mainDate: moment(this.state.mainDate, dayFormat).add(1, "years").format(dayFormat) });
  };

  weekdayPosition = (date) => {
    // ISO Day of Week is iqual to the index of this array =>
    // [null, "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

    const iso = moment(date, dayFormat).isoWeekday() - 1; //.isoWeekday();
    if (iso === 7) {
      return 0;
    } else {
      return iso;
    }
  };

  addBlankBlock = (date, list) => {
    const blankList = new Array(this.weekdayPosition(date)).fill({ display: "", disabled: false });

    return [...blankList, ...list];
  };

  createMainArray = (date) => {
    var momentDate = moment(date, dayFormat);
    const daysInMonth = momentDate.daysInMonth();
    const emptyDataList = new Array(daysInMonth).fill("");

    var results = emptyDataList.map((v, i) => {
      var day = (v = i + 1);
      var newDate = momentDate.set("date", day);
      return {
        date: newDate.format(dayFormat),
        display: day,
        disabled: (this.state.minDate && moment(newDate).startOf("day") < moment(this.state.minDate).startOf("day")) || (this.state.maxDate && moment(newDate).startOf("day") > moment(this.state.maxDate).startOf("day")),
      };
    });
    return results;
  };

  selectDays = (day, date) => {
    if (!day) return;

    const outputDate = moment(date, dayFormat)
      .add(day - 1, "days")
      .format(dayFormat);

    this.setState(
      {
        selectedDates: this.state.selectedDates.includes(outputDate)
          ? this.state.selectedDates.filter((date) => {
              return date !== outputDate;
            })
          : [...this.state.selectedDates, ...[outputDate]],
      },
      () => {
        this.props.onDateChange(this.state.selectedDates);
      }
    );
  };

  updateMonthLabel = (date) => {
    return moment(date, dayFormat).locale(this.getMomentLanguage()).format(monthLabelFormat);
  };

  updateYearLabel = (date) => {
    return moment(date, dayFormat).format(yearFormat);
  };

  isSameMonthAndYear = (mainDate, date) => {
    return moment(date, dayFormat).format(comparisonMonthYearFormat) === moment(mainDate, dayFormat).format(comparisonMonthYearFormat);
  };

  getJustDayNumber = (date) => {
    return parseInt(moment(date, dayFormat).format(justDayNumber));
  };

  validateMinMaxDate = () => {
    let hasChanged = false;
    let { mainDate } = { ...this.state };

    if (this.state.minDate && moment(mainDate, dayFormat) < moment(this.state.minDate).set("date", 1)) {
      mainDate = moment(this.state.minDate).set("date", 1).format(dayFormat);
      hasChanged = true;
    }

    if (this.state.maxDate && moment(mainDate, dayFormat) > moment(this.state.maxDate).set("date", 1)) {
      mainDate = moment(this.state.maxDate).set("date", 1).format(dayFormat);
      hasChanged = true;
    }

    if (hasChanged) {
      this.setState({ mainDate });
    }
  };

  isPrevMonthDisabled = () => {
    if (this.state.minDate) {
      let prevMonthDate = moment(this.state.mainDate, dayFormat).set("date", 1);
      return moment(prevMonthDate).startOf("day") < moment(this.state.minDate).startOf("day");
    }
    return false;
  };

  isNextMonthDisabled = () => {
    if (this.state.maxDate) {
      let nextMonthDate = moment(this.state.mainDate, dayFormat).set("date", 0).add("month", 1);
      return moment(nextMonthDate).startOf("day") > moment(this.state.maxDate).startOf("day");
    }
    return false;
  };

  isPrevYearDisabled = () => {
    if (this.state.minDate) {
      let prevYearDate = moment(this.state.mainDate, dayFormat).set("date", 31).set("month", 11).add("year", -1);
      return moment(prevYearDate).startOf("day") < moment(this.state.minDate).startOf("day");
    }
    return false;
  };

  isNextYearDisabled = () => {
    if (this.state.maxDate) {
      let nextYearDate = moment(this.state.mainDate, dayFormat).set("date", 0).set("month", 0).add("year", 1);
      return moment(nextYearDate).startOf("day") > moment(this.state.maxDate).startOf("day");
    }
    return false;
  };

  getWeekdays = () => {
    moment.locale(this.getMomentLanguage());
    let weekdaysShort = moment.weekdaysShort();
    weekdaysShort.push(weekdaysShort.splice(0, 1)[0]);
    return weekdaysShort;
  };

  getWeekNums = () => {
    let date = moment(this.state.mainDate, dayFormat);
    const firstDayOfMonth = date.set("date", 1);
    const numOfDays = firstDayOfMonth.daysInMonth();
    let weeks = new Set();
    for (let i = 0; i < numOfDays; i++) {
      const currentDay = moment(firstDayOfMonth, "YYYY-MM-DD").add(i, "days");
      weeks.add(currentDay.isoWeek());
    }
    return Array.from(weeks);
  };

  render() {
    const { mainDate, selectedDates } = this.state;

    return (
      <StyledMultipleDatePicker className="datePickerContainer">
        <NanoFlex className="datePickerContainer" flexDirection="column">
          <NanoFlex className="datePicker" justifyContent="space-between">
            <NanoFlex className="topWrapper monthWrapper">
              <BlockBox data={{ display: <ArrowLeft />, disabled: this.isPrevMonthDisabled() }} blockBoxAction={this.goOneMonthBack} />
              <NanoFlex className="date-titles">
                <Type.p>{this.updateMonthLabel(mainDate)}</Type.p>
              </NanoFlex>
              <BlockBox data={{ display: <ArrowRight />, disabled: this.isNextMonthDisabled() }} blockBoxAction={this.goOneMonthForward} />
            </NanoFlex>

            <NanoFlex className="topWrapper yearWrapper">
              <BlockBox data={{ display: <ArrowLeft />, disabled: this.isPrevYearDisabled() }} blockBoxAction={this.goOneYearBack} />
              <NanoFlex className="date-titles">
                <Type.p>{this.updateYearLabel(mainDate)}</Type.p>
              </NanoFlex>
              <BlockBox data={{ display: <ArrowRight />, disabled: this.isNextYearDisabled() }} blockBoxAction={this.goOneYearForward} />
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="dateTableWrapper" alignItems="flex-start">
            <NanoFlex className="week-table" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
              <NanoFlex className="weekHeader">
                <Type.p></Type.p>
              </NanoFlex>
              <NanoFlex className="weekNumberList" flexDirection="column">
                {this.getWeekNums().map((v, i) => {
                  return (
                    <NanoFlex className="weekNumber" key={"WK_" + i}>
                      <Type.p>{v}</Type.p>
                    </NanoFlex>
                  );
                })}
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="date-table" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
              <NanoFlex className="dateTableWrapper" justifyContent="flex-start">
                <BlockBoxList
                  data={this.getWeekdays().map((v) => {
                    return { display: v };
                  })}
                  directValue
                />
              </NanoFlex>

              <NanoFlex flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                <BlockBoxList data={this.addBlankBlock(mainDate, this.createMainArray(mainDate))} directValue selectedDates={selectedDates} alternativeList={allWeekendIndex} blockBoxListAction={(clickedDay) => this.selectDays(clickedDay, mainDate)} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledMultipleDatePicker>
    );
  }
}

export default MultipleDatePicker;
