import React from "react";
import BaseComponent from "../../../BaseComponent.js";

import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import pivotService from "./../../../../services/PivotService";
import { downloadBlob } from "./../../../Global/tools.js";

// COMPONENTS
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import Modal from "../../../Global/Elements/Modals/Modal.js";
import ButtonCTA from "../../../Global/Elements/Buttons/ButtonCTA.js";
import ToggleButton from "../../../Global/Elements/Buttons/ToggleButton.js";
import { SaveReportModal } from "../../../Global/Elements/Modals/SaveReportModal.js";
import { DiffusionModal } from "../../../Global/Elements/Modals/DiffusionModal.js";

//Imgs
import Excel from "../../../Global/icons/excel.svg";
import Pdf from "../../../Global/icons/pdf.svg";
import Save from "../../../Global/icons/save.svg";

const StyledOptionsFilters = styled(NanoFlex)`
  height: auto;
  padding: 16px 0;
  user-select: none;
  .infoWrapper {
    margin-bottom: 8px;
    .asideWrapper {
      .optionBtnWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .elementWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .formWrapper {
        max-width: 232px;
      }
      .cta {
        width: auto;
        .ctaWrapper {
          font-size: 14px;
          border-radius: 60px;
          padding: 8px 16px;
          letter-spacing: 0.5px;
        }
      }
      .asideBlock {
        width: auto;
        margin-bottom: 8px;
        .titleWrapper {
          width: auto;
          margin-right: 8px;
        }
        .infoContainer {
          width: auto;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export class OptionsFilters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSaveReport: false,
      hideCounts: this.props.hideCounts,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.hideCounts !== this.props.hideCounts) {
      this.setState({ hideCounts: this.props.hideCounts });
    }

    if (prevState.hideCounts !== this.state.hideCounts && this.props.onHideCountsChange) {
      this.props.onHideCountsChange(this.state.hideCounts);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onExcelExport = () => {
    pivotService
      .getPivotExcel({
        ...this.props.filtersSelected,
        ...{ columnsLines: this.props.pivotSettings },
      })
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((blob) => {
        if (blob) {
          downloadBlob(blob, `Report-dynamique.xlsx`);
        }
      });
  };

  render() {
    let startTime = this.props.selectedInterval[0];
    let endTime = this.props.selectedInterval[1] === "00:00" ? "24:00" : this.props.selectedInterval[1];

    return (
      <StyledOptionsFilters flexDirection="column">
        <Modal show={this.state.openSaveReport} content={<SaveReportModal reportType={7} filtersSelected={this.props.filtersSelected} cancelAction={() => this.setState({ openSaveReport: false })} nextAction={() => this.setState({ openSaveReport: false })} />} />

        <NanoFlex className="infoWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
            <NanoFlex className="asideBlock" justifyContent="flex-end">
              <NanoFlex className="optionBtnWrapper">
                <ToggleButton active={this.state.hideCounts} clickAction={() => this.setState({ hideCounts: !this.state.hideCounts })} disabled={!this.props.hasData} title={this.translation.global.hideEffectives} />
              </NanoFlex>
              {/* <NanoFlex className="optionBtnWrapper">
                <ButtonIcon src={Excel} clickAction={this.onExcelExport} disabled={!this.props.hasData} />
              </NanoFlex> */}
              {/* <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Pdf} />
            </NanoFlex> */}
              <NanoFlex className="optionBtnWrapper">
                <ButtonIcon src={Save} clickAction={() => this.setState({ openSaveReport: true })} />
              </NanoFlex>
            </NanoFlex>

            <NanoFlex className="asideBlock" justifyContent="flex-end">
              <NanoFlex className="titleWrapper">
                <Type.h6>
                  <b>{this.translation.global.trancheHoraire}:</b>
                </Type.h6>
              </NanoFlex>
              <NanoFlex className="infoContainer">
                <Type.p>
                  {startTime}h - {endTime}h
                </Type.p>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledOptionsFilters>
    );
  }
}
