import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledButtonSimple = styled(NanoFlex)`
  .buttonSimpleWrapper {
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    padding: 8px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    background-color: ${(props) => (props.selected ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
    .titleBtn {
      width: max-content;
      p {
        text-align: center;
        color: ${(props) => (props.selected ? props.theme.color.main.white : props.theme.color.main.font)};
        /* font-weight: ${(props) => (props.selected ? "bold" : "unset")}; */
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ButtonSimple = (props) => {
  return (
    <StyledButtonSimple className="buttonSimple" selected={props.selected} disabled={props.disabled}>
      <NanoFlex className="buttonSimpleWrapper" onClick={() => props.clickAction(props.id)}>
        <NanoFlex className="titleBtn">
          <Type.p>{props.label}</Type.p>
        </NanoFlex>
      </NanoFlex>
    </StyledButtonSimple>
  );
};

// Set default props
ButtonSimple.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  clickAction: () => null,
};

export default ButtonSimple;

/*
  Doc
  import ButtonCTA from './Buttons/ButtonCTA.js';
  
  <ButtonCTA label="Contacte-nos"/>

  */
