import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

const StyledBaseFilterSection = styled(NanoFlex)`
  height: auto;
  padding: 0 16px 16px 16px;
  .baseFilterSectionWrapper {
    .titleSection {
      height: auto;
      border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding-bottom: 8px;
      margin-bottom: 8px;
      p {
        text-transform: uppercase;
      }
    }
    .formContainer {
      height: auto;
      .formWrapper {
        margin-bottom: 12px;
        .titleForm {
          margin-bottom: 4px;
        }
        .disabledMessage {
          margin-top: 4px;
          h6 {
            color: ${(props) => props.theme.color.colorsPalette.red};
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      .disabledForm {
        .titleForm {
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }

      .formAside {
        margin-bottom: 12px;
        .titleForm {
          margin-bottom: 4px;
        }
        .formsContainers {
          .formWrapper {
            margin-bottom: 0px;
            margin-right: 8px;
            .inputContainer {
              min-width: auto;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  &:first-child {
    padding-top: 16px;
  }
  &:last-child {
    padding-bottom: 0px;
  }
`;

export class BaseFilterSection extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledBaseFilterSection className="baseFilterSection">
        <NanoFlex className="baseFilterSectionWrapper" flexDirection="column" justifyContent="flex-start">
          <NanoFlex className="titleSection">
            <Type.p>{this.props.sectionTitle}</Type.p>
          </NanoFlex>
          <NanoFlex className="formContainer" flexDirection="column">
            {this.props.children}
          </NanoFlex>
        </NanoFlex>
      </StyledBaseFilterSection>
    );
  }
}
