import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import Settings from "../../Settings.json";
import moment from "moment";
import { Popper, Reference, Manager } from "react-popper";

import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import DatePicker from "./DatePicker.js";
import InputDate from "./InputDate.js";

import { ReactComponent as CalendarIcon } from "../../icons/calendar.svg";

const checkInvalidDate = (d) => (d !== "Invalid date" ? d : null);

const StyledCalendar = styled(NanoFlex)`
  user-select: none;
  .inputDateForm {
    margin-right: 8px;
    p {
      margin-bottom: 4px;
    }
  }

  .icon {
    padding-top: 12px;
    width: 20px;
    cursor: pointer;
    svg path {
      fill: ${(props) => (props.isOpen ? props.theme.color.main.green : props.theme.color.main.font)};
    }
    &:hover {
      opacity: 0.7;
    }
  }
  .dropdown-content {
    position: absolute;
    border: 1px solid ${(props) => props.theme.color.main.font};
    left: 45%;
    margin: 20px 0 0 0;
  }

  .popUpContainer {
    z-index: 9;
    background-color: ${(props) => props.theme.color.main.white};
    .datePickerWrapper {
      box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    }
  }
  .iconWrapper {
    width: auto;
  }
`;

class Calendar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
      isOpenDropdown: false,
    };

    this.calendarRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
    if (this.props.selectedDate) this.setState(this.updateDates(this.props.selectedDate));
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedDate !== this.state.selectedDate) {
      let newDate = this.updateDates(this.state.selectedDate);

      if (newDate.selectedDate !== this.state.selectedDate) {
        this.setState(newDate);
      } else {
        //Validate min max date
        this.props.onChangeSelection(newDate.selectedDate);
      }
    }

    if (prevProps.selectedDate !== this.props.selectedDate || this.props.forceSelection) {
      this.setState(this.updateDates(this.props.selectedDate));
    } else {
      if (prevProps.minDate !== this.props.minDate || prevProps.maxDate !== this.props.maxDate) {
        this.setState(this.updateDates(this.state.selectedDate));
      }
    }

    if (this.state.isOpenDropdown !== prevState.isOpenDropdown && this.props.isOpenDropdownChanged) {
      this.props.isOpenDropdownChanged(this.state.isOpenDropdown);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  updateDates = (inputDate) => {
    const dateFormat = Settings.moment.date;

    //IF IS NOT A LOAD LOOK TO MIN AND MAX
    if (sessionStorage.getItem("filtersByReport") === null) {
      if (this.props.minDate && moment(inputDate, dateFormat).startOf("day") < moment(this.props.minDate, dateFormat).startOf("day")) {
        inputDate = moment(this.props.minDate, dateFormat).format(dateFormat);
      }

      if (this.props.maxDate && moment(inputDate, dateFormat).startOf("day") > moment(this.props.maxDate, dateFormat).startOf("day")) {
        inputDate = moment(this.props.maxDate, dateFormat).format(dateFormat);
      }
    }

    return {
      selectedDate: checkInvalidDate(moment(inputDate, dateFormat).format(dateFormat)),
    };
  };

  handleOutsideClick = (e) => {
    /*
      Has onClickOutsideClose Enabled
      Has isToggle Enabled
      Is Tooltip Open
      Is Click Outside Tooltip
      */
    if (this.calendarRef && this.calendarRef.current && this.state.isOpenDropdown && !this.calendarRef.current.contains(e.target)) {
      this.setState({ isOpenDropdown: false });
    }
  };

  render() {
    return (
      <StyledCalendar isOpen={this.state.isOpenDropdown}>
        <NanoFlex>
          <NanoFlex className="inputDateForm" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <Type.p>{this.translation.filters.date}</Type.p>
            <InputDate date={this.state.selectedDate} onDateChange={(newDate) => this.setState(this.updateDates(newDate))} />
          </NanoFlex>

          <NanoFlex className="iconWrapper">
            <div ref={this.calendarRef}>
              <Manager>
                <Reference>
                  {(targetProps) => {
                    return (
                      <div
                        ref={targetProps.ref}
                        onClick={() => {
                          this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
                        }}>
                        <NanoFlex className="icon">
                          <CalendarIcon />
                        </NanoFlex>
                      </div>
                    );
                  }}
                </Reference>
                {this.state.isOpenDropdown && (
                  <Popper placement={this._isMobile ? "top" : "bottom"}>
                    {({ ref, style, placement, arrowProps }) => (
                      <div className="popUpContainer" ref={ref} style={style} data-placement={placement}>
                        <NanoFlex className="datePickerWrapper" alignItems="flex-start">
                          <DatePicker selectedDate={this.state.selectedDate} minDate={this.props.minDate} maxDate={this.props.maxDate} onDateChange={(newDate) => this.setState(this.updateDates(newDate))} />
                        </NanoFlex>
                      </div>
                    )}
                  </Popper>
                )}
              </Manager>
            </div>
          </NanoFlex>
        </NanoFlex>
      </StyledCalendar>
    );
  }
}

export default Calendar;

// Set default props
Calendar.defaultProps = {
  selectedDate: null,
};

// --- // Documentation // --- //
/*
import Calendar from './../Micro/Calendar.js';

<Calendar
    selectedDate={"27-02-2020"}
    onChangeSelection={(obj) => console.log(obj)}
/>
*/
