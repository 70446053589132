import React from "react";
import BaseComponent from "../../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../../Global/NanoFlex.js";
import Type from "../../../../Global/Typography.js";

// COMPONENTS
import Medal from "../../../../Global/Elements/Blocks/Medal.js";

const StyledListPalmares = styled(NanoFlex)`
  .listPalmaresContent {
    .listWrapper {
      border: 1px solid ${(props) => props.theme.color.main.font};
      overflow: hidden;
      height: auto;
      .listHeader {
        padding: 16px 8px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        height: auto;
        box-shadow: ${(props) => props.theme.boxShadow};
        position: sitcky;
        z-index: 1;
        .headerCol {
          p {
            text-transform: uppercase;
          }
        }
      }
      .tableListWrapper {
        overflow: auto;
        padding-top: 4px;
        /* max-height: calc(100vh - 396px); */
        max-height: 472px;
        .lineTable {
          padding: 8px;
          height: auto;
          .lineCol {
            height: auto;
            overflow: hidden;
            p {
              width: calc(100% - 16px);
              /* overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap; */
            }
            img {
              max-width: 80%;
              max-height: 26px;
              object-fit: contain;
            }
          }

          &:nth-child(even) {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
          }

          .alertNumber {
            p {
              color: ${(props) => props.theme.color.colorsPalette.red};
            }
          }
        }
      }
      .xs {
        width: 64px;
      }
      .s {
        flex: 1;
      }
      .m {
        flex: 2;
      }
      .l {
        flex: 3;
      }

      .centerCol {
        p {
          text-align: center;
        }
      }
    }
  }
  .noResults {
    padding: 32px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    h3 {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
  }
`;

export class ListPalmares extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {
    super.componentDidMount();

    // if (this.props.filtersSelected) {
    //   this.getPrograms();
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (JSON.stringify(prevProps.filtersSelected) !== JSON.stringify(this.props.filtersSelected) || prevProps.report !== this.props.report) {
    //   if (this.props.filtersSelected) {
    //     this.getPrograms();
    //   } else {
    //     this.setState({ programs: null });
    //   }
    // }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilter = () => {
    return {
      ...this.props.filtersSelected,
      ...{ report: this.props.report },
    };
  };

  getReportTitle = () => {
    switch (this.props.report) {
      case 6:
        return this.translation.global.noteQualiTV;
      case 7:
        return this.translation.palmares.origin;
      case 8:
        return this.translation.palmares.education;
      case 9:
        return this.translation.global.impact;
    }
    return null;
  };

  render() {
    return (
      <StyledListPalmares alignItems="flex-start">
        <NanoFlex className="listPalmaresContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          {/* NO RESULTS */}
          {(!this.props.programs || this.props.programs.length === 0) && (
            <NanoFlex className="noResults">
              <Type.h3>
                <b>{this.translation.global.noResults}</b>
              </Type.h3>
            </NanoFlex>
          )}

          {/* PALMARÉS LIST */}
          {this.props.programs && this.props.programs.length > 0 && (
            <NanoFlex className="listWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="listHeader">
                <NanoFlex className="headerCol xs">{/* empty */}</NanoFlex>
                <NanoFlex className="headerCol l" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.palmares.programsName}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="headerCol s">
                  <Type.p>
                    <b>{this.translation.palmares.channel}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="headerCol m" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.palmares.type}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="headerCol s centerCol">
                  <Type.p>
                    <b>{this.getReportTitle()}</b>
                  </Type.p>
                </NanoFlex>
                {this.props.showRespondants && (
                  <NanoFlex className="headerCol s centerCol">
                    <Type.p>
                      <b>{this.translation.palmares.numberRespondents}</b>
                    </Type.p>
                  </NanoFlex>
                )}
              </NanoFlex>
              {/* TABLE */}
              <NanoFlex className="tableListWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {this.props.programs?.slice(0, this.props.top)?.map((p, i) => (
                  <NanoFlex key={`programme_${i}`} className="lineTable" justifyContent="flex-start">
                    <NanoFlex className="lineCol xs">
                      <Medal gold={i === 0} silver={i === 1} bronze={i === 2} place={i + 1} />
                    </NanoFlex>
                    <NanoFlex className="lineCol l" justifyContent="flex-start">
                      <Type.p>{p.programTitle}</Type.p>
                    </NanoFlex>
                    <NanoFlex className="lineCol s">
                      <img src={"/images/channels/" + p.name.replace(/\s/g, "") + ".png"} alt="Chaîne Logo" />
                    </NanoFlex>
                    <NanoFlex className="lineCol m" justifyContent="flex-start">
                      <Type.p>{p.genre}</Type.p>
                    </NanoFlex>
                    <NanoFlex className="lineCol s centerCol">
                      <Type.p>{p.value}</Type.p>
                    </NanoFlex>
                    {this.props.showRespondants && (
                      <NanoFlex className={`lineCol s centerCol ${p.share < 60 ? "alertNumber" : ""}`}>
                        <Type.p>{p.share}</Type.p>
                      </NanoFlex>
                    )}
                  </NanoFlex>
                ))}
              </NanoFlex>
              {/* END TABLE */}
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledListPalmares>
    );
  }
}
