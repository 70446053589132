/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

//IMGS
import { ReactComponent as Filters } from "../../../Global/icons/filters.svg";
import { ReactComponent as Close } from "../../../Global/icons/close.svg";

const StyledRecapFilters = styled(NanoFlex)`
  height: auto;
  user-select: none;
  .recapTitle {
    height: auto;
    margin-bottom: 8px;
    p {
      text-transform: uppercase;
    }
  }
  .recapFilterWrapper {
    .leftAsideWrapper {
      background-color: ${(props) => props.theme.color.main.green};
      width: auto;
      padding: 8px;
      cursor: pointer;
      .iconWrapper {
        margin-bottom: 8px;
        margin-top: 4px;
        svg {
          height: 16px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.white};
          }
        }
      }
      .filtersNumber {
        h6 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
    .rightAsideWrapper {
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      overflow-y: hidden;
      overflow-x: hidden;
      min-width: 0;
      width: calc(100% - 32px);
      min-height: 56px;
      .recapFiltersList {
        width: auto;
        cursor: grab;
        cursor: -webkit-grab;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: horizontal;
        padding: 4px;
        .recapFilterItem {
          width: auto;
          margin-right: 4px;
          user-select: none;
          .filterInfo {
            width: auto;
            padding: 8px 16px 8px 8px;
            background-color: ${(props) => props.theme.color.main.white};
          }
          .closeBtn {
            width: auto;
            cursor: pointer;
            background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
            padding: 8px;
            svg {
              width: 8px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              opacity: 0.8;
              svg {
                path {
                  fill: ${(props) => props.theme.color.colorsPalette.red};
                }
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export class RecapFilters extends BaseComponent {
  constructor(props) {
    super(props);

    this.scrollerRef = React.createRef();
    this.isDown = false;
    this.startX = null;
    this.scrollLeft = null;
  }
  onMouseDown = (e) => {
    this.isDown = true;
    this.startX = e.pageX - this.scrollerRef.current.offsetLeft;
    this.scrollLeft = this.scrollerRef.current.scrollLeft;
  };

  onMouseLeave = (e) => {
    this.isDown = false;
  };

  onMouseUp = () => {
    this.isDown = false;
  };

  onMouseMove = (e) => {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.scrollerRef.current.offsetLeft;
    const walk = (x - this.startX) * 3; //scroll-fast
    this.scrollerRef.current.scrollLeft = this.scrollLeft - walk;
  };

  render() {
    return (
      <StyledRecapFilters flexDirection="column" justifyContent="flex-start">
        <NanoFlex className="recapTitle" justifyContent="flex-start">
          <Type.p>{this.translation.filters.selectedFilters}</Type.p>
        </NanoFlex>
        <NanoFlex className="recapFilterWrapper" justifyContent="flex-start">
          <NanoFlex className="leftAsideWrapper" flexDirection="column" onClick={() => window.dispatchEvent(new Event("triggerFilterToggle"))}>
            <NanoFlex className="iconWrapper">
              <Filters />
            </NanoFlex>
            <NanoFlex className="filtersNumber">
              <Type.h6>{this.props.selectedFilters?.filterRecap?.map((i) => i.values.length)?.reduce((a, b) => a + b, 0)}</Type.h6>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="rightAsideWrapper" justifyContent="flex-start" ref={this.scrollerRef} onMouseDown={this.onMouseDown} onMouseLeave={this.onMouseLeave} onMouseUp={this.onMouseUp} onMouseMove={this.onMouseMove}>
            {/* RECAP FILTERS LIST */}
            <NanoFlex className="recapFiltersList" justifyContent="flex-start">
              {/* RECAP FILTER ITEM - TO REPEAT */}
              {this.props.selectedFilters?.filterRecap?.map((v) => {
                let filterValue = v.values
                  .map((m) => m.name)
                  .join(", ")
                  .replace(/\t/g, "");

                return (
                  <NanoFlex className="recapFilterItem" key={v.field} title={filterValue}>
                    <NanoFlex className="filterInfo" flexDirection="column" alignItems="flex-start">
                      <Type.p>
                        <b>{v.translation}</b>
                      </Type.p>
                      <Type.p>
                        {v.values
                          .map((m) => {
                            return m.name;
                          })
                          .join(", ").length > 40 && v.field !== "date"
                          ? v.isAllSelected
                            ? this.translation.filters.allSelected
                            : v.values.length + " " + v.translation
                          : filterValue}
                      </Type.p>
                    </NanoFlex>
                    {!v.disabled && (
                      <NanoFlex className="closeBtn" onClick={() => window.dispatchEvent(new CustomEvent("deleteFilter", { detail: [v] }))}>
                        <Close />
                      </NanoFlex>
                    )}
                  </NanoFlex>
                );
              })}
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledRecapFilters>
    );
  }
}
