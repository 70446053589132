import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import moment from "moment";
import NanoFlex from "./../../../Global/NanoFlex.js";
import gridService from "./../../../../services/GridService";
import { getDateTime, downloadBlob } from "./../../../Global/tools.js";
import Settings from "./../../../Global/Settings.json";

// COMPONENTS
import Loading from "../../../Global/Elements/Loading/Loading.js";
import { Filters } from "./../Intermediaires/Filters.js";
import { RecapFilters } from "./../Grid/RecapFilters.js";
import { OptionsFilters } from "./OptionsFilters.js";
import { Timeline } from "./../Grid/Timeline.js";
import { Results } from "./../Grid/Results.js";

const StyledDefinitifs = styled(NanoFlex)`
  min-width: 0;
  .definitifsContent {
    padding: 32px;
    min-width: 0;
  }
`;

export class Definitifs extends BaseComponent {
  constructor(props, prevProps) {
    super(props, prevProps);

    let prevFilters = null
    let prevStart = null
    let prevEnd = null

    if (window.location.pathname === sessionStorage.getItem("lastPathname") || sessionStorage.getItem("lastPathname") === "/librairies") {
      prevFilters = JSON.parse(sessionStorage.getItem("filtersByReport"));
      prevStart = moment(prevFilters?.startTime);
      prevEnd = moment(prevFilters?.endTime);
    }

    this.state = {
      filter: prevFilters ?? null,
      start: prevFilters !== null && prevFilters.startTime && new Date(prevFilters.startTime).getHours() !== 0 ? moment().startOf("day").set("hour", prevStart?.hour()).set("minute", prevStart.minute()).toDate() : moment().startOf("day").set("hour", 0).toDate(),
      end: prevFilters !== null && prevFilters.endTime && new Date(prevFilters.endTime).getHours() !== 0 ? moment().startOf("day").set("hour", prevEnd?.hour()).set("minute", prevEnd.minute()).toDate() : moment().startOf("day").set("hour", 24).toDate(),
      results: null,
      viewDetails: prevFilters?.viewDetails ?? false,
      loading: false,
    };

    if (this.state.filter) {
      this.state.filter.minTimeline = moment(this.state.start).set("hour", 0).toDate();
      this.state.filter.maxTimeline = moment(this.state.start).set("hour", 24).toDate();
    }

    sessionStorage.setItem("lastPathname", window.location.pathname)
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter) || JSON.stringify(prevState.start) !== JSON.stringify(this.state.start) || JSON.stringify(prevState.end) !== JSON.stringify(this.state.end)) {
      if (this.state.filter) {
        this.getDailyEmissions();
      } else {
        this.setState({ results: null });
      }
    }

    if (
      prevState.filter?.minTimeline &&
      prevState.filter?.maxTimeline &&
      (prevState.filter.minTimeline.getTime() !== this.state.filter.minTimeline.getTime() || prevState.filter.maxTimeline.getTime() !== this.state.filter.maxTimeline.getTime()) &&
      this.state.start.getTime() === prevState.filter.minTimeline.getTime() &&
      this.state.end.getTime() === prevState.filter.maxTimeline.getTime()
    ) {
      this.setState({ start: this.state.filter.minTimeline, end: this.state.filter.maxTimeline });
    }

    if (prevState.redirect !== this.state.redirect) {
      this.setState({ redirect: null });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilter = () => {
    return {
      ...this.state.filter,
      ...{
        startTime: getDateTime(this.state.start),
        endTime: getDateTime(this.state.end),
        filterRecap: this.getTranslatedRecapFilters(this.state.filter.filterRecap),
        viewDetails: this.state.viewDetails,
      },
    };
  };

  getDailyEmissions = () => {
    gridService
      .getDailyEmissions(this.getFilter())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ results: data });
        sessionStorage.removeItem("filtersByReport");
        sessionStorage.removeItem("jsonLinesByReport");
        sessionStorage.removeItem("jsonColumnsByReport");
      });
  };

  onExcelExport = () => {
    gridService
      .getDailyEmissionsExcel(this.getFilter())
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((blob) => {
        if (blob) {
          downloadBlob(blob, `Report-Definitifs-${this.state.filter.dateSelected ? moment(this.state.filter.dateSelected, Settings.moment.serverDate).format(Settings.moment.date) : null}.xlsx`);
        }
      });
  };

  onPdfExport = () => {
    this.setState({ loading: true }, () => {
      gridService
        .getDailyEmissionsPdf(this.getFilter())
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((blob) => {
          if (blob) {
            downloadBlob(blob, `Report-Definitifs-${this.state.filter.dateSelected ? moment(this.state.filter.dateSelected, Settings.moment.serverDate).format(Settings.moment.date) : null}.pdf`);
          }
          this.setState({ loading: false });
        });
    });
  };

  render() {
    let loading = !this.state.filter || this.state.results === null || this.state.loading;

    return (
      <StyledDefinitifs justifyContent="flex-start">
        <Filters filtersSelected={this.state.filter} onFilterChange={(f) => this.setState({ filter: f })} isActive={this.state.filter ? true : false} isDefinitifs={true} />
        <NanoFlex className="definitifsContent">
          {loading && (
            <NanoFlex clasName="definitifsWrapper">
              <Loading />
            </NanoFlex>
          )}
          {!loading && (
            <NanoFlex className="definitifsWrapper" flexDirection="column" justifyContent="flex-start">
              <RecapFilters selectedFilters={this.getFilter()} />
              <OptionsFilters
                selectedInterval={[moment(this.state.start).format("HH:mm"), moment(this.state.end).format("HH:mm")]}
                peopleSample={this.state.results?.filterSample}
                viewDetails={this.state.viewDetails}
                filtersSelected={this.getFilter()}
                hasData={this.state.results?.channels.length > 0}
                onViewDetail={(f) => {
                  this.setState({ viewDetails: f });
                }}
                onExcelExport={this.onExcelExport}
                onPdfExport={this.onPdfExport}
              />
              <Timeline selectedInterval={[this.state.start, this.state.end]} timelineInterval={[this.state.filter.minTimeline, this.state.filter.maxTimeline]} onChangeCallback={(intervals) => this.setState({ start: intervals[0], end: intervals[1] })} />
              <Results results={this.state.results} viewDetails={this.state.viewDetails} filtersSelected={this.state.filter} />
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledDefinitifs>
    );
  }
}
