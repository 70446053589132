/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import moment from "moment";
import Settings from "../../Global/Settings.json";
import { NavLink } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//Imgs
import LogoFranceTv from "../../Global/images/francetv-black.svg";
import { ReactComponent as LogOut } from "../../Global/icons/logOut.svg";
import { ReactComponent as Rapports } from "../../Global/icons/rapports.svg";
import { ReactComponent as Librairies } from "../../Global/icons/librairies.svg";
import { ReactComponent as Administration } from "../../Global/icons/administration.svg";
import { ReactComponent as Filter } from "../../Global/icons/filters.svg";
import { ReactComponent as ArrowLeft } from "../../Global/icons/arrowLeft.svg";

const StyledNavBar = styled(NanoFlex)`
  width: ${(props) => (props.isCollapsed ? "140px" : "280px")};
  position: relative;
  transition: ${(props) => props.theme.transition};
  z-index: 2;
  .navBarWrapper {
    box-shadow: ${(props) => props.theme.boxShadow};
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    .topNavBar {
      padding: 16px 8px;
      .headerNavBar {
        height: auto;
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.grey};
        padding-bottom: 8px;
        margin-bottom: 8px;
        .logoWrapper {
          padding: ${(props) => (props.isCollapsed ? "16px 4px 32px 4px" : "16px 16px 32px 16px")};
          height: auto;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .userWrapper {
          height: auto;
          background-color: ${(props) => props.theme.color.main.white};
          padding: 8px;
          flex-direction: ${(props) => (props.isCollapsed ? "column" : "row")};
          .userInfo {
            p {
              font-size: ${(props) => (props.isCollapsed ? "12px" : "14px")};
            }
            h6 {
              font-size: ${(props) => (props.isCollapsed ? "10px" : "12px")};
            }
          }
          .logoutBtn {
            cursor: pointer;
            width: auto;
            padding: ${(props) => (props.isCollapsed ? "8px 8px 0px 8px" : "8px")};
            svg {
              width: 16px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              svg {
                path {
                  fill: ${(props) => props.theme.color.colorsPalette.red};
                }
              }
            }
          }
        }
      }

      .listNavBar {
        .listNavWrapper {
          height: auto;
          .itemWrapper {
            width: 100%;
            text-decoration: none;
            margin-top: 8px;
            .itemNavBar {
              padding: 8px 16px;
              background-color: ${(props) => props.theme.color.main.white};
              .iconWrapper {
                width: auto;
                svg {
                  width: auto;
                  max-width: ${(props) => (props.isCollapsed ? "22px" : "18px")};
                  height: ${(props) => (props.isCollapsed ? "22px" : "18px")};

                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
              }
              .titleWrapper {
                margin-left: 16px;
                display: ${(props) => (props.isCollapsed ? "none" : "flex")};
              }
            }
            &:first-child {
              margin-bottom: 0;
            }
            &:hover {
              opacity: 0.8;
            }
          }
          .activeItem {
            pointer-events: none;
            .itemNavBar {
              border-right: 4px solid ${(props) => props.theme.color.main.green};
              p {
                font-weight: bold;
              }
              .iconWrapper {
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.green};
                  }
                }
              }
              .titleWrapper {
              }
            }
          }

          .secNavList {
            background-color: ${(props) => props.theme.color.main.white};
            padding: 4px;
            height: auto;
            .secItem {
              text-decoration: none;
              width: 100%;
              height: ${(props) => (props.isCollapsed ? "auto" : "32px")};

              margin-bottom: 4px;
              .secNavItem {
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                flex-direction: ${(props) => (props.isCollapsed ? "column" : "row")};
                .secNavTitle {
                  padding: 8px;
                  justify-content: ${(props) => (props.isCollapsed ? "center" : "flex-start")};
                  text-align: ${(props) => (props.isCollapsed ? "center" : "left")};
                }
                .filterBtn {
                  display: none;
                  width: ${(props) => (props.isCollapsed ? "100%" : "auto")};
                  height: ${(props) => (props.isCollapsed ? "auto" : "32px")};
                  padding: 8px;
                  background-color: ${(props) => props.theme.color.main.font};
                  svg {
                    max-width: 18px;
                    max-height: 18px;
                    height: auto;
                    path {
                      fill: ${(props) => props.theme.color.main.white};
                    }
                  }
                }
                .closedFilter {
                  background-color: ${(props) => props.theme.color.colorsPalette.grey};
                }
                .activeFilter {
                  background-color: ${(props) => props.theme.color.main.green};
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
              &:hover {
                opacity: 0.8;
              }
            }
            .activeSecItem {
              .secNavItem {
                background-color: ${(props) => props.theme.color.main.white};
                border: ${(props) => (props.isCollapsed ? "1px" : "0")};
                border-left: ${(props) => (props.isCollapsed ? "1px" : "4px")};
                border-style: solid;
                border-color: ${(props) => props.theme.color.main.green};
                cursor: default;
                .secNavTitle {
                  pointer-events: none;
                }
                .filterBtn {
                  display: flex;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .bottomNavBar {
      height: auto;
      .collapseBtn {
        cursor: pointer;
        padding: 8px 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        svg {
          width: auto;
          height: 14px;
          transform: rotate(${(props) => (props.isCollapsed ? "180deg" : "0")});
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
`;

export class NavBar extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      isCollapsed: JSON.parse(localStorage.getItem("isNavbarCollapsed")) ?? false,
      ...this.getDefaultFilterSettings(),
    };
  };

  getDefaultFilterSettings = () => {
    return {
      isFilterOpen: false,
      isFilterActive: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();

    window.addEventListener("onFilterToggle", this.onFilterToggle, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isCollapsed !== prevState.isCollapsed) {
      localStorage.setItem("isNavbarCollapsed", this.state.isCollapsed);
    }

    if (this.props.render.match.path !== prevProps.render.match.path) {
      this.setState(this.getDefaultFilterSettings());
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();

    window.removeEventListener("onFilterToggle", this.onFilterToggle, false);
  }

  onFilterToggle = (e) => {
    if (e?.detail) {
      this.setState({
        isFilterOpen: e.detail.isOpen,
        isFilterActive: e.detail.isActive,
      });
    }
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  onToggle = () => {
    window.dispatchEvent(new Event("triggerFilterToggle"));
  };

  render() {
    let navFilter = (
      <NanoFlex className={`filterBtn ${!this.state.isFilterOpen ? "closedFilter" : ""} ${this.state.isFilterActive ? "activeFilter" : ""}`} onClick={this.onToggle}>
        <Filter />
      </NanoFlex>
    );

    return (
      <StyledNavBar className="navBar" isCollapsed={this.state.isCollapsed}>
        <NanoFlex className="navBarWrapper" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="topNavBar" flexDirection="column">
            <NanoFlex className="headerNavBar" flexDirection="column" justifyContent="flex-start">
              <NanoFlex className="logoWrapper">
                <img src={LogoFranceTv} alt="France TV Logo" />
              </NanoFlex>
              <NanoFlex className="userWrapper" justifyContent="space-between">
                <NanoFlex className="userInfo" flexDirection="column" alignItems="flex-start">
                  <Type.p>{this._user.UserName}</Type.p>
                  <Type.h6>{moment(this._user.LoginDate).format(Settings.moment.navBar)}</Type.h6>
                </NanoFlex>
                <NanoFlex className="logoutBtn">
                  <NavLink to="/login">
                    <LogOut />
                  </NavLink>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="listNavBar" alignItems="flex-start">
              <NanoFlex className="listNavWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                {/* NAV BAR ITEM - RAPPORTS */}
                <NavLink
                  to="#"
                  className={`itemWrapper ${
                    this.props.render.match.path === "/" ||
                    this.props.render.match.path === "/intermediaires" ||
                    this.props.render.match.path === "/definitifs" ||
                    this.props.render.match.path === "/palmares" ||
                    this.props.render.match.path === "/tvReplay" ||
                    this.props.render.match.path === "/dynamique" ||
                    this.props.render.match.path === "/verbatim"
                      ? "activeItem"
                      : ""
                  }`}>
                  <NanoFlex className="itemNavBar" title={this.translation.global.rapports}>
                    <NanoFlex className="iconWrapper">
                      <Rapports />
                    </NanoFlex>
                    <NanoFlex className="titleWrapper" justifyContent="flex-start">
                      <Type.p>{this.translation.global.rapports}</Type.p>
                    </NanoFlex>
                  </NanoFlex>
                </NavLink>
                {/* SECONDARY NAV LIST - RAPPORTS */}
                <NanoFlex className="secNavList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  {/* SECONDARY NAV ITEM - DEFINITIFS */}
                  <NavLink to="/" exact className={`secItem ${this.props.render.match.path === "/definitifs" ? "activeSecItem" : ""}`} activeClassName="activeSecItem">
                    <NanoFlex className="secNavItem" justifyContent="space-between">
                      <NanoFlex className="secNavTitle" justifyContent="flex-start">
                        <Type.h6>{this.translation.global.definitifs}</Type.h6>
                      </NanoFlex>
                      {navFilter}
                    </NanoFlex>
                  </NavLink>

                  {/* SECONDARY NAV ITEM - INTERMÉDIAIRES */}
                  {this._user.IsAdmin && (
                    <NavLink to="/intermediaires" className="secItem" activeClassName="activeSecItem">
                      <NanoFlex className="secNavItem" justifyContent="space-between">
                        <NanoFlex className="secNavTitle" justifyContent="flex-start">
                          <Type.h6>{this.translation.global.intermediaires}</Type.h6>
                        </NanoFlex>
                        {navFilter}
                      </NanoFlex>
                    </NavLink>
                  )}

                  {/* SECONDARY NAV ITEM - PALMARÈS */}
                  <NavLink to="/palmares" className="secItem" activeClassName="activeSecItem">
                    <NanoFlex className="secNavItem" justifyContent="space-between">
                      <NanoFlex className="secNavTitle" justifyContent="flex-start">
                        <Type.h6>{this.translation.global.palmares}</Type.h6>
                      </NanoFlex>
                      {navFilter}
                    </NanoFlex>
                  </NavLink>

                  {/* SECONDARY NAV ITEM - TV REPLAY */}
                  <NavLink to="/tvReplay" className="secItem" activeClassName="activeSecItem">
                    <NanoFlex className="secNavItem" justifyContent="space-between">
                      <NanoFlex className="secNavTitle" justifyContent="flex-start">
                        <Type.h6>{this.translation.global.tvReplay}</Type.h6>
                      </NanoFlex>
                      {navFilter}
                    </NanoFlex>
                  </NavLink>

                  {/* SECONDARY NAV ITEM - RAPPORT CROISÉ DYNAMIQUE */}
                  <NavLink to="/dynamique" className="secItem" activeClassName="activeSecItem">
                    <NanoFlex className="secNavItem" justifyContent="space-between">
                      <NanoFlex className="secNavTitle" justifyContent="flex-start">
                        <Type.h6>{this.translation.global.rapportCroiseDynamique}</Type.h6>
                      </NanoFlex>
                      {navFilter}
                    </NanoFlex>
                  </NavLink>

                  {/* SECONDARY NAV ITEM - VERBATIM */}
                  <NavLink to="/verbatim" className="secItem" activeClassName="activeSecItem">
                    <NanoFlex className="secNavItem" justifyContent="space-between">
                      <NanoFlex className="secNavTitle" justifyContent="flex-start">
                        <Type.h6>{this.translation.global.verbatim}</Type.h6>
                      </NanoFlex>
                      {navFilter}
                    </NanoFlex>
                  </NavLink>

                  {/* SECONDARY NAV ITEM - PROFILE */}
                  {this._user.IsAdmin && (
                    <NavLink to="/profil" className="secItem" activeClassName="activeSecItem">
                      <NanoFlex className="secNavItem" justifyContent="space-between">
                        <NanoFlex className="secNavTitle" justifyContent="flex-start">
                          <Type.h6>{this.translation.global.profile}</Type.h6>
                        </NanoFlex>
                        {navFilter}
                      </NanoFlex>
                    </NavLink>
                  )}
                </NanoFlex>
                {/* END SECONDARY NAV LIST - RAPPORTS */}
                {/* NAV BAR ITEM - LIBRAIRIES */}
                <NavLink to="/librairies" className="itemWrapper" activeClassName="activeItem">
                  <NanoFlex className="itemNavBar" title={this.translation.global.librairies}>
                    <NanoFlex className="iconWrapper">
                      <Librairies />
                    </NanoFlex>
                    <NanoFlex className="titleWrapper" justifyContent="flex-start">
                      <Type.p>{this.translation.global.librairies}</Type.p>
                    </NanoFlex>
                  </NanoFlex>
                </NavLink>

                {/* NAV BAR ITEM - ADMINISTRATION */}
                <NavLink to={`${this._user.IsAdmin ? "/admin" : "/admin/reports"}`} className="itemWrapper" activeClassName="activeItem">
                  <NanoFlex className="itemNavBar" title={this.translation.global.administration}>
                    <NanoFlex className="iconWrapper">
                      <Administration />
                    </NanoFlex>
                    <NanoFlex className="titleWrapper" justifyContent="flex-start">
                      <Type.p>{this.translation.global.administration}</Type.p>
                    </NanoFlex>
                  </NanoFlex>
                </NavLink>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="bottomNavBar">
            <NanoFlex className="collapseBtn" onClick={this.toggleCollapse}>
              <ArrowLeft />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledNavBar>
    );
  }
}
