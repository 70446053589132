import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";

// COMPONENTS
import EmissionHeader from "./EmissionHeader.js";
import EmissionCard from "./EmissionCard.js";

const StyledEmissionList = styled(NanoFlex)`
  width: auto;
  margin-right: 4px;
  .emissionListWrapper {
    width: auto;
    height: auto;
    background-color: ${(props) => props.theme.color.main.white};
    .emissionsCardList {
      padding: 4px;
      /* height: calc(100vh - 382px);
      overflow: auto; */
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;

export default class EmissionList extends Component {
  
  render() {
    let emission = this.props.data;
    
    return (
      <StyledEmissionList alignItems="flex-start">
        <NanoFlex className="emissionListWrapper" flexDirection="column" justifyContent="flex-start">
          <EmissionHeader data={emission} filtersSelected={this.props.filtersSelected} />
          <NanoFlex className="emissionsCardList" flexDirection="column" justifyContent="flex-start">
            {/* EMISSION CARD - TO REPEAT */}
            {emission.emissions.map((v) => {
              return <EmissionCard data={v} viewDetails={this.props.viewDetails} key={`emissionCard_${v.id}}`} filtersSelected={this.props.filtersSelected} />;
            })}
            {/* EMISSION EMPTY CARD - TO REPEAT */}
            {Array(emission.maxCardsList - emission.emissions.length)
              .fill()
              .map(() => {
                return <EmissionCard empty key={`emissionCardEmpty_${Math.random().toString(36).substring(5)}}`} />;
              })}
          </NanoFlex>
        </NanoFlex>
      </StyledEmissionList>
    );
  }
}
