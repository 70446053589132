import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import filtersService from "./../../../../services/FiltersService";
import moment from "moment";
import Settings from "../../../Global/Settings.json";
import * as _ from "lodash";

// COMPONENTS
import { BaseFilter } from "./../../Shared/BaseFilter.js";
import { BaseFilterSection } from "./../../Shared/BaseFilterSection.js";
import MultipleSelectionDropdown from "../../../Global/Elements/Forms/MultipleSelectionDropdown.js";
import InputNumberRange from "../../../Global/Elements/Forms/InputNumberRange.js";
import Calendar from "../../../Global/Elements/Forms/Calendar.js";
import Input from "../../../Global/Elements/Forms/Input.js";
import FetchTreeNavigatorDropdown from "../../../Global/Elements/Forms/FetchTreeNavigatorDropdown";

const StyledFilters = styled(NanoFlex)`
  width: auto;
  transition: ${(props) => props.theme.transition};
`;

export class Filters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState(this.props.filtersSelected ?? {});

    this.firstLoad = true;
    this.defaultDateSelected = null;
  }

  getDefaultState = (prevFilters) => {
    return {
      dateRanges: null,
      channels: null,
      dayParts: null,
      genres: null,
      genders: null,
      ages: null,
      csp: null,
      loaded: false,
      ...this.getDefaultStateSelection(prevFilters),
    };
  };

  getDefaultStateSelection = (prevFilters) => {
    return {
      //Date
      dateSelected: moment(new Date(prevFilters.dateSelected), Settings.moment.serverDate).format(Settings.moment.date) ?? this.defaultDateSelected,
      //Channels
      channelsSelected: prevFilters.channelsSelected ?? [],
      allChannelsSelected: false,
      //Emissions
      emissionsSelected: prevFilters.emissionsSelected ?? [],
      //Genres
      genresSelected: prevFilters.genresSelected ?? [],
      allGenresSelected: false,
      //Genders
      gendersSelected: prevFilters.gendersSelected ?? [],
      allGendersSelected: false,
      //Ages
      agesSelected: prevFilters.agesSelected ?? [],
      allAgesSelected: false,
      //Dayparts
      dayPartsSelected: prevFilters.dayPartsSelected ?? [1],
      allDayPartsSelected: false,
      //Csp
      cspSelected: prevFilters.cspSelected ?? [],
      allCspSelected: false,
      //Duration
      durationStart: prevFilters.durationStart ?? null,
      durationEnd: prevFilters.durationEnd ?? null,
      //Correspondants
      correspondantsStart: prevFilters.correspondantsStart ?? 60,
      correspondantsEnd: prevFilters.correspondantsEnd ?? null,
    };
  };

  componentDidMount() {
    this.defaultDateSelected = null;
    this.getAllData();

    //Events
    window.addEventListener("deleteFilter", this.onDeleteFilter, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isComponentDataLoaded(prevState) !== this.isComponentDataLoaded(this.state)) {
      this.setState({ loaded: this.isComponentDataLoaded(this.state) }, () => {
        /*Trigger Filter on First Load*/
        if (this.firstLoad && this.state.loaded) {
          this.firstLoad = false;
          this.onFilter();
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("deleteFilter", this.onDeleteFilter, false);
  }

  getAllData = () => {
    if (this.props.isDefinitifs) {
      this.getDateRangeDefinitifs();
    } else {
      this.getDateRangeIntermediaires();
    }
    this.getDayparts();
    this.getChannels();
    this.getGenres();
    this.getAges();
    this.getGenders();
    this.getCSP();
  };

  isComponentDataLoaded = (obj) => {
    let state = obj ?? this.state;

    if (state.dateRanges === null || state.dayparts === null || state.channels === null || state.genres === null || state.genders === null || state.ages === null || state.csp === null) {
      return false;
    } else {
      return true;
    }
  };

  getDateRangeDefinitifs = () => {
    filtersService
      .getDateRangeDefinitifs()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.defaultDateSelected = data?.endDate;
        this.setState({ dateRanges: data, dateSelected: this.props.filtersSelected?.dateSelected ?? data?.endDate });
      });
  };

  getDateRangeIntermediaires = () => {
    filtersService
      .getDateRangeIntermediaires()
      .then((response) => {
        if (response.ok && response.status !== 204) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        // this.defaultDateSelected = data?.endDate;
        // var dateSelected = new Date();
        // dateSelected.setDate(dateSelected.getDate() - 1);
        // this.setState({ dateRanges: data, dateSelected: new Date(dateSelected.toISOString()) });
        this.setState({ dateRanges: data, dateSelected: data?.endDate });
      });
  };

  getEmissions = () => {
    filtersService
      .getEmissions(null)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ emissions: data });
      });
  };

  getDayparts = () => {
    filtersService
      .getDayparts()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ dayParts: data });
      });
  };

  getChannels = () => {
    filtersService
      .getChannels()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ channels: data });
      });
  };

  getGenres = () => {
    filtersService
      .getGenres()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ genres: data });
      });
  };

  getGenders = () => {
    filtersService
      .getGenders()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ genders: data });
      });
  };

  getAges = () => {
    filtersService
      .getAges()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ ages: data });
      });
  };

  getCSP = () => {
    filtersService
      .getCSP()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ csp: data });
      });
  };

  getRecapFilters = () => {
    let recap = [];
    //Date
    if (this.state.dateSelected !== null) {
      recap.push({
        translation: "filters.date",
        field: "date",
        values: [{ name: moment(this.state.dateSelected, Settings.moment.serverDate).format(Settings.moment.date) }],
        disabled: this.props.isIntermediare ?? false,
      });
    }

    //Channels
    if (this.state.channels && this.state.channelsSelected !== null) {
      recap.push({
        translation: "filters.channels",
        field: "channels",
        values: this.state.allChannelsSelected ? this.state.channels : this.state.channels.filter((c) => this.state.channelsSelected.includes(c.id)),
        isAllSelected: this.state.allChannelsSelected,
      });
    }

    //Emissions
    if (this.state.emissionsSelected !== null) {
      recap.push({
        translation: "filters.emissions",
        field: "emissions",
        values: this.state.emissionsSelected,
        isAllSelected: false,
      });
    }

    //Genres
    if (this.state.genres && this.state.genresSelected !== null) {
      recap.push({
        translation: "filters.genreFTV",
        field: "genreFTV",
        values: this.state.allGenresSelected ? this.state.genres : this.state.genres.filter((c) => this.state.genresSelected.includes(c.id)),
        isAllSelected: this.state.allGenresSelected,
      });
    }

    //Dayparts
    if (this.state.dayParts && this.state.dayPartsSelected !== null) {
      recap.push({
        translation: "filters.dayPart",
        field: "dayPart",
        values: this.state.allDayPartsSelected ? this.state.dayParts : this.state.dayParts.filter((c) => this.state.dayPartsSelected.includes(c.id)),
        isAllSelected: this.state.allDayPartsSelected,
      });
    }

    //Genders
    if (this.state.genders && this.state.gendersSelected !== null) {
      recap.push({
        translation: "filters.gender",
        field: "gender",
        values: this.state.allGendersSelected ? this.state.genders : this.state.genders.filter((c) => this.state.gendersSelected.includes(c.id)),
        isAllSelected: this.state.allGendersSelected,
      });
    }

    //Ages
    if (this.state.ages && this.state.agesSelected !== null) {
      recap.push({
        translation: "filters.age",
        field: "age",
        values: this.state.allAgesSelected ? this.state.ages : this.state.ages.filter((c) => this.state.agesSelected.includes(c.id)),
        isAllSelected: this.state.allAgesSelected,
      });
    }

    //Csp
    if (this.state.csp && this.state.cspSelected !== null) {
      recap.push({
        translation: "filters.csp",
        field: "csp",
        values: this.state.allCspSelected ? this.state.csp : this.state.csp.filter((c) => this.state.cspSelected.includes(c.id)),
        isAllSelected: this.state.allCspSelected,
      });
    }

    //Duration
    if (this.state.durationStart || this.state.durationEnd) {
      recap.push({
        translation: "filters.duration",
        field: "duration",
        values: [{ name: this.state.durationStart?.toString() ?? "-" }, { name: this.state.durationEnd?.toString() ?? "-" }],
      });
    }

    //Correspondants
    if (this.state.correspondantsStart || this.state.correspondantsEnd) {
      recap.push({
        translation: "filters.numberCorrespondants",
        field: "numberCorrespondants",
        values: [{ name: this.state.correspondantsStart?.toString() ?? "-" }, { name: this.state.correspondantsEnd?.toString() ?? "-" }],
      });
    }

    return recap.filter((r) => r.values.length > 0);
  };

  onFilter = () => {
    var filters = {
      //Date
      dateSelected: this.state.dateSelected,
      //Channels
      channelsSelected: this.state.channelsSelected,
      allChannelsSelected: this.state.allChannelsSelected,
      //Emissions
      emissionsSelected: this.state.emissionsSelected,
      //Genres
      genresSelected: this.state.genresSelected,
      allGenresSelected: this.state.allGenresSelected,
      //Genders
      gendersSelected: this.state.gendersSelected,
      allGendersSelected: this.state.allGendersSelected,
      //Ages
      agesSelected: this.state.agesSelected,
      allAgesSelected: this.state.allAgesSelected,
      //Dayparts
      dayPartsSelected: this.state.dayPartsSelected,
      allDayPartsSelected: this.state.allDayPartsSelected,
      minTimeline: moment(_.minBy(this.state.dayPartsSelected?.length > 0 ? this.state.dayParts.filter((d) => this.state.dayPartsSelected.includes(d.id)) : [{ startTime: 0 }], (d) => moment(d.startTime.toString(), "HHmm")).startTime, "HHmm").toDate(),
      maxTimeline: moment(_.maxBy(this.state.dayPartsSelected?.length > 0 ? this.state.dayParts.filter((d) => this.state.dayPartsSelected.includes(d.id)) : [{ endTime: 2400 }], (d) => moment(d.endTime.toString(), "HHmm")).endTime, "HHmm").toDate(),
      //Csp
      cspSelected: this.state.cspSelected,
      allCspSelected: this.state.allCspSelected,
      //Duration
      durationStart: this.state.durationStart,
      durationEnd: this.state.durationEnd,
      //Correspondants
      correspondantsStart: this.state.correspondantsStart,
      correspondantsEnd: this.state.correspondantsEnd,
      //Filters Recap
      filterRecap: this.getRecapFilters(),
    };

    this.props.onFilterChange(filters);
    sessionStorage.setItem("filtersByReport", JSON.stringify(filters));
  };

  onDeleteFilter = (e) => {
    if (!e.detail) return;

    let toChange = {};
    e.detail.forEach((f) => {
      switch (f.field) {
        case "date":
          toChange.dateSelected = null;
          break;
        case "channels":
          toChange.allChannelsSelected = false;
          toChange.channelsSelected = [];
          break;
        case "emissions":
          toChange.emissionsSelected = [];
          break;
        case "genreFTV":
          toChange.allGenresSelected = false;
          toChange.genresSelected = [];
          break;
        case "gender":
          toChange.allGendersSelected = false;
          toChange.gendersSelected = [];
          break;
        case "age":
          toChange.allAgesSelected = false;
          toChange.agesSelected = [];
          break;
        case "dayPart":
          toChange.allDayPartsSelected = false;
          toChange.dayPartsSelected = [];
          break;
        case "csp":
          toChange.allCspSelected = false;
          toChange.cspSelected = [];
          break;
        case "duration":
          toChange.durationStart = null;
          toChange.durationEnd = null;
          break;
        case "numberCorrespondants":
          toChange.correspondantsStart = null;
          toChange.correspondantsEnd = null;
          break;
        default:
          break;
      }
    });

    if (toChange !== {}) {
      this.setState(toChange, () => {
        this.onFilter();
      });
    }
  };

  onClear = () => {
    var defaultSelection = this.getDefaultStateSelection({});
    defaultSelection.dayPartsSelected = [];
    if (this.props.isIntermediare) defaultSelection.dateSelected = this.state.dateSelected;
    this.setState(defaultSelection);
  };

  onChannelChange = (e) => {
    /*Filter Emissions Selected By Channels Selected */
    let channelIds = e.selected.map((i) => i.id);
    this.setState({
      allChannelsSelected: e.isAllSelected,
      channelsSelected: channelIds,
      emissionsSelected: this.state.emissionsSelected.filter((m) => channelIds.includes(m.channel?.id)),
    });
  };

  getEmissionsFetch = () => {
    return {
      StartDateSelected: this.state.dateSelected,
      EndDateSelected: this.state.dateSelected,
      ChannelsSelected: this.state.channelsSelected,
    };
  };

  isEmissionsDisabled = () => {
    let emissionsFetch = this.getEmissionsFetch();
    return emissionsFetch.ChannelsSelected.length === 0 || !emissionsFetch.StartDateSelected || !emissionsFetch.EndDateSelected;
  };

  render() {
    let emissionsDisabled = this.isEmissionsDisabled();
    let equalDates = moment(this.state.dateRanges?.startDate).startOf("day").isSame(moment(this.state.dateRanges?.endDate).startOf("day"));
    return (
      <StyledFilters className="filters">
        <BaseFilter loading={!this.state.loaded} isActive={this.props.isActive} onClear={this.onClear} onFilter={this.onFilter}>
          <BaseFilterSection sectionTitle={this.translation.filters.titleGrille}>
            {/* TO REPEAT - INPUT DATE*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              {this.props.isIntermediare && equalDates && (
                <Input center title={this.translation.filters.date} placeholder={this.translation.filters.date} defaultValue={this.state.dateSelected ? moment(this.state.dateSelected, Settings.moment.serverDate).format(Settings.moment.date) : null} disabled />
              )}
              {(!this.props.isIntermediare || !equalDates) && (
                <Calendar
                  selectedDate={this.state.dateSelected ? moment(this.state.dateSelected, Settings.moment.serverDate).format(Settings.moment.date) : null}
                  minDate={moment(this.state.dateRanges?.startDate, Settings.moment.serverDate)}
                  maxDate={moment(this.state.dateRanges?.endDate, Settings.moment.serverDate)}
                  onChangeSelection={(date) => {
                    this.setState({ dateSelected: date ? moment(date, Settings.moment.date).format(Settings.moment.serverDate) : null }, () => {
                      if (this.isEmissionsDisabled()) {
                        this.setState({ emissionsSelected: [] });
                      }
                    });
                  }}
                />
              )}
            </NanoFlex>

            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.channels}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.channels} selected={this.state.channelsSelected} onChangeSelection={this.onChannelChange} />
            </NanoFlex>

            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className={`formWrapper ${emissionsDisabled ? "disabledForm" : ""}`} flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.emissions}</Type.p>
              <FetchTreeNavigatorDropdown
                disabled={emissionsDisabled}
                placeholder="Placeholder"
                fetch={filtersService.getEmissions}
                fetchParams={this.getEmissionsFetch()}
                selected={this.state.emissionsSelected}
                onChangeSelection={(selected) => {
                  this.setState({ emissionsSelected: selected });
                }}
                minFetchLength={3}
              />
              {emissionsDisabled && (
                <NanoFlex className="disabledMessage" justifyContent="flex-start">
                  <Type.h6>{this.translation.filters.disabledEmissionsMessage}</Type.h6>
                </NanoFlex>
              )}
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.genreFTV}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.genres} selected={this.state.genresSelected} onChangeSelection={(e) => this.setState({ allGenresSelected: e.isAllSelected, genresSelected: e.selected.map((i) => i.id) })} />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.dayPart}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.dayParts} selected={this.state.dayPartsSelected} onChangeSelection={(e) => this.setState({ allDayPartsSelected: e.isAllSelected, dayPartsSelected: e.selected.map((i) => i.id) })} />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.gender}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.genders} selected={this.state.gendersSelected} onChangeSelection={(e) => this.setState({ allGendersSelected: e.isAllSelected, gendersSelected: e.selected.map((i) => i.id) })} />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.age}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.ages} selected={this.state.agesSelected} onChangeSelection={(e) => this.setState({ allAgesSelected: e.isAllSelected, agesSelected: e.selected.map((i) => i.id) })} />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.csp}</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" options={this.state.csp} selected={this.state.cspSelected} onChangeSelection={(e) => this.setState({ allCspSelected: e.isAllSelected, cspSelected: e.selected.map((i) => i.id) })} />
            </NanoFlex>

            {/* TO REPEAT - INPUT NUMBER RANGE*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <InputNumberRange title={this.translation.filters.duration} start={this.state.durationStart} end={this.state.durationEnd} onChange={(e) => this.setState({ durationStart: e.start, durationEnd: e.end })} />
            </NanoFlex>

            {/* TO REPEAT - INPUT NUMBER RANGE*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <InputNumberRange title={this.translation.filters.numberCorrespondants} start={this.state.correspondantsStart} end={this.state.correspondantsEnd} onChange={(e) => this.setState({ correspondantsStart: e.start, correspondantsEnd: e.end })} />
            </NanoFlex>
          </BaseFilterSection>
          {/* <BaseFilterSection sectionTitle={this.translation.filters.titleSearch}>
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">{this.translation.filters.date}</Type.p>
              <CalendarRange onChangeSelection={(obj) => console.log(obj)} />
            </NanoFlex>
            <NanoFlex className="formWrapper">
              <Input title={this.translation.global.title} placeholder={this.translation.global.search} />
            </NanoFlex>
          </BaseFilterSection> */}
        </BaseFilter>
      </StyledFilters>
    );
  }
}
