import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import administrationService from "../../../services/AdministrationService.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

//COMPONENTS
import { AdminNavBar } from "./AdminNavBar.js";
import Input from "../../Global/Elements/Forms/Input.js";

const StyledChaines = styled(NanoFlex)`
  min-width: 0;
  .chainesContent {
    min-width: 0;
    .contentWrapper {
      padding: 32px;
      .chainesList {
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        padding: 32px;

        overflow: auto;
        height: calc(100vh - 130px);
        .listWrapper {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));

          grid-template-rows: auto;
          grid-auto-flow: dense;
          height: auto;
          .itemChaine {
            background-color: ${(props) => props.theme.color.main.white};
            box-shadow: ${(props) => props.theme.boxShadow};
            padding: 32px;
            width: auto;
            height: auto;

            .logoChaine {
              margin-bottom: 32px;
              height: 48px;
              max-width: 96px;
              width: auto;
              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
            .formWrapper {
              height: auto;
            }
          }
        }
      }
    }
  }
`;

export class Chaines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      channels: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getChannels();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getChannels = () => {
    administrationService
      .getChannels()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ channels: data });
      });
  };

  render() {
    return (
      <StyledChaines>
        {this.state.channels === null && <Loading />}
        {this.state.channels && this.state.channels !== null && (
          <NanoFlex className="chainesContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <AdminNavBar render={this.props.render} />
            <NanoFlex className="contentWrapper">
              <NanoFlex className="chainesList" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
                <NanoFlex className="listWrapper" alignContent="flax-start">
                  {/* ITEM CHAINE */}
                  {this.state.channels.map((c) => (
                    <NanoFlex key={`channel_${c.id}`} className="itemChaine" flexDirection="column">
                      <NanoFlex className="logoChaine">
                        <img src={"/images/channels/" + c.icon} alt="Chaîne Logo" />
                      </NanoFlex>
                      <NanoFlex className="formWrapper">
                        <Input editable title="Chaîne" placeholder="Placeholder" defaultValue={c.name} />
                      </NanoFlex>
                    </NanoFlex>
                  ))}
                  {/* ITEM CHAINE */}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledChaines>
    );
  }
}
