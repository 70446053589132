/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";
import pivotService from "./../../../../services/PivotService";

//IMGS
import { ReactComponent as Close } from "../../icons/close.svg";
import { ReactComponent as Plus } from "../../icons/plus.svg";
import { ReactComponent as Minus } from "../../icons/minus.svg";

//COMPONENTS
import { SearchBar } from "../../Elements/Forms/SearchBar.js";
import ButtonCTA from "../../Elements/Buttons/ButtonCTA.js";

const StyledSelectVariablesModal = styled(NanoFlex)`
  width: 70vw;
  background-color: ${(props) => props.theme.color.main.white};
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
  user-select: none;
  .selectVariablesModalContent {
    .headerModal {
      position: relative;
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      padding: 16px 24px;

      h5 {
        text-transform: uppercase;
      }
      .closeBtn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        height: auto;
        width: auto;

        svg {
          width: 10px;
          height: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
    .contentModal {
      .topWrapper {
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        height: auto;
        padding: 16px;
      }
      .listWrapper {
        padding: 16px;
        max-height: 60vh;
        height: 60vh;
        overflow: auto;
        .listContainer {
          margin-bottom: 8px;
          height: auto;
          .headerList {
            background-color: ${(props) => props.theme.color.main.font};
            border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
            padding: 8px;
            height: auto;
            p {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.main.white};
            }
          }
          .listVariables {
            padding: 8px;
            display: grid;
            gap: 8px;
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
            grid-template-rows: auto;
            grid-auto-flow: dense;
            height: auto;
            .itemVariable {
              border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
              background-color: ${(props) => props.theme.color.main.white};
              width: auto;
              cursor: pointer;
              .itemTitle {
                width: auto;
                overflow: hidden;
                padding: 8px;
                p {
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              .itemIcon {
                width: auto;
                padding: 8px;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                svg {
                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
                .minusIcon {
                  display: none;
                }
              }

              &:hover {
                background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              }
            }
            .selectedItem {
              opacity: 0.6;
              .itemIcon {
                .minusIcon {
                  display: flex;
                }
                .plusIcon {
                  display: none;
                }
              }
            }
          }
          .listWithSubLists {
            grid-template-columns: repeat(auto-fill, minmax(calc(25% - 24px), 1fr));
            height: auto;
            .subListVariables {
              .headerSubList {
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                height: auto;
                border-bottom: 1px solid ${(props) => props.theme.color.main.font};
                cursor: pointer;
                .itemTitle {
                  width: auto;
                  overflow: hidden;
                  padding: 8px;
                  p {
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                }
                .itemIcon {
                  width: auto;
                  padding: 8px;
                  background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
                  svg {
                    path {
                      fill: ${(props) => props.theme.color.main.font};
                    }
                  }
                  .minusIcon {
                    display: none;
                  }
                }

                &:hover {
                  background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
                  .itemIcon {
                    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                  }
                }
              }
              .selectedItem {
                opacity: 0.6;
                .itemIcon {
                  .minusIcon {
                    display: flex;
                  }
                  .plusIcon {
                    display: none;
                  }
                }
              }
              .subListWrapper {
                height: auto;
                .itemVariable {
                  width: 100%;
                  margin-top: 4px;
                }
              }
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .noResultsWrapper {
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          h3 {
            text-transform: uppercase;
            color: ${(props) => props.theme.color.colorsPalette.grey};
          }
        }
      }
    }
    .bottomWrapper {
      padding: 24px;
      height: auto;
      border-top: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
      .cta {
        max-width: 200px;
      }
    }
  }
`;

export class SelectVariablesModal extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      results: null,
      selectedVariables: this.props.selectedVariables ?? [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.getPivotColumnsLines();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedVariables) !== JSON.stringify(this.props.selectedVariables)) {
      this.setState({ selectedVariables: this.props.selectedVariables ?? [] });
    }
    if (JSON.stringify(prevState.selectedVariables) !== JSON.stringify(this.state.selectedVariables) && this.props.onSelectedChange) {
      this.props.onSelectedChange(this.state.selectedVariables);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCancel = () => {
    if (this.props.cancelAction) this.props.cancelAction();
  };

  onNext = () => {
    if (this.props.nextAction) this.props.nextAction();
  };

  onToggle = (obj) => {
    if (this.state.selectedVariables.map((v) => v.name).includes(obj.name)) {
      this.setState({ selectedVariables: this.state.selectedVariables.filter((v) => v.name !== obj.name) });
    } else {
      this.setState({ selectedVariables: [...this.state.selectedVariables, obj] });
    }
  };

  onGroupToggle = (objList, allSelected) => {
    let objListName = objList.map((v) => v.name);
    let filteredSelected = this.state.selectedVariables.filter((v) => !objListName.includes(v.name));
    if (allSelected) {
      this.setState({ selectedVariables: filteredSelected });
    } else {
      this.setState({ selectedVariables: [...filteredSelected, ...objList] });
    }
  };

  getPivotColumnsLines = () => {
    pivotService
      .getPivotColumnsLines()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ results: data });
      });
  };

  getFilteredResults = () => {
    if (this.state.term.trim() !== "") {
      let immutableResults = JSON.parse(JSON.stringify(this.state.results));

      let upperTerm = this.state.term.trim().toUpperCase();
      immutableResults.variables = immutableResults.variables.filter((v) => {
        let upperName = v.name.trim().toUpperCase();
        return upperName.includes(upperTerm);
      });

      return immutableResults;
    } else {
      return this.state.results;
    }
  };

  render() {
    let columnsLines = this.getFilteredResults();
    let selectedVariablesNames = this.state.selectedVariables.map((m) => m.name);

    return (
      <StyledSelectVariablesModal justifyContent="flex-start">
        <NanoFlex className="selectVariablesModalContent" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="headerModal">
            <Type.h5>{this.translation.global.variables}</Type.h5>
            <NanoFlex className="closeBtn" onClick={this.onCancel}>
              <Close />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="contentModal" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="topWrapper" justifyContent="flex-start">
              <SearchBar term={this.state.term} onTermChange={(v) => this.setState({ term: v })} />
            </NanoFlex>
            <NanoFlex className="listWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {columnsLines?.variables?.length === 0 && (
                <NanoFlex className="noResultsWrapper">
                  <Type.h3>
                    <b>{this.translation.global.noResults}</b>
                  </Type.h3>
                </NanoFlex>
              )}
              {columnsLines &&
                columnsLines.variables.length > 0 &&
                columnsLines.variableTypes &&
                columnsLines.variableTypes.map((vt) => {
                  let subTypes = columnsLines.variableSubTypes?.filter((f) => f.typeCode === vt.code) ?? [];
                  let subTypesCount = subTypes.filter((vst) => columnsLines.variables?.filter((f) => f.type === vst.typeCode && f.subType === vst.code).length > 0).length;
                  let variables = columnsLines.variables?.filter((f) => f.type === vt.code /* && !selectedVariablesNames.includes(f.name)*/) ?? [];
                  if ((subTypes.length === 0 || subTypesCount === 0) && variables.length === 0) return null;
                  return (
                    <NanoFlex className="listContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" key={`variableType_${vt.name}}`}>
                      <NanoFlex className="headerList">
                        <Type.p>
                          <b>{vt.name}</b>
                        </Type.p>
                      </NanoFlex>

                      {subTypes.length === 0 && (
                        <NanoFlex className="listVariables">
                          {variables.map((v) => {
                            return (
                              <NanoFlex key={`variable_${v.name}`} className={`itemVariable ${selectedVariablesNames.includes(v.name) ? "selectedItem" : ""}`} justifyContent="space-between" onClick={() => this.onToggle(v)}>
                                <NanoFlex className="itemTitle" justifyContent="flex-start" alignItems="flex-start">
                                  <Type.p title={v.name}>{v.name}</Type.p>
                                </NanoFlex>
                                <NanoFlex className="itemIcon">
                                  <Plus className="plusIcon" />
                                  <Minus className="minusIcon" />
                                </NanoFlex>
                              </NanoFlex>
                            );
                          })}
                        </NanoFlex>
                      )}

                      {subTypes.length > 0 && (
                        <NanoFlex className="listVariables listWithSubLists">
                          {subTypes.map((vst) => {
                            let subVariables = columnsLines.variables?.filter((f) => f.type === vst.typeCode && f.subType === vst.code /*&& !selectedVariablesNames.includes(f.name)*/) ?? [];
                            if (subVariables.length === 0) return null;
                            let allSubVariablesSelected = subVariables.filter((f) => !selectedVariablesNames.includes(f.name)).length === 0;
                            return (
                              <NanoFlex key={`variableSubType_${vst.name}`} className="subListVariables" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                <NanoFlex className={`headerSubList ${allSubVariablesSelected ? "selectedItem" : ""}`} justifyContent="space-between" onClick={() => this.onGroupToggle(subVariables, allSubVariablesSelected)}>
                                  <NanoFlex className="itemTitle" justifyContent="flex-start" alignItems="flex-start">
                                    <Type.p>
                                      <b title={vst.name}>{vst.name}</b>
                                    </Type.p>
                                  </NanoFlex>
                                  <NanoFlex className="itemIcon">
                                    <Plus className="plusIcon" />
                                    <Minus className="minusIcon" />
                                  </NanoFlex>
                                </NanoFlex>
                                <NanoFlex className="subListWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                                  {subVariables.map((sv) => {
                                    return (
                                      <NanoFlex key={`subVariable${sv.name}`} className={`itemVariable ${selectedVariablesNames.includes(sv.name) ? "selectedItem" : ""}`} justifyContent="space-between" onClick={() => this.onToggle(sv)}>
                                        <NanoFlex className="itemTitle" justifyContent="flex-start" alignItems="flex-start">
                                          <Type.p title={sv.name}>{sv.name}</Type.p>
                                        </NanoFlex>
                                        <NanoFlex className="itemIcon">
                                          <Plus className="plusIcon" />
                                          <Minus className="minusIcon" />
                                        </NanoFlex>
                                      </NanoFlex>
                                    );
                                  })}
                                </NanoFlex>
                              </NanoFlex>
                            );
                          })}
                        </NanoFlex>
                      )}
                    </NanoFlex>
                  );
                })}
            </NanoFlex>
          </NanoFlex>

          <NanoFlex className="bottomWrapper">
            <ButtonCTA label={this.translation.librairies.add} clickAction={this.onNext} />
          </NanoFlex>
        </NanoFlex>
      </StyledSelectVariablesModal>
    );
  }
}
