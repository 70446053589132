/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";

//Imgs
import { ReactComponent as ArrowLeft } from "../../Global/icons/arrowLeft.svg";
import Clean from "../../Global/icons/clean.svg";

// COMPONENTS
import Loading from "../../Global/Elements/Loading/Loading.js";
import ButtonWithIcon from "../../Global/Elements/Buttons/ButtonWithIcon.js";
import ButtonCTA from "../../Global/Elements/Buttons/ButtonCTA.js";

const StyledBaseFilter = styled(NanoFlex)`
  width: 320px;
  transition: ${(props) => props.theme.transition};
  .baseFilterWrapper {
    box-shadow: ${(props) => props.theme.boxShadow};
    transition: ${(props) => props.theme.transition};
    background-color: ${(props) => props.theme.color.main.white};
    .topWrapper {
      .headerWrapper {
        background-color: ${(props) => props.theme.color.main.green};
        height: auto;
        padding: 16px;
        h5 {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.main.white};
        }
      }
      .filtersSection {
        height: calc(100vh - 220px);
        overflow: auto;
      }
    }
    .bottomWrapper {
      height: auto;

      .cleanFiltersWrapper {
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        padding: 16px;
      }
      .filterBtn {
        padding: 16px;
      }
      .collapseBtn {
        cursor: pointer;
        padding: 8px 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        svg {
          width: auto;
          height: 14px;
          transform: rotate(${(props) => (props.isCollapsed ? "180deg" : "0")});
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
      }
    }
  }
`;

export class BaseFilter extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("triggerFilterToggle", this.onToggle, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isOpen !== prevState.isOpen || this.props.isActive !== prevProps.isActive) {
      this.triggerOnFilterToggle();
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.addEventListener("triggerFilterToggle", this.onToggle, false);
  }

  triggerOnFilterToggle = () => {
    window.dispatchEvent(
      new CustomEvent("onFilterToggle", {
        detail: {
          isOpen: this.state.isOpen,
          isActive: this.props.isActive ?? false,
        },
      })
    );
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    if (!this.state.isOpen) return null;

    return (
      <StyledBaseFilter className="baseFilter">
        <NanoFlex className="baseFilterWrapper" flexDirection="column" justifyContent="space-between">
          <NanoFlex className="topWrapper" flexDirection="column" justifyContent="flex-start">
            <NanoFlex className="headerWrapper">
              <Type.h5>
                <b>{this.translation.global.filtres}</b>
              </Type.h5>
            </NanoFlex>
            {this.props.loading && <Loading />}
            {!this.props.loading && (
              <NanoFlex className="filtersSection" flexDirection="column" justifyContent="flex-start">
                {this.props.children}
              </NanoFlex>
            )}
          </NanoFlex>
          <NanoFlex className="bottomWrapper" flexDirection="column">
            {!this.props.loading && (
              <NanoFlex className="cleanFiltersWrapper" justifyContent="flex-end">
                <ButtonWithIcon label={this.translation.global.effacer} src={Clean} clickAction={this.props.onClear} />
              </NanoFlex>
            )}
            {!this.props.loading && (
              <NanoFlex className="filterBtn">
                <ButtonCTA label={this.translation.global.filtrer} clickAction={this.props.onFilter} />
              </NanoFlex>
            )}
            <NanoFlex className="collapseBtn" onClick={this.onToggle}>
              <ArrowLeft />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledBaseFilter>
    );
  }
}
