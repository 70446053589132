import React from "react";
import styled from "styled-components";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

// COMPONENTS
import Input from "../../Global/Elements/Forms/Input.js";
import SingleSelectionDropdown from "../../Global/Elements/Forms/SingleSelectionDropdown.js";
import MultipleSelectionDropdown from "../../Global/Elements/Forms/MultipleSelectionDropdown.js";
import FetchMultipleSelectionDropdown from "../../Global/Elements/Forms/FetchMultipleSelectionDropdown.js";
import CalendarRange from "../../Global/Elements/Forms/CalendarRange.js";
import Calendar from "../../Global/Elements/Forms/Calendar.js";
import InputNumberRange from "../../Global/Elements/Forms/InputNumberRange.js";
import TreeNavigatorDropdown from "../../Global/Elements/Forms/TreeNavigatorDropdown.js";
import Loading from "../../Global/Elements/Loading/Loading.js";
import ButtonCTA from "../../Global/Elements/Buttons/ButtonCTA.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { SelectVariablesModal } from "../../Global/Elements/Modals/SelectVariablesModal.js";
import { SaveReportModal } from "../../Global/Elements/Modals/SaveReportModal.js";
import { SearchBar } from "../../Global/Elements/Forms/SearchBar.js";
import { DateFormat } from "../../Global/Elements/Forms/DateFormat.js";
import SwitchEmissionDiffusion from "../../Global/Elements/Buttons/SwitchEmissionDiffusion.js";

// SERVICES
import filtersService from "../../../services/FiltersService.js";

const StyledGuidelines = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.colorsPalette.grey};
  padding-top: 30px;
  height: auto;
  .guidelinesWrapper {
    width: 320px;
    height: auto;
    background-color: ${(props) => props.theme.color.main.white};
    padding: 16px;
    .formContainer {
      height: auto;
      .formWrapper {
        margin-bottom: 12px;
        .titleForm {
          margin-bottom: 4px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      .formAside {
        margin-bottom: 12px;
        .titleForm {
          margin-bottom: 4px;
        }
        .formsContainers {
          .formWrapper {
            margin-bottom: 0px;
            margin-right: 8px;
            .inputContainer {
              min-width: auto;
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export class Guidelines extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      selectedDate: null,
      openSelectVariables: false,
      openSaveReport: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledGuidelines flexDirection={"column"} justifyContent={"flex-start"}>
        <NanoFlex className="guidelinesWrapper">
          <NanoFlex className="formContainer" flexDirection="column">
            {/* TO REPEAT - INPUT*/}
            <NanoFlex className="formWrapper">
              <Input title="Label Input" placeholder="Placeholder" />
            </NanoFlex>
             {/* TO REPEAT - INPUT DISABLED */}
             <NanoFlex className="formWrapper">
              <Input title="Label Input" placeholder="Placeholder" disabled/>
            </NanoFlex>
            {/* TO REPEAT - INPUT EDITABLE */}
            <NanoFlex className="formWrapper">
              <Input editable title="Label Input" placeholder="Placeholder" />
            </NanoFlex>
            {/* TO REPEAT - SINGLE SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Single Selection</Type.p>
              <SingleSelectionDropdown
                placeholder="Placeholder"
                options={[
                  { id: 1, name: "teste" },
                  { id: 2, name: "teste2" },
                ]}
              />
            </NanoFlex>
            {/* TO REPEAT - SINGLE SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Single Selection</Type.p>
              <SingleSelectionDropdown
                placeholder="Placeholder"
                options={[
                  { id: 1, name: "teste" },
                  { id: 2, name: "teste 2" },
                  { id: 3, name: "teste 3" },
                  { id: 4, name: "teste 4" },
                  { id: 5, name: "teste 5" },
                  { id: 6, name: "teste 6" },
                  { id: 7, name: "teste maior texto" },
                  { id: 8, name: "teste super comprido que pode ocupar muito espaço" },
                  { id: 9, name: "teste 9" },
                ]}
              />
            </NanoFlex>
            {/* TO REPEAT - SINGLE SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Single Selection</Type.p>
              <SingleSelectionDropdown title="Label Input" placeholder="Placeholder" />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Multi Selection</Type.p>
              <MultipleSelectionDropdown placeholder="Placeholder" />
            </NanoFlex>
            {/* TO REPEAT - MULTI SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Multi Selection</Type.p>
              <MultipleSelectionDropdown
                placeholder="Placeholder"
                options={[
                  { id: 1, name: "teste" },
                  { id: 2, name: "teste 2" },
                  { id: 3, name: "teste 3" },
                  { id: 4, name: "teste 4" },
                  { id: 5, name: "teste 5" },
                  { id: 6, name: "teste 6" },
                  { id: 7, name: "teste maior texto" },
                  { id: 8, name: "teste super comprido que pode ocupar muito espaço" },
                  { id: 9, name: "teste 9" },
                ]}
              />
            </NanoFlex>
            {/* TO REPEAT - FETCH MULTI SELECTION DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Fetch Multi Selection</Type.p>
              <FetchMultipleSelectionDropdown placeholder="Placeholder" fetch={filtersService.getEmissions} fetchParams={{ StartDate: "2021-06-29", EndDate: "2021-06-29" }} minFetchLength={3} />
            </NanoFlex>
            {/* TO REPEAT - FETCH MULTI SELECTION DROPDOWN*/}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Title Fetch Tree Navigator</Type.p>
              {/* <FetchTreeNavigatorDropdown placeholder="Placeholder" fetch={filtersService.getEmissions} fetchParams={{ StartDate: "2021-06-29", EndDate: "2021-06-29" }} minFetchLength={3} /> */}
            </NanoFlex>
            {/* TO REPEAT - TREE NAVIGATOR DROPDOWN */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <TreeNavigatorDropdown
                title="Tree Navigator Dropdown"
                options={[
                  {
                    id: 1,
                    name: "Selected",
                    childs: [
                      { id: 1, name: "Opt 1" },
                      { id: 2, name: "Opt 2" },
                    ],
                  },
                  {
                    id: 2,
                    name: "Search",
                    childs: [
                      { id: 3, name: "Opt 3" },
                      { id: 4, name: "Opt 4" },
                      { id: 5, name: "Opt 4" },
                      { id: 6, name: "Opt 4" },
                    ],
                  },
                ]}
              />
            </NanoFlex>
            {/* FORMS ASIDE - INPUT */}
            <NanoFlex className="formAside" flexDirection="column" alignItems="flex-start">
              <NanoFlex className="formsContainers">
                {/* TO REPEAT - INPUT */}
                <NanoFlex className="formWrapper">
                  <Input title="Label Input" placeholder="Placeholder" />
                </NanoFlex>
                {/* TO REPEAT - INPUT */}
                <NanoFlex className="formWrapper">
                  <Input title="Label Input" placeholder="Placeholder" />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* TO REPEAT - INPUT RANGE DATE */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Type.p className="titleForm">Date</Type.p>
              <CalendarRange onChangeSelection={(obj) => console.log(obj)} />
            </NanoFlex>
            {/* TO REPEAT - INPUT DATE */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Calendar selectedDate={this.state.selectedDate} onChangeSelection={(obj) => console.log(obj)} />
            </NanoFlex>
            {/* TO REPEAT - INPUT NUMBER RANGE */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <InputNumberRange title="Title" />
            </NanoFlex>
            {/* TO REPEAT - MODAL VARIABLES */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <ButtonCTA label="Select Variables" clickAction={() => this.setState({ openSelectVariables: true })} />
              <Modal show={this.state.openSelectVariables} content={<SelectVariablesModal cancelAction={() => this.setState({ openSelectVariables: false })} nextAction={() => this.setState({ openSelectVariables: false })} />} />
            </NanoFlex>
            {/* TO REPEAT - MODAL SAVE REPORT */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <ButtonCTA label="Save Report" clickAction={() => this.setState({ openSaveReport: true })} />
              <Modal show={this.state.openSaveReport} content={<SaveReportModal cancelAction={() => this.setState({ openSaveReport: false })} nextAction={() => this.setState({ openSaveReport: false })} />} />
            </NanoFlex>
            {/* TO REPEAT - INPUT NUMBER RANGE */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <SearchBar />
            </NanoFlex>
            {/* LOADING */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <Loading />
            </NanoFlex>

             {/* DATE FORMAT */}
             <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <DateFormat />
            </NanoFlex>

            {/* SWITCH */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <SwitchEmissionDiffusion emission={false} diffusion={true}/>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledGuidelines>
    );
  }
}
