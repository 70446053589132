import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";
import palmaresService from "../../../../services/PalmaresService";
import { downloadBlob } from "../../../Global/tools.js";

// COMPONENTS
import { Filters } from "../../Reports/Dynamique/Filters.js";
import { RecapFilters } from "./../Grid/RecapFilters.js";
import { OptionsFilters } from "./OptionsFilters.js";
import { HeaderListBlock } from "./Elements/Blocks/HeaderListBlock.js";
import { ListPalmares } from "./Elements/ListPalmares.js";
import { LegendPalmares } from "./Elements/LegendPalmares.js";
// import { ShareBlock } from "./Elements/Blocks/ShareBlock.js";
// import { SocioDemographicsBlock } from "./Elements/Blocks/SocioDemographicsBlock.js";
// import { BestChannelsBlock } from "./Elements/Blocks/BestChannelsBlock.js";
// import { BestProgramsBlock } from "./Elements/Blocks/BestProgramsBlock.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";

const StyledPalmares = styled(NanoFlex)`
  min-width: 0;
  .palmaresContent {
    padding: 32px;
    overflow: auto;
    .contentWrapper {
      .rowWrapper {
        height: auto;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .totalHeight {
        height: 100%;
      }
    }
  }
`;

export class Palmares extends BaseComponent {
  constructor(props, prevProps) {
    super(props, prevProps);
    let prevFilters = null

    if (window.location.pathname === sessionStorage.getItem("lastPathname") || sessionStorage.getItem("lastPathname") === "/librairies") {
      prevFilters = JSON.parse(sessionStorage.getItem("filtersByReport"));
    }

    this.state = {
      filter: prevFilters ?? null,
      top: prevFilters?.top ?? 50,
      showRespondants: prevFilters?.viewDetails ?? true,
      report: prevFilters?.report ?? 6,
      programs: null,
    };

    sessionStorage.setItem("lastPathname", window.location.pathname)
  }

  componentDidMount() {
    super.componentDidMount();

    if (this.state.filter) {
      this.getPrograms();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter) || prevState.report !== this.state.report) {
      if (this.state.filter) {
        this.getPrograms();
      } else {
        this.setState({ programs: null });
      }
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilter = () => {
    return {
      ...this.state.filter,
      ...{ top: this.state.top, viewDetails: this.state.showRespondants, report: this.state.report },
      ...{ filterRecap: this.getTranslatedRecapFilters(this.state.filter?.filterRecap) },
    };
  };

  getPrograms = () => {
    palmaresService
      .getPalmaresReport(this.getFilter())
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ programs: data });
        //sessionStorage.removeItem("filtersByReport");
        sessionStorage.removeItem("jsonLinesByReport");
        sessionStorage.removeItem("jsonColumnsByReport");
      });
  };

  onPdfExport = () => {
    this.setState({ loading: true }, () => {
      palmaresService
        .getPalmaresPdf({
          ...this.getFilter(),
          ...{
            top: this.state.top,
            report: this.state.report,
          },
        })
        .then((response) => {
          if (response.ok) {
            return response.blob();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((blob) => {
          if (blob) {
            downloadBlob(blob, `Report-Palmares.pdf`);
          }
          this.setState({ loading: false });
        });
    });
  };

  render() {
    let loading = !this.state.filter || this.state.loading;

    return (
      <StyledPalmares className="palmares" alignItems="flex-start">
        {!this._isExport && <Filters filtersSelected={this.getFilter()} onFilterChange={(f) => this.setState({ filter: f })} isActive={this.state.filter ? true : false} isPalmares />}
        <NanoFlex className="palmaresContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          {loading && (
            <NanoFlex className="contentWrapper">
              <Loading />
            </NanoFlex>
          )}
          {!loading && (
            <NanoFlex className="contentWrapper palmaresWrapper" flexDirection="column" justifyContent="flex-start">
              {!this._isExport && <RecapFilters selectedFilters={this.getFilter()} />}

              {!this._isExport && (
                <OptionsFilters
                  hasData={this.state.programs?.length > 0}
                  filtersSelected={this.getFilter()}
                  top={this.state.top}
                  onTopChange={(t) => this.setState({ top: t })}
                  showRespondants={this.state.showRespondants}
                  onShowRespondants={(t) => this.setState({ showRespondants: t })}
                  onPdfExport={this.onPdfExport}
                />
              )}
              <NanoFlex className="rowWrapper" alignItems="flex-start">
                <HeaderListBlock hasData={this.state.programs?.length > 0} report={this.state.report} onReportChange={(r) => this.setState({ report: r })} />
              </NanoFlex>
              {this._isExport && (
                <NanoFlex className="rowWrapper exportRecapFilters" alignItems="flex-start">
                  <RecapFilters selectedFilters={this.state.filter} />
                </NanoFlex>
              )}
              <NanoFlex className="rowWrapper totalHeight">
                <ListPalmares programs={this.state.programs} filtersSelected={this.getFilter()} top={this.state.top} showRespondants={this.state.showRespondants} report={this.state.report} />
              </NanoFlex>
              <NanoFlex className="rowWrapper legendPalmaresPDF">
                <LegendPalmares />
              </NanoFlex>
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledPalmares>
    );
  }
}
