/* eslint-disable react/jsx-pascal-case */
import React from "react";
import moment from "moment";
import Settings from "../../Global/Settings.json";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex";
import Type from "../../Global/Typography.js";
import librariesService from "../../../services/LibrariesService";
import { elementMatchesAny } from "../../Global/tools.js";

//IMGS
import Delete from "../../Global/icons/delete.svg";

// COMPONENTS
import ButtonIcon from "../../Global/Elements/Buttons/ButtonIcon.js";
import Checkbox from "../../Global/Elements/Forms/Checkbox.js";
import Modal from "../../Global/Elements/Modals/Modal.js";
import { DeleteModal } from "../../Global/Elements/Modals/DeleteModal.js";

const StyledTableLibrairies = styled(NanoFlex)`
  min-width: 0;
  padding: 32px;
  .tableLibrairiesWrapper {
    min-width: 0;
    position: relative;
    user-select: none;
    .headerTitleTable {
      height: auto;
      margin-bottom: 16px;
      .headerAside {
        width: auto;
      }
    }
    .tableContainer {
      border: 1px solid ${(props) => props.theme.color.main.font};
      overflow: hidden;
      .headerTable {
        padding: 16px 8px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        height: auto;
        box-shadow: ${(props) => props.theme.boxShadow};
        position: sitcky;
        z-index: 1;
        .headerCol {
          p {
            text-transform: uppercase;
          }
        }
      }
      .tableListWrapper {
        overflow: auto;
        padding-top: 4px;
        .lineTable {
          padding: 8px;
          height: auto;

          .lineCol {
            height: auto;
            overflow: hidden;
            p {
              /* width: 100%; */
              width: calc(100% - 16px);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          &:nth-child(even) {
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
          }
        }
      }
      .xs {
        width: 48px;
      }
      .s {
        flex: 2;
      }
      .m {
        flex: 4;
      }
    }
  }
  .noResults {
    padding: 32px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    h3 {
      text-transform: uppercase;
      color: ${(props) => props.theme.color.colorsPalette.grey};
    }
  }
`;

export class TableLibrairies extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: [],
      reportSelected: {},
      openDeleteModal: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.props.folder) !== JSON.stringify(prevProps.folder)) {
      this.setState({ selectedIds: [] });
    }

    if (prevState.redirect !== this.state.redirect) {
      this.setState({ redirect: null });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onCheckClick = (v) => {
    if (this.state.selectedIds.includes(v)) {
      this.setState({
        selectedIds: this.state.selectedIds.filter((id) => {
          return id !== v;
        }),
      });
    } else {
      this.setState({ selectedIds: [...this.state.selectedIds, ...[v]] });
    }
  };

  onDelete = () => {
    librariesService
      .deleteReports(this.state.selectedIds)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        if (this.props.refetchFolders) this.props.refetchFolders();
        this.setState({ selectedIds: [], openDeleteModal: false });
      });
  };

  onReportClick = (id, e) => {
    if (elementMatchesAny(e.target, [".checkCol"])) return;
    this.props.onReportClick(id);
  };

  render() {
    return (
      <StyledTableLibrairies>
        {!this.props.folder && (
          <NanoFlex className="noResults">
            <Type.h3>
              <b>{this.translation.global.noResults}</b>
            </Type.h3>
          </NanoFlex>
        )}
        {this.props.folder && (
          <NanoFlex className="tableLibrairiesWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <NanoFlex className="headerTitleTable" justifyContent="space-between" alignItems="flex-start">
              <NanoFlex className="headerAside" justifyContent="flex-start">
                <Type.h4>
                  <b>{this.props.folder.description}</b>
                </Type.h4>
              </NanoFlex>
              <NanoFlex className="headerAside" justifyContent="flex-end">
                <NanoFlex className="optionBtnWrapper">
                  <ButtonIcon src={Delete} disabled={this.state.selectedIds.length === 0} clickAction={() => this.setState({ openDeleteModal: true })} />
                  <Modal show={this.state.openDeleteModal} content={<DeleteModal title={this.translation.global.delete} message={this.translation.librairies.messageDeleteItem} cancelAction={() => this.setState({ openDeleteModal: null })} nextAction={this.onDelete} />} />
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            {/* TABLE */}
            <NanoFlex className="tableContainer" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* HEADER TABLE */}
              <NanoFlex className="headerTable" justifyContent="flex-start" alignItems="flex-start">
                <NanoFlex className="headerCol xs"></NanoFlex>
                <NanoFlex className="headerCol s" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.librairies.user}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="headerCol m" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.librairies.reportName}</b>
                  </Type.p>
                </NanoFlex>
                <NanoFlex className="headerCol s" justifyContent="flex-start">
                  <Type.p>
                    <b>{this.translation.librairies.creationDate}</b>
                  </Type.p>
                </NanoFlex>
              </NanoFlex>
              {/* END HEADER TABLE */}
              {/* NO RESULTS TABLE LIST */}
              {(!this.props.folder.reports || this.props.folder.reports.length === 0) && (
                <NanoFlex className="noResults">
                  <Type.h3>
                    <b>{this.translation.global.noResults}</b>
                  </Type.h3>
                </NanoFlex>
              )}
              {/* END NO RESULTS TABLE LIST */}
              {/* TABLE LIST */}
              {this.props.folder.reports && this.props.folder.reports.length > 0 && (
                <NanoFlex className="tableListWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  {/* LINE */}
                  {this.props.folder.reports.map((r) => (
                    <NanoFlex key={"folder_item_" + r.id} className="lineTable" onClick={(e) => this.onReportClick(r.id, e)}>
                      <NanoFlex className="lineCol xs checkCol">
                        <Checkbox toggle={this.state.selectedIds.includes(r.id)} onChangeAction={(id) => this.onCheckClick(r.id)} />
                      </NanoFlex>
                      <NanoFlex className="lineCol s" justifyContent="flex-start">
                        <Type.p>{r.username}</Type.p>
                      </NanoFlex>
                      <NanoFlex className="lineCol m" justifyContent="flex-start">
                        <Type.p>{r.name}</Type.p>
                      </NanoFlex>
                      <NanoFlex className="lineCol s" justifyContent="flex-start">
                        <Type.p>{moment(r.creationDate).format(Settings.moment.dateTime)}</Type.p>
                      </NanoFlex>
                    </NanoFlex>
                  ))}
                  {/* END LINE */}
                </NanoFlex>
              )}
              {/* END TABLE LIST */}
            </NanoFlex>
            {/* END TABLE */}
          </NanoFlex>
        )}
      </StyledTableLibrairies>
    );
  }
}
