import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import NanoFlex from "./../../../Global/NanoFlex.js";
import TimeRange from "react-timeline-range-slider";

// COMPONENTS

const StyledTimeline = styled(NanoFlex)`
  height: auto;
  margin-bottom: 16px;
  user-select: none;
  .react_time_range__time_range_container {
    width: 100%;
    padding: 25px 0 0 0;
    .react_time_range__rail__inner {
      background-color: ${(props) => props.theme.color.main.font};
    }
    .react_time_range__track {
      background: linear-gradient(269.99deg, rgba(0, 34, 139, 0.5) 0.01%, rgba(0, 227, 255, 0.5) 99.54%);
      border-left: 1px solid ${(props) => props.theme.color.main.white} !important;
      border-right: 1px solid ${(props) => props.theme.color.main.white} !important;
    }
    .react_time_range__handle_wrapper {
      cursor: grab;
    }
    .react_time_range__handle_marker {
      background: ${(props) => props.theme.color.main.font};
    }
  }
`;

export class Timeline extends Component {
  render() {
    let timelineInterval = this.props.timelineInterval;
    if (!timelineInterval) {
      let start = moment(this.props.selectedInterval[0] ?? new Date()).startOf("day");
      timelineInterval = [start.toDate(), start.startOf("day").add("day", 1).toDate()];
    }

    return (
      <StyledTimeline>
        <TimeRange ticksNumber={36} step={900000} selectedInterval={this.props.selectedInterval} timelineInterval={timelineInterval} onChangeCallback={this.props.onChangeCallback ?? (() => {})} onUpdateCallback={this.props.onUpdateCallback ?? (() => {})} error={this.props.error} />
      </StyledTimeline>
    );
  }
}
