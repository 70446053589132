/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import pivotService from "./../../../../services/PivotService";
import { Redirect } from "react-router-dom";

// COMPONENTS
import UsersBlock from "../../../Global/Elements/Blocks/UsersBlock.js";
import BaseComponent from "../../../BaseComponent.js";

const StyledEmissionHeader = styled(NanoFlex)`
  height: auto;
  width: auto;
  position: sticky;
  top: 0px;
  user-select: none;
  border-top: 4px solid ${(props) => props.theme.color.colorsPalette.lighterGrey};
  .headerWrapper {
    width: 240px;
    height: 72px;
    background-color: ${(props) => props.theme.color.main.white};
    padding: 8px;
    box-shadow: ${(props) => props.theme.boxShadow};
    .logoWrapper {
      max-height: 48px;
      max-width: 96px;
      width: auto;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .infoHeader {
      width: auto;
      .elementWrapper {
        height: auto;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export default class EmissionHeader extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onEmissionHeaderClick = (id, type) => {
    pivotService
      .getPivotSettings(type)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        //Set pivot configuration to load
        let settings = JSON.parse(data);
        sessionStorage.setItem("jsonLinesByReport", JSON.stringify(settings[1]));
        sessionStorage.setItem("jsonColumnsByReport", JSON.stringify(settings[0]));
        //Set emission selected to load
        this.props.filtersSelected.channelsSelected.push(parseInt(id));
        sessionStorage.setItem("filtersByReport", JSON.stringify(this.props.filtersSelected));
        //Navigate to page
        this.setState({ redirect: "/Dynamique" });
      });
  };

  render() {
    let emission = this.props.data;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <StyledEmissionHeader>
        <NanoFlex className="headerWrapper" justifyContent="space-between" onClick={(id) => this.onEmissionHeaderClick(emission.id, "ProgrammesTVSoiree")}>
          <NanoFlex className="logoWrapper">
            <img src={"/images/channels/" + emission.icon} title={emission.name} alt="" />
          </NanoFlex>
          <NanoFlex className="infoHeader" flexDirection="column" alignItems="flex-end">
            <NanoFlex className="elementWrapper" justifyContent="flex-end">
              <Type.h6>
                {this.translation.global.noteQualiTV} <b>{emission.score.value}</b>
              </Type.h6>
            </NanoFlex>
            <NanoFlex className="elementWrapper" justifyContent="flex-end">
              <UsersBlock red={emission.score.sample < 60} number={emission.score.sample} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledEmissionHeader>
    );
  }
}
