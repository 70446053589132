import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

import { ReactComponent as Star } from "../../icons/star.svg";

const StyledToggleButtonAdmin = styled(NanoFlex)`
  height: auto;
  width: auto;
  .toggleButtonAdminWrapper {
    padding: 8px;
    user-select: none;
    cursor: pointer;
    background-color: ${(props) => (props.active ? props.theme.color.colorsPalette.yellow : props.theme.color.colorsPalette.lightGrey)};
    .iconBtnWrapper {
      width: auto;
      margin-right: 8px;
      svg {
        width: 18px;
        height: auto;
        path {
          fill: ${(props) => (props.active ? props.theme.color.main.font : props.theme.color.main.font)};
        }
      }
    }
    .textBtnWrapper {
      width: auto;
      p {
        margin-top: 2px;
        /* color: ${(props) => (props.active ? props.theme.color.main.white : props.theme.color.main.font)}; */
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default class ToggleButtonAdmin extends BaseComponent {
  
  render() {
    return (
      <StyledToggleButtonAdmin flexDirection="column" active={this.props.active} onClick={this.props.clickAction}>
        <NanoFlex className="toggleButtonAdminWrapper">
          <NanoFlex className="iconBtnWrapper">
            <Star />
          </NanoFlex>
          <NanoFlex className="textBtnWrapper">
            <Type.p>{this.translation.global.admin}</Type.p>
          </NanoFlex>
        </NanoFlex>
      </StyledToggleButtonAdmin>
    );
  }
}
