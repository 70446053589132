import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import ListItem from "./ListItem";

const StyledDnD = styled(NanoFlex)`
height: auto;
  .dragContainer {
    width: auto;
    height: fit-content;
    padding: 16px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
    border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    margin: 8px;
    border-radius: 60px;
    transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
    overflow-anchor: none;
    .dragItem {
      width: fit-content;
      height: fit-content;
      background-color: ${(props) => props.theme.color.gradientGreen.green03};
      padding: 8px 16px;
      margin: 8px auto;
      border-radius: 60px;
      user-select: none;
      .item {

      }
    }
  }
`;

export class DnD extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      items: [
        [
          { id: 1, content: "Entierement" },
          { id: 2, content: "Évoquant" },
          { id: 3, content: "Évoqué" },
          { id: 4, content: "Largement" },
        ],
        [
          { id: 5, content: "Stghfbdgh" },
          { id: 6, content: "Vraiement" },
          { id: 7, content: "Régulièrement" },
          { id: 8, content: "eight" },
        ],
        [
          { id: 9, content: "Ième" },
          { id: 10, content: "Dle" },
          { id: 11, content: "seven" },
          { id: 12, content: "Bonjour" },
        ],
        [{ id: 13, content: "Apparemment" }],
      ],
    };
  }
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onDragEnd = (result) => {
    let immutableItems = JSON.parse(JSON.stringify(this.state.items));

    /*Remove Item*/
    let removeGroup = immutableItems[Number(result.source.droppableId)];
    let [removed] = removeGroup.splice(result.source.index, 1);
    /*Add Item */
    if (result.destination) {
      //Is Valid
      let addGroup = immutableItems[Number(result.destination.droppableId)];
      addGroup.splice(result.destination.index, 0, removed);
    }

    //Remove group if empty at the end
    if (removeGroup.length === 0) {
      immutableItems.splice(Number(result.source.droppableId), 1);
    }

    //Validate DropOut after Remove Group
    if (!result.destination) {
      //Is Drop Out
      immutableItems.splice(0, 0, [removed]);
    }

    /*Set State*/
    this.setState({ items: immutableItems });
  };

  render() {
    return (
      <StyledDnD flexWrap="wrap">
        <DragDropContext onDragEnd={this.onDragEnd}>
          {this.state.items.map((group, groupIndex) => (
            <Droppable key={groupIndex} droppableId={groupIndex.toString()}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} className="dragContainer" {...provided.droppableProps}>
                  {group.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <NanoFlex ref={provided.innerRef} className="dragItem" provided={provided} snapshot={snapshot} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <NanoFlex className="item">
                            <Type.p>{item.content}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </StyledDnD>
    );
  }
}
