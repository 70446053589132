import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import { downloadBlob } from "./../../../Global/tools.js";
import administrationService from "../../../../services/AdministrationService.js";

//COMPONENTS
import { AdminNavBar } from "./../AdminNavBar.js";
import Input from "../../../Global/Elements/Forms/Input.js";
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import { SearchBar } from "../../../Global/Elements/Forms/SearchBar.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";
import { OptionsFilters } from "./ForbiddenWords/OptionsFilters.js";
import { GroupsWords } from "./GroupsWords/GroupsWords.js";

//Imgs
import Plus from "../../../Global/icons/plus.svg";
import { ReactComponent as Delete } from "../../../Global/icons/delete.svg";

const StyledWordCloudAdmin = styled(NanoFlex)`
  .wordCloudAdminContent {
    .contentWrapper {
      padding: 32px;
      .topOptions {
        height: auto;
        .asideOptions {
          margin-right: 16px;
          .inputAddWordWrapper {
            height: auto;
            .inputAddWordContainer {
              .inputContainer {
                min-width: calc(240px - 35px);
              }
            }
            .addBtn {
              width: auto;
              .buttonWithIcon {
                .buttonWithIconWrapper {
                  height: 35px;
                  width: 35px;
                  border: 1px solid ${(props) => props.theme.color.main.font};
                  border-left: 0;
                }
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }

        .searchOption {
          width: auto;
          height: auto;
        }
      }

      .forbiddenWordsWrapper {
        margin-top: 16px;
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        padding: 32px;
        overflow: auto;
        height: calc(100vh - 238px);
        .forbiddenWordsList {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          grid-template-rows: auto;
          grid-auto-flow: dense;
          height: auto;
          .wordItem {
            background-color: ${(props) => props.theme.color.main.white};
            box-shadow: ${(props) => props.theme.boxShadow};
            width: auto;
            height: auto;
            .word {
              padding: 16px;
              height: auto;
              overflow: hidden;
              p {
                line-height: 14px;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .deleteBtn {
              width: auto;
              height: auto;
              padding: 16px;
              cursor: pointer;
              background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
              svg {
                height: 12px;
                width: auto;
                path {
                  fill: ${(props) => props.theme.color.main.font};
                }
              }
              &:hover {
                svg {
                  path {
                    fill: ${(props) => props.theme.color.colorsPalette.red};
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export class WordCloudAdmin extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      newWord: "",
      term: "",
      words: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getWords();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getWords = () => {
    administrationService
      .getWords()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ words: data });
      });
  };

  createWord = () => {
    let newWord = this.state.newWord;
    this.setState({ newWord: "" }, () => {
      administrationService
        .createWord({ word: newWord })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.getWords();
        });
    });
  };

  deleteWord = (id) => {
    administrationService
      .deleteWord(id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.getWords();
      });
  };

  onImportFile = (file, toReplace) => {
    administrationService
      .importWords(file, toReplace)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.getWords();
      });
  };

  onExportFile = () => {
    administrationService
      .exportWords()
      .then((response) => {
        if (response.ok) {
          return response.blob();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((blob) => {
        if (blob) {
          downloadBlob(blob, `Export-Words.xlsx`);
        }
      });
  };

  getFilteredResults = () => {
    if (this.state.term.trim() !== "") {
      let upperTerm = this.state.term.trim().toUpperCase();
      return this.state.words.filter((v) => {
        let upperWord = v.word.trim().toUpperCase();
        return upperWord.includes(upperTerm);
      });
    } else {
      return this.state.words;
    }
  };

  render() {
    return (
      <StyledWordCloudAdmin>
        {this.state.words === null && <Loading />}
        {this.state.words && this.state.words !== null && (
          <NanoFlex className="wordCloudAdminContent" flexDirection="column">
            <AdminNavBar render={this.props.render} />
            <NanoFlex className="wordcloudWrapper">
              {/* FORBIDDEN WORDS */}
              <NanoFlex className="forbiddenWrapper">
                <NanoFlex className="contentWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  <OptionsFilters onImportFile={this.onImportFile} onExportFile={this.onExportFile} />

                  <NanoFlex className="topOptions" justifyContent="flex-end" alignItems="flex-end">
                    <NanoFlex className="asideOptions" alignItems="flex-start">
                      <NanoFlex className="inputAddWordWrapper" alignItems="flex-end">
                        <NanoFlex className="inputAddWordContainer">
                          <Input title={this.translation.admin.wordcloud.addForbiddenWords} placeholder={this.translation.admin.wordcloud.addForbiddenWords} defaultValue={this.state.newWord} onChangeAction={(v) => this.setState({ newWord: v })} />
                        </NanoFlex>
                        <NanoFlex className="addBtn" alignItems="flex-end">
                          <ButtonIcon disabled={this.state.newWord.trim() === ""} src={Plus} clickAction={this.createWord} />
                        </NanoFlex>
                      </NanoFlex>
                    </NanoFlex>
                    <NanoFlex className="asideOtpions searchOption" alignItems="flex-end">
                      <SearchBar term={this.state.term} onTermChange={(term) => this.setState({ term: term })} />
                    </NanoFlex>
                  </NanoFlex>

                  <NanoFlex className="forbiddenWordsWrapper" justifyContent="flex-start" alignItems="flex-start">
                    <NanoFlex className="forbiddenWordsList" justifyContent="flex-start" alignItems="flex-start">
                      {/* WORD ITEM */}
                      {this.getFilteredResults().map((w) => (
                        <NanoFlex key={`word_${w.id}`} className="wordItem" alignItems="stretch">
                          <NanoFlex className="word" justifyContent="flex-start">
                            <Type.p>{w.word}</Type.p>
                          </NanoFlex>
                          <NanoFlex className="deleteBtn" justifyContent="flex-end" onClick={() => this.deleteWord(w.id)}>
                            <Delete />
                          </NanoFlex>
                        </NanoFlex>
                      ))}
                    </NanoFlex>
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              {/* GROUPS WORDS */}
              <NanoFlex className="groupsWrapper">
                <GroupsWords />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledWordCloudAdmin>
    );
  }
}
