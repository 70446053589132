import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

// IMGS
import { ReactComponent as Delete } from "../../../Global/icons/delete.svg";

const StyledWordCloudDnD = styled(NanoFlex)`
  height: auto;

  .dragContainer {
    padding: 16px;
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey2};
    border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
    margin: 8px;
    border-radius: 60px;
    transition: background-color 0.2s ease 0s, opacity 0.1s ease 0s;
    overflow-anchor: none;

    .dragItem {
      width: fit-content;
      height: fit-content;
      background-color: ${(props) => props.theme.color.gradientGreen.green03};
      padding: 8px 8px 8px 16px;
      margin: 8px auto;
      border-radius: 60px;
      user-select: none;

      .iconWrapper {
        cursor: pointer;
        margin-left: 12px;
        padding: 4px;
        background: ${(props) => props.theme.color.main.white};
        border-radius: 40px;

        svg {
          height: 10px;
          width: auto;
          path {
            fill: ${(props) => props.theme.color.main.font};
          }
        }
        &:hover {
          svg {
            path {
              fill: ${(props) => props.theme.color.colorsPalette.red};
            }
          }
        }
      }
    }
  }
`;

export class WordCloudDnD extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledWordCloudDnD flexWrap="wrap">
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          {this.props.items.map((group, groupIndex) => (
            <Droppable key={groupIndex} droppableId={groupIndex.toString()}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef} className="dragContainer" {...provided.droppableProps}>
                  {group.words.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <NanoFlex ref={provided.innerRef} className="dragItem" provided={provided} snapshot={snapshot} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <NanoFlex className="item">
                            <Type.p>{item.word}</Type.p>
                          </NanoFlex>
                          <NanoFlex className="iconWrapper" onClick={() => this.props.onDelete(item.id)}>
                            <Delete />
                          </NanoFlex>
                        </NanoFlex>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </StyledWordCloudDnD>
    );
  }
}
