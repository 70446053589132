import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../../Global/NanoFlex.js";
import { buildColumnsLinesPivot, buildPivotColumnsLines, buildPivotLines, buildPivotColumns, convertElementsToTree, getDateTime } from "../../../Global/tools.js";
import pivotService from "./../../../../services/PivotService";
import moment from "moment";
import * as t from "lodash";

// COMPONENTS
import Loading from "../../../Global/Elements/Loading/Loading.js";
import { Filters } from "./Filters.js";
import { RecapFilters } from "./../Grid/RecapFilters.js";
import { OptionsFilters } from "./OptionsFilters.js";
import { Timeline } from "./../Grid/Timeline.js";
import { Pivot } from "./../Pivot/Pivot.js";
import { PivotTable } from "./../Pivot/PivotTable.js";

const StyledProfile = styled(NanoFlex)`
  min-width: 0;
  .profileContent {
    padding: 32px;
    min-width: 0;
    .pivotConfiguration {
      display: none;
    }
  }

  .customReport {
    height: auto;
    padding: 20px 0px;
    .pivotTable {
      height: auto;
    }
  }
`;

export class Profile extends BaseComponent {
  constructor(props, prevProps) {
    super(props, prevProps);

    let prevFilters = null;
    let prevStart = null;
    let prevEnd = null;
    let prevColumns = null;
    let prevLines = null;

    if (window.location.pathname === sessionStorage.getItem("lastPathname") || sessionStorage.getItem("lastPathname") === "/librairies") {
      prevColumns = JSON.parse(sessionStorage.getItem("jsonColumnsByReport"));
      prevLines = JSON.parse(sessionStorage.getItem("jsonLinesByReport"));
      prevFilters = JSON.parse(sessionStorage.getItem("filtersByReport"));
      prevStart = moment(prevFilters?.startTime);
      prevEnd = moment(prevFilters?.endTime);
    }

    this.state = {
      filter: prevFilters ?? null,
      start: prevFilters !== null && prevFilters.startTime && new Date(prevFilters.startTime).getHours() !== 0 ? moment().startOf("day").set("hour", prevStart?.hour()).set("minute", prevStart.minute()).toDate() : moment().startOf("day").set("hour", 0).toDate(),
      end: prevFilters !== null && prevFilters.endTime && new Date(prevFilters.endTime).getHours() !== 0 ? moment().startOf("day").set("hour", prevEnd?.hour()).set("minute", prevEnd.minute()).toDate() : moment().startOf("day").set("hour", 24).toDate(),
      results: null,
      loading: false,
      resultsCustom: null,
      loadingCustom: false,
      pivotLoading: false,
      pivotSettingsLoading: false,
      isConfigurationCollapsed: true,
      selectedTreeColumns: [
        { title: "Age", name: "Age", code: "rec_age2", type: "SocioDemo", expanded: true, children: [], sortOrientation: null, sortPosition: null },
        { title: "CSP", name: "CSP", code: "preccsp", type: "SocioDemo", expanded: true, children: [], sortOrientation: null, sortPosition: null },
        { title: "Taille d'agglomération", name: "Taille d'agglomération", code: "pagglor", type: "SocioDemo", expanded: true, children: [], sortOrientation: null, sortPosition: null },
      ], //prevColumns !== null ? convertElementsToTree(prevColumns.elements) : [],
      selectedTreeLines: [{ title: "Sexe", name: "Sexe", code: "psexerec", type: "SocioDemo", expanded: true, children: [] }], //prevLines !== null ? convertElementsToTree(prevLines.elements) : [],
      hideCounts: prevFilters?.viewDetails ?? true,
      diffusionsSelected: [],
    };

    if (this.state.filter) {
      this.state.filter.minTimeline = moment(this.state.start).set("hour", 0).toDate();
      this.state.filter.maxTimeline = moment(this.state.start).set("hour", 24).toDate();
    }

    this._requestId = 1;
    sessionStorage.setItem("lastPathname", window.location.pathname);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((JSON.stringify(prevState.filter) !== JSON.stringify(this.state.filter) && this.state.pivotSettingsLoading === false) || prevState.hideCounts !== this.state.hideCounts || JSON.stringify(prevState.diffusionsSelected) !== JSON.stringify(this.state.diffusionsSelected)) {
      if (this.state.filter) {
        this.getPivots();
      } else {
        this.setState({ results: null, resultsCustom: null });
      }
    }

    if (
      JSON.stringify(prevState.selectedTreeColumns) !== JSON.stringify(this.state.selectedTreeColumns) ||
      JSON.stringify(prevState.selectedTreeLines) !== JSON.stringify(this.state.selectedTreeLines) ||
      JSON.stringify(prevState.start) !== JSON.stringify(this.state.start) ||
      JSON.stringify(prevState.end) !== JSON.stringify(this.state.end)
    ) {
      /*Ignore 1sec timeout on first load!*/
      if (this.state.pivotSettingsLoading) {
        this.setState({ pivotSettingsLoading: false }, () => {
          if (this.state.filter) {
            this.getPivots();
          }
        });
      } else {
        this._requestId++;
        var requestId = this._requestId;
        setTimeout(() => {
          if (requestId === this._requestId) {
            if (this.state.filter) {
              this.getPivots();
            }
          }
        }, 1000);
      }
    }

    if (
      prevState.filter?.minTimeline &&
      prevState.filter?.maxTimeline &&
      (prevState.filter.minTimeline.getTime() !== this.state.filter.minTimeline.getTime() || prevState.filter.maxTimeline.getTime() !== this.state.filter.maxTimeline.getTime()) &&
      this.state.start.getTime() === prevState.filter.minTimeline.getTime() &&
      this.state.end.getTime() === prevState.filter.maxTimeline.getTime()
    ) {
      this.setState({ start: this.state.filter.minTimeline, end: this.state.filter.maxTimeline });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getFilter = () => {
    return {
      ...this.state.filter,
      ...{
        filterRecap: this.getTranslatedRecapFilters(this.state.filter.filterRecap),
        columnsLines: JSON.stringify(buildPivotColumnsLines(this.state.selectedTreeColumns, this.state.selectedTreeLines, "profile")),
        columns: JSON.stringify(buildPivotColumns(this.state.selectedTreeColumns)),
        lines: JSON.stringify(buildPivotLines(this.state.selectedTreeLines)),
        hideCounts: this.state.hideCounts,
        diffusionsSelected: this.state.diffusionsSelected,
        startTime: getDateTime(this.state.start),
        endTime: getDateTime(this.state.end),
      },
    };
  };

  getPivots = (firstLoad) => {
    this.getPivotEmissions(firstLoad);
    this.getCustomReport(firstLoad);
  };

  getPivotEmissions = (firstLoad) => {
    this.setState({ loading: firstLoad ? true : false, pivotLoading: firstLoad ? false : true }, () => {
      pivotService
        .getPivotEmissions(this.getFilter())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState({ results: data, loading: false, pivotLoading: false });
          //sessionStorage.removeItem("filtersByReport");
          sessionStorage.removeItem("jsonLinesByReport");
          sessionStorage.removeItem("jsonColumnsByReport");
        });
    });
  };

  getCustomReport = (firstLoad) => {
    this.setState({ loadingCustom: firstLoad ? true : false }, () => {
      pivotService
        .getCustomReport(this.getFilter())
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.setState({ resultsCustom: data, loadingCustom: false });
        });
    });
  };

  render() {
    let loading = !this.state.filter || this.state.loading;
    let isPivotLoading = this.state.pivotLoading || this.state.pivotSettingsLoading || this.state.loadingCustom;
    let pivotSettingsSelected = JSON.stringify(buildPivotColumnsLines(this.state.selectedTreeColumns, this.state.selectedTreeLines, "profile").categories);

    return (
      <StyledProfile justifyContent="flex-start">
        <Filters filtersSelected={this.state.filter} onFilterChange={(f) => this.setState({ filter: f })} isActive={this.state.filter ? true : false} />
        <NanoFlex className="profileContent">
          {loading && (
            <NanoFlex className="profileWrapper">
              <Loading />
            </NanoFlex>
          )}
          {!loading && (
            <NanoFlex className="profileWrapper" flexDirection="column" justifyContent="flex-start">
              <RecapFilters selectedFilters={this.getFilter()} />
              <OptionsFilters
                selectedInterval={[moment(this.state.start).format("HH:mm"), moment(this.state.end).format("HH:mm")]}
                filtersSelected={this.getFilter()}
                pivotSettings={pivotSettingsSelected}
                hasData={this.state.results?.result?.rows.length > 0}
                hideCounts={this.state.hideCounts}
                onHideCountsChange={(h) => this.setState({ hideCounts: h })}
              />
              <Timeline selectedInterval={[this.state.start, this.state.end]} timelineInterval={[this.state.filter.minTimeline, this.state.filter.maxTimeline]} onChangeCallback={(intervals) => this.setState({ start: intervals[0], end: intervals[1] })} />
              <Pivot
                isProfile={true}
                loading={isPivotLoading}
                selectedTreeColumns={this.state.selectedTreeColumns}
                selectedTreeLines={this.state.selectedTreeLines}
                onSelectedTreeChange={(columns, lines) => this.setState({ selectedTreeColumns: columns, selectedTreeLines: lines })}
                data={this.state.results}
              />
              {this.state.resultsCustom && !isPivotLoading && (
                <NanoFlex className="customReport">
                  <PivotTable isProfile={true} isVerbatim={false} data={this.state.resultsCustom} selectedTreeColumns={[]} />
                </NanoFlex>
              )}
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledProfile>
    );
  }
}
