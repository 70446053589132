import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import filtersService from "./../../../../services/FiltersService";
import moment from "moment";
import Settings from "../../../Global/Settings.json";
import * as _ from "lodash";

// COMPONENTS
import { BaseFilter } from "./../../Shared/BaseFilter.js";
import { BaseFilterSection } from "./../../Shared/BaseFilterSection.js";
import MultipleSelectionDropdown from "../../../Global/Elements/Forms/MultipleSelectionDropdown.js";
import InputNumberRange from "../../../Global/Elements/Forms/InputNumberRange.js";
import CalendarRange from "../../../Global/Elements/Forms/CalendarRange.js";
import FetchTreeNavigatorDropdown from "../../../Global/Elements/Forms/FetchTreeNavigatorDropdown.js";
import { DateFormat } from "../../../Global/Elements/Forms/DateFormat.js";
import SwitchEmissionDiffusion from "../../../Global/Elements/Buttons/SwitchEmissionDiffusion.js";

const StyledFilters = styled(NanoFlex)`
  width: auto;
  transition: ${(props) => props.theme.transition};
`;

export class Filters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState(this.props.filtersSelected ?? {});

    this.firstLoad = true;
    this.defaultDateSelected = null;
  }

  getDefaultState = (prevFilters) => {
    return {
      dateRanges: null,
      channels: null,
      dayParts: null,
      genres: null,
      platforms: null,
      genders: null,
      ages: null,
      csp: null,
      loaded: false,
      ...this.getDefaultStateSelection(prevFilters),
    };
  };

  getDefaultStateSelection = (prevFilters) => {
    return {
      //Date
      dateOption: prevFilters.dateOption ?? 0,
      startDateSelected: prevFilters.startDateSelected
        ? moment(new Date(prevFilters.startDateSelected), Settings.moment.serverDate).format(Settings.moment.date)
        : prevFilters.dateSelected
          ? moment(new Date(prevFilters.dateSelected), Settings.moment.serverDate).format(Settings.moment.date)
          : this.defaultDateSelected,
      endDateSelected: prevFilters.endDateSelected
        ? moment(new Date(prevFilters.endDateSelected), Settings.moment.serverDate).format(Settings.moment.date)
        : prevFilters.dateSelected
          ? moment(new Date(prevFilters.dateSelected), Settings.moment.serverDate).format(Settings.moment.date)
          : this.defaultDateSelected,
      weekdaysSelected: prevFilters.weekdaysSelected ?? [],
      datesSelected: prevFilters.datesSelected ?? [],
      //Channels
      channelsSelected: prevFilters.channelsSelected ?? [],
      allChannelsSelected: false,
      //Emissions
      emissionsSelected: prevFilters.emissionsSelected ?? [],
      //Genres
      genresSelected: prevFilters.genresSelected ?? [],
      allGenresSelected: false,
      //Platforms
      platformsSelected: prevFilters.platformsSelected ?? [],
      allPlatformsSelected: false,
      //Genders
      gendersSelected: prevFilters.gendersSelected ?? [],
      allGendersSelected: false,
      //Ages
      agesSelected: prevFilters.agesSelected ?? [],
      allAgesSelected: false,
      //Dayparts
      dayPartsSelected: prevFilters.dayPartsSelected ?? [],
      allDayPartsSelected: false,
      //Csp
      cspSelected: prevFilters.cspSelected ?? [],
      allCspSelected: false,
      //Duration
      durationStart: prevFilters.durationStart ?? null,
      durationEnd: prevFilters.durationEnd ?? null,
      //Correspondants
      correspondantsStart: prevFilters.correspondantsStart ?? null,
      correspondantsEnd: prevFilters.correspondantsEnd ?? null,
      isCleared: false,
      aggregateEmissions: false,
    };
  };

  componentDidMount() {
    this.defaultDateSelected = null;
    this.getAllData();

    //Events
    window.addEventListener("deleteFilter", this.onDeleteFilter, false);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.isComponentDataLoaded(prevState) !== this.isComponentDataLoaded(this.state)) {
      this.setState({ loaded: this.isComponentDataLoaded(this.state) }, () => {
        /*Trigger Filter on First Load*/
        if (this.firstLoad && this.state.loaded && !this.props.isVerbatim) {
          this.firstLoad = false;
          this.onFilter();
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("deleteFilter", this.onDeleteFilter, false);
  }

  getAllData = () => {
    this.getDateRange();
    this.getDayparts();
    this.getChannels();
    this.getGenres();
    this.getPlatforms();
    this.getAges();
    this.getGenders();
    this.getCSP();
  };

  isComponentDataLoaded = (obj) => {
    let state = obj ?? this.state;

    if (state.dateRanges === null || state.dayparts === null || state.channels === null || state.genres === null || state.genders === null || state.ages === null || state.csp === null) {
      return false;
    } else {
      return true;
    }
  };

  getDateRange = () => {
    if (this.props.isTvReplay) {
      filtersService
        .getDateRangeTvReplay()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.defaultDateSelected = data?.endDate;
          this.setState({
            dateRanges: data,
            startDateSelected: this.props.filtersSelected?.startDateSelected ? this.props.filtersSelected?.startDateSelected : this.props.filtersSelected?.dateSelected ? this.props.filtersSelected?.dateSelected : data?.endDate,
            endDateSelected: this.props.filtersSelected?.endDateSelected ? this.props.filtersSelected?.endDateSelected : this.props.filtersSelected?.dateSelected ? this.props.filtersSelected?.dateSelected : data?.endDate,
          });
        });
    } else {
      filtersService
        .getDateRangeDefinitifs()
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.defaultDateSelected = data?.endDate;
          this.setState({
            dateRanges: data,
            startDateSelected: this.props.filtersSelected?.startDateSelected ? this.props.filtersSelected?.startDateSelected : this.props.filtersSelected?.dateSelected ? this.props.filtersSelected?.dateSelected : data?.endDate,
            endDateSelected: this.props.filtersSelected?.endDateSelected ? this.props.filtersSelected?.endDateSelected : this.props.filtersSelected?.dateSelected ? this.props.filtersSelected?.dateSelected : data?.endDate,
          });
        });
    }
  };

  getEmissions = () => {
    filtersService
      .getEmissions(null) //filter
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ emissions: data });
      });
  };

  getDayparts = () => {
    filtersService
      .getDayparts()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ dayParts: data });
      });
  };

  getChannels = () => {
    filtersService
      .getChannels()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ channels: data });
      });
  };

  getGenres = () => {
    filtersService
      .getGenres()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ genres: data });
      });
  };

  getPlatforms = () => {
    filtersService
      .getPlatforms()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ platforms: data });
      });
  };

  getGenders = () => {
    filtersService
      .getGenders()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ genders: data });
      });
  };

  getAges = () => {
    filtersService
      .getAges()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ ages: data });
      });
  };

  getCSP = () => {
    filtersService
      .getCSP()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ csp: data });
      });
  };

  getRecapFilters = () => {
    let recap = [];

    //Date
    if (this.state.startDateSelected !== null) {
      recap.push({
        translation: "filters.from",
        field: "startDate",
        values: [{ name: moment(this.state.startDateSelected, Settings.moment.serverDate).format(Settings.moment.date) }],
      });
    }

    if (this.state.endDateSelected !== null) {
      recap.push({
        translation: "filters.to",
        field: "endDate",
        values: [{ name: moment(this.state.endDateSelected, Settings.moment.serverDate).format(Settings.moment.date) }],
      });
    }

    if (this.state.weekdaysSelected && this.state.weekdaysSelected.length > 0) {
      recap.push({
        translation: "filters.weekdays",
        field: "weekdays",
        values: Settings.filters.weekdays.filter((c) => this.state.weekdaysSelected.includes(c.id)),
      });
    }

    if (this.state.datesSelected && this.state.datesSelected.length > 0) {
      recap.push({
        translation: "filters.dates",
        field: "dates",
        values: this.state.datesSelected.map((d) => {
          return { name: moment(d, Settings.moment.serverDate).format(Settings.moment.date) };
        }),
      });
    }

    //Channels
    if (this.state.channels && this.state.channelsSelected !== null) {
      recap.push({
        translation: "filters.channels",
        field: "channels",
        values: this.state.allChannelsSelected ? this.state.channels : this.state.channels.filter((c) => this.state.channelsSelected.includes(c.id)),
        isAllSelected: this.state.allChannelsSelected,
      });
    }

    //Emissions
    if (this.state.emissionsSelected !== null) {
      recap.push({
        translation: "filters.emissions",
        field: "emissions",
        values: this.state.emissionsSelected,
        isAllSelected: false,
      });
    }

    //Genres
    if (this.state.genres && this.state.genresSelected !== null) {
      recap.push({
        translation: "filters.genreFTV",
        field: "genreFTV",
        values: this.state.allGenresSelected ? this.state.genres : this.state.genres.filter((c) => this.state.genresSelected.includes(c.id)),
        isAllSelected: this.state.allGenresSelected,
      });
    }

    //Platforms
    if (this.state.platforms && this.state.platformsSelected !== null) {
      recap.push({
        translation: "filters.platforms",
        field: "platforms",
        values: this.state.allPlatformsSelected ? this.state.platforms : this.state.platforms.filter((c) => this.state.platformsSelected.includes(c.id)),
        isAllSelected: this.state.allPlatformsSelected,
      });
    }

    //Genders
    if (this.state.genders && this.state.gendersSelected !== null) {
      recap.push({
        translation: "filters.gender",
        field: "gender",
        values: this.state.allGendersSelected ? this.state.genders : this.state.genders.filter((c) => this.state.gendersSelected.includes(c.id)),
        isAllSelected: this.state.allGendersSelected,
      });
    }

    //Ages
    if (this.state.ages && this.state.agesSelected !== null) {
      recap.push({
        translation: "filters.age",
        field: "age",
        values: this.state.allAgesSelected ? this.state.ages : this.state.ages.filter((c) => this.state.agesSelected.includes(c.id)),
        isAllSelected: this.state.allAgesSelected,
      });
    }

    //Dayparts
    if (this.state.dayParts && this.state.dayPartsSelected !== null) {
      recap.push({
        translation: "filters.dayPart",
        field: "dayPart",
        values: this.state.allDayPartsSelected ? this.state.dayParts : this.state.dayParts.filter((c) => this.state.dayPartsSelected.includes(c.id)),
        isAllSelected: this.state.allDayPartsSelected,
      });
    }

    //Csp
    if (this.state.csp && this.state.cspSelected !== null) {
      recap.push({
        translation: "filters.csp",
        field: "csp",
        values: this.state.allCspSelected ? this.state.csp : this.state.csp.filter((c) => this.state.cspSelected.includes(c.id)),
        isAllSelected: this.state.allCspSelected,
      });
    }

    //Duration
    if (this.state.durationStart || this.state.durationEnd) {
      recap.push({
        translation: "filters.duration",
        field: "duration",
        values: [{ name: this.state.durationStart?.toString() ?? "-" }, { name: this.state.durationEnd?.toString() ?? "-" }],
      });
    }

    //Correspondants
    if (this.state.correspondantsStart || this.state.correspondantsEnd) {
      recap.push({
        translation: "filters.numberCorrespondants",
        field: "numberCorrespondants",
        values: [{ name: this.state.correspondantsStart?.toString() ?? "-" }, { name: this.state.correspondantsEnd?.toString() ?? "-" }],
      });
    }

    return recap.filter((r) => r.values.length > 0);
  };

  onFilter = () => {
    var filters = {
      //Date
      dateOption: this.state.dateOption,
      startDateSelected: this.state.startDateSelected,
      endDateSelected: this.state.endDateSelected,
      weekdaysSelected: this.state.weekdaysSelected,
      datesSelected: this.state.datesSelected,
      //Channels
      channelsSelected: this.state.channelsSelected,
      allChannelsSelected: this.state.allChannelsSelected,
      //Emissions
      emissionsSelected: this.state.emissionsSelected,
      //Genres
      genresSelected: this.state.genresSelected,
      allGenresSelected: this.state.allGenresSelected,
      //Platform
      platformsSelected: this.state.platformsSelected,
      allPlatformsSelected: this.state.allPlatformsSelected,
      //Genders
      gendersSelected: this.state.gendersSelected,
      allGendersSelected: this.state.allGendersSelected,
      //Ages
      agesSelected: this.state.agesSelected,
      allAgesSelected: this.state.allAgesSelected,
      //Dayparts
      dayPartsSelected: this.state.dayPartsSelected,
      allDayPartsSelected: this.state.allDayPartsSelected,
      minTimeline: moment(_.minBy(this.state.dayPartsSelected?.length > 0 ? this.state.dayParts.filter((d) => this.state.dayPartsSelected.includes(d.id)) : [{ startTime: 0 }], (d) => moment(d.startTime.toString(), "HHmm")).startTime, "HHmm").toDate(),
      maxTimeline: moment(_.maxBy(this.state.dayPartsSelected?.length > 0 ? this.state.dayParts.filter((d) => this.state.dayPartsSelected.includes(d.id)) : [{ endTime: 2400 }], (d) => moment(d.endTime.toString(), "HHmm")).endTime, "HHmm").toDate(),
      //Csp
      cspSelected: this.state.cspSelected,
      allCspSelected: this.state.allCspSelected,
      //Duration
      durationStart: this.state.durationStart,
      durationEnd: this.state.durationEnd,
      //Correspondants
      correspondantsStart: this.state.correspondantsStart,
      correspondantsEnd: this.state.correspondantsEnd,
      //Filters Recap
      filterRecap: this.getRecapFilters(),
      //CLEARED FILTERS
      isCleared: this.state.isCleared,
      //AGGREGATE
      aggregateEmissions: this.state.aggregateEmissions
    };
    this.props.onFilterChange(filters);
    sessionStorage.setItem("filtersByReport", JSON.stringify(filters));

    if (this.state.isCleared) {
      this.setState({ isCleared: false });
    }
  };

  onDeleteFilter = (e) => {
    if (!e.detail) return;

    let toChange = {};
    e.detail.forEach((f) => {
      switch (f.field) {
        case "startDate":
          toChange.startDateSelected = null;
          break;
        case "endDate":
          toChange.endDateSelected = null;
          break;
        case "weekdays":
          toChange.weekdaysSelected = [];
          break;
        case "dates":
          toChange.datesSelected = [];
          break;
        case "channels":
          toChange.allChannelsSelected = false;
          toChange.channelsSelected = [];
          break;
        case "emissions":
          toChange.emissionsSelected = [];
          break;
        case "genreFTV":
          toChange.allGenresSelected = false;
          toChange.genresSelected = [];
          break;
        case "platforms":
          toChange.allPlatformsSelected = false;
          toChange.platformsSelected = [];
          break;
        case "gender":
          toChange.allGendersSelected = false;
          toChange.gendersSelected = [];
          break;
        case "age":
          toChange.allAgesSelected = false;
          toChange.agesSelected = [];
          break;
        case "dayPart":
          toChange.allDayPartsSelected = false;
          toChange.dayPartsSelected = [];
          break;
        case "csp":
          toChange.allCspSelected = false;
          toChange.cspSelected = [];
          break;
        case "duration":
          toChange.durationStart = null;
          toChange.durationEnd = null;
          break;
        case "numberCorrespondants":
          toChange.correspondantsStart = null;
          toChange.correspondantsEnd = null;
          break;
        default:
          break;
      }
    });

    if (toChange !== {}) {
      this.setState(toChange, () => {
        this.onFilter();
      });
    }
  };

  onClear = () => {
    var defaultSelection = this.getDefaultStateSelection({});
    defaultSelection.dayPartsSelected = [];
    defaultSelection.isCleared = true;
    this.setState(defaultSelection);
  };

  onChannelChange = (e) => {
    /*Filter Emissions Selected By Channels Selected */
    let channelIds = e.selected.map((i) => i.id);
    this.setState({
      allChannelsSelected: e.isAllSelected,
      channelsSelected: channelIds,
      emissionsSelected: this.state.emissionsSelected.filter((m) => channelIds.includes(m.channel?.id)),
    });
  };

  getEmissionsFetch = () => {
    return {
      StartDateSelected: this.state.startDateSelected ?? _.minBy(this.state.datesSelected, (s) => moment(s, Settings.moment.serverDate).toDate()),
      EndDateSelected: this.state.endDateSelected ?? _.maxBy(this.state.datesSelected, (s) => moment(s, Settings.moment.serverDate).toDate()),
      ChannelsSelected: this.state.channelsSelected ?? [],
    };
  };

  isEmissionsDisabled = () => {
    let emissionsFetch = this.getEmissionsFetch();
    return emissionsFetch.ChannelsSelected.length === 0 || !emissionsFetch.StartDateSelected || !emissionsFetch.EndDateSelected;
  };

  render() {
    let emissionsDisabled = this.isEmissionsDisabled();

    return (
      <StyledFilters className="filters">
        <BaseFilter loading={!this.state.loaded} isActive={this.props.isActive} onClear={this.onClear} onFilter={this.onFilter}>
          <BaseFilterSection sectionTitle={this.translation.filters.titleGrille}>
            {/* DATE FORMAT */}
            <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
              <DateFormat
                dateOption={this.state.dateOption}
                startDate={this.state.startDateSelected}
                endDate={this.state.endDateSelected}
                weekdays={this.state.weekdaysSelected}
                selectedDates={this.state.datesSelected?.map((d) => moment(d, Settings.moment.serverDate).format(Settings.moment.date))}
                minDate={moment(this.state.dateRanges?.startDate, Settings.moment.serverDate)}
                maxDate={moment(this.state.dateRanges?.endDate, Settings.moment.serverDate)}
                onDateChange={(model) => {
                  this.setState(
                    {
                      dateOption: model.dateOption,
                      startDateSelected: model.startDate,
                      endDateSelected: model.endDate,
                      weekdaysSelected: model.weekdays,
                      datesSelected: model.selectedDates?.map((d) => moment(d, Settings.moment.date).format(Settings.moment.serverDate)),
                    },
                    () => {
                      if (this.isEmissionsDisabled()) {
                        this.setState({ emissionsSelected: [] });
                      }
                    }
                  );
                }}
              />
            </NanoFlex>
          </BaseFilterSection>
        </BaseFilter>
      </StyledFilters>
    );
  }
}
