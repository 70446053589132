/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import BaseComponent from "./../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";

// COMPONENTS
import PivotConfigurationItem from "./PivotConfigurationItem.js";

// IMGS
import { ReactComponent as ArrowDown } from "./../../../Global/icons/arrowDown.svg";
import { ReactComponent as Arrows } from "./../../../Global/icons/arrows.svg";

const StyledPivotConfiguration = styled(NanoFlex)`
  height: auto;
  margin-bottom: 8px;
  .pivotConfigurationWrapper {
    .pivotHeader {
      height: auto;
      background-color: ${(props) => props.theme.color.main.green};
      padding: 8px 16px;
      cursor: pointer;
      user-select: none;
      .titleWrapper {
        h5 {
          color: ${(props) => props.theme.color.main.white};
          text-transform: uppercase;
        }
      }
      .expandBtn {
        width: auto;
        padding-top: ${(props) => (props.isCollapsed ? "4px" : "0px")};
        svg {
          width: 16px;
          height: auto;
          transform: rotate(${(props) => (props.isCollapsed ? "0" : "180deg")});
          path {
            fill: ${(props) => props.theme.color.main.white};
          }
        }
      }
    }
    .configurationWrapper {
      height: ${(props) => (props.isCollapsed ? "0" : "auto")};
      overflow: hidden;
      padding: ${(props) => (props.isCollapsed ? "0" : "8px")};
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      .blockConfigurationWrapper {
        height: auto;
        overflow: auto;
        .headerBlock {
          height: auto;
          .titleBlock {
            p {
              text-transform: uppercase;
            }
          }
          .optionsWrapper {
            .optionBtnWrapper {
              width: auto;
              margin-right: 8px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .blockListWrapper {
          padding: 8px;
          max-height: 224px;
          overflow: auto;
          background-color: ${(props) => props.theme.color.main.white};
        }
      }
      .switchSortablesWrapper {
        margin: 0 16px;
        width: auto;
        height: auto;
        .switchBtn {
          cursor: pointer;
          padding: 16px;
          background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
          transition: ${(props) => props.theme.transition};
          svg {
            width: 24px;
            height: auto;
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
          &:hover {
            background-color: ${(props) => props.theme.color.main.font};
            transition: ${(props) => props.theme.transition};
            svg {
              path {
                fill: ${(props) => props.theme.color.main.white};
              }
            }
          }
        }
      }
    }
  }
`;

export class PivotConfiguration extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      selectedTreeColumns: this.props.selectedTreeColumns ?? [],
      selectedTreeLines: this.props.selectedTreeLines ?? [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedTreeColumns) !== JSON.stringify(this.props.selectedTreeColumns)) {
      this.setState({ selectedTreeColumns: this.props.selectedTreeColumns ?? [] });
    }
    if (JSON.stringify(prevProps.selectedTreeLines) !== JSON.stringify(this.props.selectedTreeLines)) {
      this.setState({ selectedTreeLines: this.props.selectedTreeLines ?? [] });
    }

    if ((JSON.stringify(prevState.selectedTreeColumns) !== JSON.stringify(this.state.selectedTreeColumns) || JSON.stringify(prevState.selectedTreeLines) !== JSON.stringify(this.state.selectedTreeLines)) && this.props.onSelectedTreeChange) {
      this.props.onSelectedTreeChange(this.state.selectedTreeColumns, this.state.selectedTreeLines);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  render() {
    return (
      <StyledPivotConfiguration className="pivotConfiguration" isCollapsed={this.state.isCollapsed}>
        <NanoFlex className="pivotConfigurationWrapper" flexDirection="column">
          <NanoFlex className="pivotHeader" onClick={this.toggleCollapse}>
            <NanoFlex className="titleWrapper">
              <Type.h5>
                <b>{this.translation.pivot.pivotConfiguration}</b>
              </Type.h5>
            </NanoFlex>
            <NanoFlex className="expandBtn">
              <ArrowDown />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="configurationWrapper" alignItems="stretch">
            <PivotConfigurationItem title={this.translation.pivot.columns} selectedTreeItems={this.state.selectedTreeColumns} onSelectedChange={(selected) => this.setState({ selectedTreeColumns: selected })} />
            {/* SWITCH BTN */}
            <NanoFlex className="switchSortablesWrapper">
              <NanoFlex className="switchBtn" onClick={() => this.setState({ selectedTreeLines: this.state.selectedTreeColumns, selectedTreeColumns: this.state.selectedTreeLines })}>
                <Arrows />
              </NanoFlex>
            </NanoFlex>
            <PivotConfigurationItem title={this.translation.pivot.lines} selectedTreeItems={this.state.selectedTreeLines} onSelectedChange={(selected) => this.setState({ selectedTreeLines: selected })} />
          </NanoFlex>
        </NanoFlex>
      </StyledPivotConfiguration>
    );
  }
}
