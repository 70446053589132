import React, { Component } from "react";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import { buildColumnsLinesPivot, buildPivotColumnsLines } from "../../Global/tools.js";
import administrationService from "../../../services/AdministrationService.js";

//COMPONENTS
import { AdminNavBar } from "./AdminNavBar.js";
import { PivotConfiguration } from "./../Reports/Pivot/PivotConfiguration.js";
import Type from "../../Global/Typography.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

const settingsNames = [
  {
    id: 1,
    name: "Rapport",
    subName: "Emissions",
  },
  {
    id: 2,
    name: "Programmes TV",
    subName: "Journée",
  },
  {
    id: 3,
    name: "Programmes TV",
    subName: "Soirée",
  },
  {
    id: 4,
    name: "Verbatim",
    subName: null,
  },
  {
    id: 5,
    name: "Programmes TV ",
    subName: "Chaîne TV",
  },
  {
    id: 6,
    name: "Rapport",
    subName: "Indicateur de Qualité",
  },
  {
    id: 7,
    name: "TV Replay",
    subName: null,
  },
];

const StyledReports = styled(NanoFlex)`
  min-width: 0;
  .reportsContent {
    min-width: 0;
    height: auto;
    .contentWrapper {
      padding: 32px;
      .reportsMenuWrapper {
        width: 208px;
        background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
        margin-right: 16px;
        padding: 16px;
        height: auto;
        .itemMenuReports {
          cursor: pointer;
          margin-bottom: 16px;
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          p {
            margin-top: 4px;
            text-align: center;
          }
          &:last-child {
            margin-bottom: 0px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        .activeItem {
          border-right: 4px solid ${(props) => props.theme.color.main.green};
          background-color: ${(props) => props.theme.color.main.white};
          pointer-events: none;
          h6 {
            font-weight: bold;
          }
          p {
            font-weight: bold;
          }
        }
      }
      .pivotConfiguration {
        margin-bottom: 0;
        .pivotConfigurationWrapper {
          .pivotHeader {
            .expandBtn {
              display: none;
            }
          }
          .configurationWrapper {
            height: calc(100vh - 164px);
            padding: 8px;
            .blockConfigurationWrapper {
              .blockListWrapper {
                max-height: 100%;
              }
            }
          }
        }
      }
    }
  }
`;

export class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      selectedSetting: null,
    };
  }

  componentDidMount() {
    this.getPivotSettings();
  }

  getPivotSettings = () => {
    administrationService
      .getPivotSettings()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ settings: data, selectedSetting: data[0] });
      });
  };

  savePivotSettings = (id, columns, lines) => {
    /*Get Updated Model */
    let model = {
      id: id,
      json: JSON.stringify(buildPivotColumnsLines(columns, lines, null).categories),
    };

    /*Update Settings State with new json */
    let immutableSettings = JSON.parse(JSON.stringify(this.state.settings));
    let selected = immutableSettings.find((s) => s.id === id);
    selected.json = model.json;

    this.setState({ settings: immutableSettings /*, selectedSetting: selected*/ }, () => {
      /*SavePivotSettings */
      administrationService
        .savePivotSettings(model)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {});
    });
  };

  render() {
    if (this.state.settings === null)
      return (
        <StyledReports>
          <Loading />
        </StyledReports>
      );

    let selectedPivotSettings = buildColumnsLinesPivot(JSON.parse(this.state.selectedSetting.json));
    return (
      <StyledReports>
        <NanoFlex className="reportsContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
          <AdminNavBar render={this.props.render} />
          <NanoFlex className="contentWrapper" justifyContent="flex-start" alignItems="stretch" alignContent="stretch">
            <NanoFlex className="reportsMenuWrapper" flexDirection="column">
              {this.state.settings.map((s) => {
                let settingName = settingsNames.find((sn) => sn.id === s.id);
                return (
                  <NanoFlex key={`settings_${s.id}`} className={`itemMenuReports ${this.state.selectedSetting?.id === s.id ? "activeItem" : ""}`} flexDirection="column" onClick={() => this.setState({ selectedSetting: s })}>
                    <Type.h6>{settingName.name}</Type.h6>
                    <Type.p>{settingName.subName}</Type.p>
                  </NanoFlex>
                );
              })}
            </NanoFlex>
            <PivotConfiguration selectedTreeColumns={selectedPivotSettings.columns} selectedTreeLines={selectedPivotSettings.lines} onSelectedTreeChange={(columns, lines) => this.savePivotSettings(this.state.selectedSetting.id, columns, lines)} />
          </NanoFlex>
        </NanoFlex>
      </StyledReports>
    );
  }
}
