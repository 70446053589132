import React from "react";
import BaseComponent from "../../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../../Global/NanoFlex.js";
import Type from "./../../../../Global/Typography.js";

// COMPONENTS
import ButtonIcon from "../../../../Global/Elements/Buttons/ButtonIcon.js";

//Imgs
import Import from "../../../../Global/icons/import.svg";
import Excel from "../../../../Global/icons/excel.svg";
import Pdf from "../../../../Global/icons/pdf.svg";

const StyledOptionsFilters = styled(NanoFlex)`
  height: auto;
  padding-bottom: 8px;
  user-select: none;
  .infoWrapper {
    margin-bottom: 8px;
    .asideWrapper {
      min-height: 30px;
      h5 {
        text-transform: uppercase;
        color: ${(props) => props.theme.color.main.green};
      }
      .optionBtnWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .withOptions {
        position: relative;
        .optionsWrapper {
          display: none;
          position: absolute;
          z-index: 2;
          top: 28px;
          right: 0;
          background-color: ${(props) => props.theme.color.main.white};
          box-shadow: ${(props) => props.theme.boxShadow};
          width: auto;
          min-width: 112px;
          height: auto;
          padding: 8px;
          .optionContainer {
            padding: 6px;
            cursor: pointer;
            background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
            margin-bottom: 4px;
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              background-color: ${(props) => props.theme.color.main.green};
              h6 {
                color: ${(props) => props.theme.color.main.white};
              }
            }
          }
        }
        &:hover {
          .optionsWrapper {
            display: flex;
          }
        }
      }

      .elementWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .formWrapper {
        max-width: 232px;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export class OptionsFilters extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      toReplace: null,
    };

    this.fileRef = React.createRef();
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  openImportDialog = (toReplace) => {
    this.setState({ toReplace: toReplace }, () => {
      this.fileRef.current.click();
    });
  };

  onFileChange = (e) => {
    console.log(e);
  };

  render() {
    return (
      <StyledOptionsFilters flexDirection="column">
        <NanoFlex className="infoWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper" justifyContent="flex-start">
            <Type.h5>
              <b>{this.translation.admin.wordcloud.groupsWords}</b>
            </Type.h5>
          </NanoFlex>
          <NanoFlex className="asideWrapper" justifyContent="flex-end">
            <NanoFlex className="optionBtnWrapper withOptions">
              <ButtonIcon src={Import} />
              <input ref={this.fileRef} type="file" onChange={this.onFileChange} style={{ display: "none" }} />
              <NanoFlex className="optionsWrapper" flexDirection="column">
                <NanoFlex className="optionContainer" justifyContent="flex-end" onClick={() => this.openImportDialog(false)}>
                  <Type.h6>{this.translation.global.add}</Type.h6>
                </NanoFlex>
                <NanoFlex className="optionContainer" justifyContent="flex-end" onClick={() => this.openImportDialog(true)}>
                  <Type.h6>{this.translation.global.replace}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Excel} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledOptionsFilters>
    );
  }
}
