import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import ReactWordcloud from "react-wordcloud";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

import Words from "./Words.js";

const StyledWordCloud = styled(NanoFlex)``;

export class WordCloud extends BaseComponent {
  
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledWordCloud>
        <ReactWordcloud
          words={Words}
          options={{
            fontFamily: "'Roboto', sans-serif",
            colors: ["#444444", "#009A49", "#7CCA4B", "#2B6C02", "#99D7B6"],
            fontSizes: [14, 60],
            fontStyle: "normal",
            fontWeight: "bold",
            padding: 1,
            rotations: 1,
            rotationAngles: [0],
            scale: "log",
            transitionDuration: 1000,
          }}
        />
      </StyledWordCloud>
    );
  }
}
