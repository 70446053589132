import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

import { ReactComponent as Eye } from "../../icons/eye.svg";
import { ReactComponent as EyeClosed } from "../../icons/eyeClosed.svg";

const StyledToggleButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  .toggleButtonWrapper {
    padding: 8px;
    user-select: none;
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    background-color: ${(props) => (props.active ? props.theme.color.main.green : props.theme.color.colorsPalette.lightGrey)};
    opacity:  ${(props) => (props.disabled ? "0.4" : "1")};
    .iconBtnWrapper {
      width: auto;
      margin-right: 8px;
      svg {
        width: 18px;
        height: auto;
        path {
          fill: ${(props) => (props.active ? props.theme.color.main.white : props.theme.color.main.font)};
        }
      }
    }
    .eyeClosed {
      display: ${(props) => (props.active ? "none" : "flex")};
    }
    .eye {
      display: ${(props) => (props.active ? "flex" : "none")};
    }
    .textBtnWrapper {
      width: auto;
      p {
        color: ${(props) => (props.active ? props.theme.color.main.white : props.theme.color.main.font)};
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

export default class ToggleButton extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledToggleButton flexDirection="column" active={this.props.active} disabled={this.props.disabled} onClick={this.props.clickAction}>
        <NanoFlex className="toggleButtonWrapper">
          <NanoFlex className="iconBtnWrapper">
            <EyeClosed className="eyeClosed" />
            <Eye className="eye" />
          </NanoFlex>
          <NanoFlex className="textBtnWrapper">
            <Type.p>{this.props.title}</Type.p>
          </NanoFlex>
        </NanoFlex>
      </StyledToggleButton>
    );
  }
}
