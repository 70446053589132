/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";
import administrationService from "../../../services/AdministrationService.js";

//COMPONENTS
import { AdminNavBar } from "./AdminNavBar.js";
import ButtonIcon from "../../Global/Elements/Buttons/ButtonIcon.js";
import ToggleButtonAdmin from "../../Global/Elements/Buttons/ToggleButtonAdmin.js";
import Input from "../../Global/Elements/Forms/Input.js";
import { SearchBar } from "../../Global/Elements/Forms/SearchBar.js";
import ButtonCTA from "../../Global/Elements/Buttons/ButtonCTA.js";
import ButtonWithIcon from "../../Global/Elements/Buttons/ButtonWithIcon.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

//Imgs
import Plus from "../../Global/icons/plus.svg";
import Delete from "../../Global/icons/delete.svg";
import { ReactComponent as UserIcon } from "../../Global/icons/utilisateurs.svg";
import { ReactComponent as AdminIcon } from "../../Global/icons/star.svg";
import { ReactComponent as EditIcon } from "../../Global/icons/edit.svg";

const StyledUsers = styled(NanoFlex)`
  min-width: 0;
  .usersContent {
    min-width: 0;
    .contentWrapper {
      padding: 32px;
      .topBarWrapper {
        margin-bottom: 16px;
        height: auto;
        .asideBar {
          width: auto;
        }
      }
      .userListWrapper {
        .userList {
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          padding: 32px;
          overflow: auto;
          height: calc(100vh - 180px);
          .listWrapper {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
            grid-template-rows: auto;
            grid-auto-flow: dense;
            height: auto;
            .itemUser {
              background-color: ${(props) => props.theme.color.main.white};
              box-shadow: ${(props) => props.theme.boxShadow};
              padding: 24px;
              width: auto;
              height: auto;
              position: relative;
              .iconUser {
                margin-bottom: 24px;
                width: 64px;
                background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
                padding: 16px;
                height: 64px;
                border-radius: 62px;
                position: relative;

                svg {
                  width: auto;
                  height: 48px;
                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
                .adminIcon {
                  position: absolute;
                  height: auto;
                  bottom: 4px;
                  left: 24px;
                  svg {
                    height: 20px;
                    width: auto;
                    path {
                      fill: ${(props) => props.theme.color.colorsPalette.yellow};
                    }
                  }
                }
              }
              .userInfo {
                height: auto;
                .infoWrapper {
                  margin-bottom: 8px;
                  p {
                    width: calc(100% - 16px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
              .editBtn {
                cursor: pointer;
                position: absolute;
                bottom: 0;
                right: 0;
                height: 46px;
                width: 46px;
                border-radius: 60px 0 0 0;
                background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
                transition: all 0.2s ease-in-out;
                svg {
                  width: 16px;
                  height: auto;
                  margin: 8px 0 0 8px;
                  path {
                    transition: all 0.2s ease-in-out;
                    fill: ${(props) => props.theme.color.colorsPalette.grey};
                  }
                }
                &:hover {
                  background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};

                  svg {
                    path {
                      transition: all 0.2s ease-in-out;
                      fill: ${(props) => props.theme.color.main.font};
                    }
                  }
                }
              }
            }
            .selectedUser {
              .iconUser {
                background-color: ${(props) => props.theme.color.gradientGreen.green04};
                svg {
                  width: auto;
                  height: 48px;
                  path {
                    fill: ${(props) => props.theme.color.main.font};
                  }
                }
              }
              .editBtn {
                background-color: ${(props) => props.theme.color.main.green};
                svg {
                  width: 16px;
                  height: auto;
                  margin: 8px 0 0 8px;
                  transform: rotate(360deg) scaleX(-1);
                  path {
                    transition: all 0.2s ease-in-out;
                    fill: ${(props) => props.theme.color.main.white};
                  }
                }
                &:hover {
                  background-color: ${(props) => props.theme.color.main.green};

                  svg {
                    path {
                      transition: all 0.2s ease-in-out;
                      fill: ${(props) => props.theme.color.main.white};
                    }
                  }
                }
              }
            }
          }
        }

        .formEditCreateWrapper {
          width: 344px;
          background-color: ${(props) => props.theme.color.main.white};
          border: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
          .headerWrapper {
            background-color: ${(props) => (props.isCollapsed ? "unset" : props.theme.color.main.green)};
            height: auto;
            padding: 16px;
            h5 {
              text-transform: uppercase;
              color: ${(props) => props.theme.color.main.white};
            }
          }
          form {
            height: 100%;
            width: 100%;

            .formContainer {
              padding: 16px;
              height: calc(100vh - 234px);
              overflow: auto;
              .formTop {
                height: auto;
                .formWrapper {
                  margin-bottom: 12px;
                  .titleForm {
                    margin-bottom: 4px;
                  }
                  &:last-child {
                    margin-bottom: 0;
                  }
                }
                .adminBtn {
                  margin-bottom: 16px;
                }
              }
              .formBottom {
                height: auto;
                margin-top: 32px;
                .deleteBtn {
                  height: auto;
                  margin-bottom: 8px;
                  .buttonWithIcon {
                    width: 100%;
                    .buttonWithIconWrapper {
                      width: 100%;
                      justify-content: center;
                      .titleBtn {
                        width: auto;
                        order: 2;
                        margin-top: 4px;
                      }
                      .iconBtn {
                        width: auto;
                        margin-left: 0;
                        margin-right: 8px;
                      }
                      &:hover {
                        background-color: ${(props) => props.theme.color.colorsPalette.red};
                        .titleBtn {
                          p {
                            color: ${(props) => props.theme.color.main.white};
                          }
                        }
                        .iconBtn {
                          img {
                            filter: brightness(100);
                          }
                        }
                      }
                    }
                  }
                }
                .filterBtn {
                  height: auto;
                }
              }
            }
          }
        }
      }
    }
  }
  /* MEDIA QUERIES */
  @media only screen and (max-width: 1400px) {
    .usersContent {
      .contentWrapper {
        .userListWrapper {
          .userList {
            .listWrapper {
              grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .usersContent {
      .contentWrapper {
        .userListWrapper {
          .userList {
            .listWrapper {
              grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
            }
          }
        }
      }
    }
  }
`;

export class Users extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      users: null,
      editUser: null,
      openCreate: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getUsers();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getUsers = () => {
    administrationService
      .getUsers()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ users: data });
      });
  };

  getFilteredUsers = () => {
    if (this.state.term.trim() !== "") {
      let upperTerm = this.state.term.trim().toUpperCase();
      return this.state.users.filter((v) => {
        let upperName = v.name.trim().toUpperCase();
        let upperUsername = v.username.trim().toUpperCase();
        return upperName.includes(upperTerm) || upperUsername.includes(upperTerm);
      });
    } else {
      return this.state.users;
    }
  };

  render() {
    return (
      <StyledUsers>
        {this.state.users === null && <Loading />}
        {this.state.users && this.state.users !== null && (
          <NanoFlex className="usersContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <AdminNavBar render={this.props.render} />
            <NanoFlex className="contentWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              {/* TOP BAR */}
              <NanoFlex className="topBarWrapper" justifyContent="space-between">
                <NanoFlex className="asideBar" justifyContent="flex-start">
                  <SearchBar term={this.state.term} onTermChange={(v) => this.setState({ term: v })} />
                </NanoFlex>
                <NanoFlex className="asideBar" justifyContent="flex-end">
                  <NanoFlex className="optionBtnWrapper" onClick={() => this.setState({ openCreate: !this.state.openCreate, editUser: null })}>
                    <ButtonIcon src={Plus} />
                  </NanoFlex>
                </NanoFlex>
              </NanoFlex>
              <NanoFlex className="userListWrapper">
                {/* USERS LIST */}
                <NanoFlex className="userList" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
                  <NanoFlex className="listWrapper" alignContent="flax-start">
                    {/* ITEM USER */}
                    {this.getFilteredUsers().map((c) => (
                      <NanoFlex key={`user_${c.id}`} className={`itemUser ${this.state.editUser?.id === c.id ? "selectedUser" : ""}`} flexDirection="column">
                        <NanoFlex className="iconUser">
                          <UserIcon />
                          {c.isAdmin && (
                            <NanoFlex className="adminIcon" title="Admin">
                              <AdminIcon />
                            </NanoFlex>
                          )}
                        </NanoFlex>
                        <NanoFlex className="userInfo" flexDirection="column">
                          <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.h6>
                              <b>{this.translation.admin.users.username}</b>
                            </Type.h6>
                            <Type.p>{c.username}</Type.p>
                          </NanoFlex>
                          <NanoFlex className="infoWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                            <Type.h6>
                              <b>{this.translation.admin.users.name}</b>
                            </Type.h6>
                            <Type.p>{c.name}</Type.p>
                          </NanoFlex>
                        </NanoFlex>
                        <NanoFlex className="editBtn" onClick={() => this.setState({ editUser: this.state.editUser?.id !== c.id ? c : null, openCreate: false })}>
                          <EditIcon />
                        </NanoFlex>
                      </NanoFlex>
                    ))}
                  </NanoFlex>
                </NanoFlex>
                {/* FORM EDIT/CREATE USER */}
                {(this.state.editUser || this.state.openCreate) && <EditUser user={this.state.editUser} isCreate={this.state.openCreate} nextAction={() => this.setState({ editUser: null, openCreate: false }, this.getUsers)} />}
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledUsers>
    );
  }
}

class EditUser extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = this.getDefaultState();
  }

  getDefaultState = () => {
    return {
      id: this.props.user?.id,
      isAdmin: this.props.user?.isAdmin ?? false,
      username: this.props.user?.username ?? "",
      name: this.props.user?.name ?? "",
      password: "",
      confirmPassword: "",
      confirmPasswordError: false,
    };
  };

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.setState(this.getDefaultState());
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  saveUser = (event) => {
    // Prevent page refresh
    event.preventDefault();

    if (this.state.password === this.state.confirmPassword) {
      let user = {
        id: this.state.id,
        isAdmin: this.state.isAdmin,
        username: this.state.username,
        name: this.state.name,
        password: this.state.password,
      };

      let request = this.props.isCreate ? administrationService.createUser(user) : administrationService.saveUser(user);

      request
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Catch
            if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
          }
        })
        .then((data) => {
          this.props.nextAction();
        });
    } else {
      this.setState({ confirmPasswordError: true });
    }
  };

  deleteUser = () => {
    administrationService
      .deleteUser(this.state.id)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.props.nextAction();
      });
  };

  render() {
    return (
      <NanoFlex className="formEditCreateWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <NanoFlex className="headerWrapper">
          <Type.h5>
            <b>{this.props.isCreate ? this.translation.admin.users.createUser : this.translation.admin.users.editUser}</b>
          </Type.h5>
        </NanoFlex>
        <form onSubmit={this.saveUser}>
          <NanoFlex className="formContainer" flexDirection="column" justifyContent="space-between" alignItems="flex-start">
            <NanoFlex className="formTop" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
              <NanoFlex className="formWrapper adminBtn">
                <ToggleButtonAdmin active={this.state.isAdmin} clickAction={() => this.setState({ isAdmin: !this.state.isAdmin })} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input title={this.translation.admin.users.username} placeholder={this.translation.admin.users.username} defaultValue={this.state.username} onChangeAction={(v) => this.setState({ username: v })} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input title={this.translation.admin.users.name} placeholder={this.translation.admin.users.name} defaultValue={this.state.name} onChangeAction={(v) => this.setState({ name: v })} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input eye title={this.translation.admin.users.password} type="password" placeholder={this.translation.admin.users.password} defaultValue={this.state.password} onChangeAction={(v) => this.setState({ password: v })} />
              </NanoFlex>
              <NanoFlex className="formWrapper">
                <Input
                  eye
                  title={this.translation.admin.users.confirmPassword}
                  type="password"
                  placeholder={this.translation.admin.users.confirmPassword}
                  error={this.state.confirmPasswordError}
                  defaultValue={this.state.confirmPassword}
                  onChangeAction={(v) => this.setState({ confirmPassword: v })}
                />
              </NanoFlex>
            </NanoFlex>
            <NanoFlex className="formBottom" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
              {!this.props.isCreate && (
                <NanoFlex className="deleteBtn" onClick={this.deleteUser}>
                  <ButtonWithIcon label={this.translation.admin.users.deleteUser} src={Delete} />
                </NanoFlex>
              )}
              <NanoFlex className="filterBtn">
                <ButtonCTA type="submit" label={this.translation.global.confirm} />
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        </form>
      </NanoFlex>
    );
  }
}
