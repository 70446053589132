import { BaseService } from "./BaseService";

export class PivotService extends BaseService {
  getPivotColumnsLines() {
    return this.request("api/Pivot/GetPivotColumnsLines", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getPivotSettings(type) {
    return this.request(`api/Pivot/GetPivotSettings/${type}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "GET",
    });
  }

  getPivotEmissions(model) {
    return this.request("api/Pivot/GetPivotEmissions", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getCustomReport(model) {
    return this.request("api/Pivot/GetCustomReport", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getWordCloud(model) {
    return this.request("api/Pivot/GetWordCloud", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getPivotExcel(model) {
    return this.request("api/Pivot/GetPivotExcel", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }

  getCustomReport(model) {
    return this.request("api/Pivot/GetCustomReport", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(model),
    });
  }
}

const pivotService = new PivotService();

export default pivotService;
