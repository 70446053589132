/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

// COMPONENTS
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import ToggleButton from "../../../Global/Elements/Buttons/ToggleButton.js";
import UsersBlock from "../../../Global/Elements/Blocks/UsersBlock.js";
import Modal from "../../../Global/Elements/Modals/Modal.js";
import { SaveReportModal } from "../../../Global/Elements/Modals/SaveReportModal.js";

//Imgs
import Excel from "../../../Global/icons/excel.svg";
import Pdf from "../../../Global/icons/pdf.svg";
import Save from "../../../Global/icons/save.svg";

const StyledOptionsFilters = styled(NanoFlex)`
  height: auto;
  padding: 16px 0;
  user-select: none;
  .infoWrapper {
    margin-bottom: 8px;
    .asideWrapper {
      .titleWrapper {
        width: auto;
        margin-right: 8px;
      }
      .infoContainer {
        width: auto;
      }
      .optionBtnWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
      .elementWrapper {
        width: auto;
        margin-right: 8px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export class OptionsFilters extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSaveReport: false,
    };
  }

  onViewDetails = () => {
    this.props.onViewDetail(!this.props.viewDetails);
  };

  render() {
    let startTime = this.props.selectedInterval[0];
    let endTime = this.props.selectedInterval[1] === "00:00" ? "24:00" : this.props.selectedInterval[1];
    return (
      <StyledOptionsFilters flexDirection="column">
        <Modal show={this.state.openSaveReport} content={<SaveReportModal reportType={1} filtersSelected={this.props.filtersSelected} cancelAction={() => this.setState({ openSaveReport: false })} nextAction={() => this.setState({ openSaveReport: false })} />} />
        <NanoFlex className="infoWrapper" justifyContent="space-between">
        {/* <NanoFlex className="asideWrapper" justifyContent="flex-start">
            <NanoFlex className="elementWrapper">
              <UsersBlock grey number={this.props.peopleSample} />
            </NanoFlex>
          </NanoFlex> */}
          <NanoFlex className="asideWrapper" justifyContent="flex-end">
            <NanoFlex className="optionBtnWrapper">
              <ToggleButton title={this.translation.global.viewDetails} active={this.props.viewDetails} clickAction={this.onViewDetails} disabled={!this.props.hasData} />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon
                src={Excel}
                disabled={!this.props.hasData}
                clickAction={() => {
                  if (this.props.onExcelExport) this.props.onExcelExport();
                }}
              />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon
                src={Pdf}
                disabled={!this.props.hasData}
                clickAction={() => {
                  if (this.props.onPdfExport) this.props.onPdfExport();
                }}
              />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon src={Save} clickAction={() => this.setState({ openSaveReport: true })} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="infoWrapper" justifyContent="space-between">
          <NanoFlex className="asideWrapper" justifyContent="flex-start">
            <NanoFlex className="elementWrapper">
              <UsersBlock grey number={this.props.peopleSample} />
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="asideWrapper" justifyContent="flex-end">
            <NanoFlex className="titleWrapper">
              <Type.h6>
                <b>{this.translation.global.trancheHoraire}:</b>
              </Type.h6>
            </NanoFlex>
            <NanoFlex className="infoContainer">
              <Type.p>
                {startTime}h - {endTime}h
              </Type.p>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
      </StyledOptionsFilters>
    );
  }
}
