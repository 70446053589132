import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import * as _ from "lodash";

// COMPONENTS
import { Palmares } from "../Palmares/Palmares";

const StyledExportPalmares = styled(NanoFlex)`
  /* position: relative;
height: -webkit-fill-available; */
  .palmares {
    justify-content: space-between;
    position: relative;
    height: -webkit-fill-available;
    .palmaresContent {
      padding: 0 16px;
      overflow: unset;
      .palmaresWrapper {
        height: auto;
        justify-content: space-between;

        .listPalmaresContent {
          .listWrapper {
            .listHeader {
              padding: 8px;
              box-shadow: none;
              .headerCol {
                p {
                  font-size: 12px;
                }
              }
            }
            .m {
              flex: 1;
            }
            .tableListWrapper {
              max-height: max-content;
              height: auto;
            }
          }
        }
      }
      .headerList {
        justify-content: flex-start;
      }

      .exportRecapFilters {
        .recapTitle {
          margin-bottom: 4px;
        }
        .recapFilterWrapper {
          align-items: stretch;
          .leftAsideWrapper {
            height: auto;
          }
          .rightAsideWrapper {
            width: 100%;
            min-height: auto;
            .recapFiltersList {
              padding: 0;
              flex-wrap: wrap;
              .recapFilterItem {
                padding: 4px 4px 4px 0;
                &:first-child {
                  padding-left: 4px;
                }
                .filterInfo {
                  padding: 8px;
                }
                .closeBtn {
                  display: none;
                }
              }
            }
          }
        }
      }

      .legendPalmaresPDF {
        width: -webkit-fill-available;
        height: auto;
        /* position: absolute;
        bottom: 0; */
        padding: 0 16px;
        .legendPalmaresContent {
          height: auto;
          .logoWrapper {
            height: auto;
          }

          .legendInfo {
            height: auto;
            h6 {
              font-size: 10px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
  /* @media print {
    @page {
      size: landscape;
    }
  } */
`;

export class ExportPalmares extends BaseComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledExportPalmares justifyContent="space-between">
        <Palmares />
      </StyledExportPalmares>
    );
  }
}
