import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

const StyledButtonIcon = styled(NanoFlex)`
  height: auto;
  width: auto;
  .buttonWithIconWrapper {
    width: auto;
    height: auto;
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    padding: ${(props) => (props.small ? "4px" : "6px")};
    user-select: none;
    cursor: pointer;
    background-color: ${(props) => (props.yellow ? props.theme.color.colorsPalette.yellow : props.theme.color.colorsPalette.lightGrey)};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};
    .iconBtn {
      img {
        height: ${(props) => (props.small ? "10px" : "18px")};
        opacity: ${(props) => (props.disabled ? ".5" : "1")};
        width: auto;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ButtonIcon = (props) => {
  return (
    <StyledButtonIcon className="buttonWithIcon" yellow={props.yellow} small={props.small} disabled={props.disabled}>
      <NanoFlex className="buttonWithIconWrapper" justifyContent="flex-end" onClick={() => props.clickAction(props.id)}>
        <NanoFlex className="iconBtn">
          <img src={props.src} alt="icon" />
        </NanoFlex>
      </NanoFlex>
    </StyledButtonIcon>
  );
};

// Set default props
ButtonIcon.defaultProps = {
  id: 0,
  type: "submit",
  src: "",
  clickAction: () => null,
};

export default ButtonIcon;
