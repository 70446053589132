import React, { Component } from "react";
//import { SortableTreeWithoutDndContext, ReactSortableTree, removeNodeAtPath } from 'react-sortable-tree';
import ReactSortableTree, { removeNodeAtPath } from "react-sortable-tree";

import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import "react-sortable-tree/style.css";

//Imgs
import DragIcon from "../../../Global/icons/dragIcon.svg";
import { ReactComponent as Delete } from "../../../Global/icons/delete.svg";
import Minus from "../../../Global/icons/minus.svg";
import Plus from "../../../Global/icons/plusWhite.svg";

const StyledSortableTree = styled(NanoFlex)`
  width: auto;
  user-select: none;
  .rst__moveHandle,
  .rst__loadingHandle {
    width: 32px;
    background: ${(props) => props.theme.color.main.font} url(${DragIcon}) no-repeat center;
    background-size: 10px;
    box-shadow: none;
    border: 1px solid ${(props) => props.theme.color.main.font};
    border-radius: 20px 0 0 20px;
    background-position: 12px;
  }

  .rst__rowContents {
    box-shadow: none;
    width: 176px;
    overflow: hidden;
    border-radius: 0 20px 20px 0;
    padding: 0 0 0 8px;
    .rst__rowLabel {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 4px;
      .rst__rowTitle {
        color: ${(props) => props.theme.color.main.font};
        font-size: 14px;
        font-weight: normal;
      }
    }
    .rst__rowToolbar {
      width: 26px;
      height: 100%;
      justify-content: flex-end;
      .rst__toolbarButton {
        .btnDelete {
          cursor: pointer;
          padding: 8px;
          background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
          svg {
            width: 10px;
            height: auto;
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
          &:hover {
            svg {
              path {
                fill: ${(props) => props.theme.color.colorsPalette.red};
              }
            }
          }
        }
      }
    }
  }
  .rst__rowWrapper {
    padding: 6px 6px 6px 0;
  }
  .rst__rowLandingPad::before,
  .rst__rowCancelPad::before {
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    border: 1px dashed ${(props) => props.theme.color.colorsPalette.grey};
    border-radius: 40px;
  }
  .rst__highlightLineVertical::before {
    background-color: ${(props) => props.theme.color.main.font};
  }
  .rst__highlightTopLeftCorner::before {
    border-top: solid 8px ${(props) => props.theme.color.main.font};
    border-left: solid 8px ${(props) => props.theme.color.main.font};
  }
  .rst__highlightBottomLeftCorner::before {
    border-left: solid 8px ${(props) => props.theme.color.main.font};
    border-bottom: solid 8px ${(props) => props.theme.color.main.font};
  }
  .rst__highlightBottomLeftCorner::after {
    border-left: 12px solid ${(props) => props.theme.color.main.font};
    border-radius: 20px;
  }
  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    box-shadow: 0 0 0 1px ${(props) => props.theme.color.main.font}, 0 0 1px 3px ${(props) => props.theme.color.colorsPalette.yellow};
  }

  .rst__collapseButton,
  .rst__expandButton {
    width: 18px;
    height: 18px;
    box-shadow: none;
  }

  .rst__collapseButton {
    background: ${(props) => props.theme.color.main.font} url(${Minus}) no-repeat center;
    background-size: 8px;
    background-position: 5px 8px;
    &:hover:not(:active) {
      background-size: 8px;
      height: 20px;
      width: 20px;
      background-position: 6px 9px;
    }
  }

  .rst__expandButton {
    background: ${(props) => props.theme.color.main.font} url(${Plus}) no-repeat center;
    background-size: 10px;
    &:hover:not(:active) {
      background-size: 10px;
      height: 20px;
      width: 20px;
    }
  }

  .rst__lineHalfHorizontalRight::before,
  .rst__lineFullVertical::after,
  .rst__lineHalfVerticalTop::after,
  .rst__lineHalfVerticalBottom::after {
    background-color: ${(props) => props.theme.color.main.font};
  }
`;

export default class SortableTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTreeItems: this.props.selectedTreeItems ?? [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedTreeItems) !== JSON.stringify(this.props.selectedTreeItems)) {
      this.setState({ selectedTreeItems: this.props.selectedTreeItems ?? [] });
    }
    if (JSON.stringify(prevState.selectedTreeItems) !== JSON.stringify(this.state.selectedTreeItems) && this.props.onSelectedChange) {
      this.props.onSelectedChange(this.state.selectedTreeItems);
    }
  }

  removeNode = (rowInfo) => {
    let { node, treeIndex, path } = rowInfo;
    this.setState({
      selectedTreeItems: removeNodeAtPath({
        treeData: this.state.selectedTreeItems,
        path: path,
        getNodeKey: ({ node: TreeNode, treeIndex: number }) => {
          return number;
        },
        ignoreCollapsed: false,
      }),
    });
  };

  render() {
    return (
      <StyledSortableTree alignItems="flex-start">
        <ReactSortableTree
          rowHeight={48}
          isVirtualized={false}
          treeData={this.state.selectedTreeItems}
          onChange={(selectedTreeItems) => this.setState({ selectedTreeItems })}
          generateNodeProps={(node) => ({
            buttons: [
              <NanoFlex className="btnDelete" onClick={() => this.removeNode(node)}>
                <Delete />
              </NanoFlex>,
            ],
          })}
        />
      </StyledSortableTree>
    );
  }
}
