import React from "react";
import BaseComponent from "../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../Global/NanoFlex.js";
import administrationService from "../../../services/AdministrationService.js";

//Imgs
import { ReactComponent as GenreIcon } from "../../Global/icons/genre.svg";

//COMPONENTS
import { AdminNavBar } from "./AdminNavBar.js";
import Input from "../../Global/Elements/Forms/Input.js";
import Loading from "../../Global/Elements/Loading/Loading.js";

const StyledGenre = styled(NanoFlex)`
  min-width: 0;
  .genreContent {
    min-width: 0;
    .contentWrapper {
      padding: 32px;
      .genreList {
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
        padding: 32px;

        overflow: auto;
        height: calc(100vh - 130px);
        .listWrapper {
          display: grid;
          gap: 16px;
          grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
          grid-template-rows: auto;
          grid-auto-flow: dense;
          height: auto;
          .itemGenre {
            background-color: ${(props) => props.theme.color.main.white};
            box-shadow: ${(props) => props.theme.boxShadow};
            padding: 32px;
            width: auto;
            height: auto;

            .iconGenre {
              margin-bottom: 16px;
              width: 64px;
              background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
              padding: 16px;
              height: 64px;
              border-radius: 62px;
              svg {
                width: auto;
                height: 48px;
                path {
                  fill: ${(props) => props.theme.color.main.font};
                }
              }
            }
            .formWrapper {
              height: auto;
            }
          }
        }
      }
    }
  }
`;

export class Genre extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      genres: null,
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.getGenres();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getGenres = () => {
    administrationService
      .getGenres()
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Catch
          if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
        }
      })
      .then((data) => {
        this.setState({ genres: data });
      });
  };

  render() {
    return (
      <StyledGenre>
        {this.state.genres === null && <Loading />}
        {this.state.genres && this.state.genres !== null && (
          <NanoFlex className="genreContent" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            <AdminNavBar render={this.props.render} />
            <NanoFlex className="contentWrapper">
              <NanoFlex className="genreList" justifyContent="flex-start" alignItems="flex-start" alignContent="flex-start">
                <NanoFlex className="listWrapper" alignContent="flax-start">
                  {/* ITEM GENRE */}
                  {this.state.genres.map((c) => (
                    <NanoFlex key={`genre_${c.id}`} className="itemGenre" flexDirection="column">
                      <NanoFlex className="iconGenre">
                        <GenreIcon />
                      </NanoFlex>
                      <NanoFlex className="formWrapper">
                        <Input editable title="Genre" placeholder="Placeholder" defaultValue={c.name} />
                      </NanoFlex>
                    </NanoFlex>
                  ))}
                </NanoFlex>
              </NanoFlex>
            </NanoFlex>
          </NanoFlex>
        )}
      </StyledGenre>
    );
  }
}
