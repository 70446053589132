import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import { Popper, Reference, Manager } from "react-popper";

import Type from "../../Typography.js";
import NanoFlex from "../../NanoFlex.js";
import TreeNavigator from "./TreeNavigator.js";
import Checkbox from "./Checkbox.js";
import Input from "./Input.js";

import { ReactComponent as ArrowUp } from "../../icons/arrowUp.svg";
import { ReactComponent as ArrowDown } from "../../icons/arrowDown.svg";
import { ReactComponent as SearchIcon } from "../../icons/search.svg";

const multiple = 8;
//const mainWidth = multiple * 25; /* 200px */

const StyledFetchTreeNavigatorDropdown = styled.div`
  width: 100%;
  /* border: 1px solid ${(props) => props.theme.color.colorsPalette.grey}; */
  font-size: 12px;
  color: ${(props) => props.theme.color.colorsPalette.grey};
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  /* border-radius: 12px; */
  pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  background-color: ${(props) => (props.disabled ? props.theme.color.colorsPalette.lighterGrey : props.theme.color.main.white)};

  .dropDownWrapper {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;

    .title {
      margin-bottom: 4px;
    }
    .inputDropDownWrapper {
      width: 100%;
      height: 36px;
      border: 1px solid;
      border-color: ${(props) => (props.disabled ? props.theme.color.colorsPalette.lightGrey : props.theme.color.main.font)};
      .infoDropDownWrapper {
        width: calc(100% - 24px);
        padding: 0 8px;
        p {
          color: ${(props) => (props.displayString ? props.theme.color.main.font : props.theme.color.colorsPalette.grey)};
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .iconDropDownWrapper {
        width: 24px;
        svg {
          height: 8px;
          width: auto;
          margin-top: 4px;
          path {
            fill: ${(props) => (props.disabled ? props.theme.color.colorsPalette.grey : props.indicator ? props.theme.color.main.green : props.theme.color.main.font)};
          }
        }
      }
    }
  }

  .dropDownLine {
    height: 32px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selectAllLine {
    background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
    min-height: 32px;
  }

  .inputSearchWrapper {
    position: relative;
    min-height: -webkit-fit-content;
    div {
      margin: 0;
      width: 100%;
      input {
        border: 0;
        border-bottom: 1px solid ${(props) => props.theme.color.colorsPalette.lightGrey};
        padding: ${multiple + "px"};
        padding-right: 40px;
        border-radius: 0;
      }
    }
    .searchIconWrapper {
      position: absolute;
      height: auto;
      right: ${multiple + "px"};
      width: ${multiple * 3 + "px"};
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }
    }
  }
  .noResults {
    padding: ${multiple + "px"};
  }

  .dropDownContentWrapper {
    margin: 0;
    border: 1px solid ${(props) => props.theme.color.main.font};
    width: 100%;
    height: auto;
    max-height: 304px;
    overflow: auto;
    overflow-x: clip;
    background: ${(props) => props.theme.color.main.white};
    z-index: 1;
    margin-top: -1px;
    margin-bottom: -1px;
    .minFetchLengthInfo {
      padding: 8px;
      h6 {
        color: ${(props) => props.theme.color.colorsPalette.grey};
      }
    }
  }
`;

class FetchTreeNavigatorDropdown extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      options: [],
      selected: this.props.selected ? this.props.selected : [],
      tempSelected: [],
      isOpenDropdown: false,
      openAllTrees: true,
      minFetchLength: this.props.minFetchLength ?? 0,
    };

    this.nodeRef = React.createRef();
    this.requestId = 1;
  }

  componentDidMount() {
    super.componentDidMount();
    document.addEventListener("mousedown", this.handleOutsideClick);
    /*Get Options*/
    this.getOptions(() => {
      /*Prevent different IsAllSelected States from Props to State */
      this.validateIsAllSelectedProp();
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(this.state.selected.sort()) !== JSON.stringify(prevState.selected.sort()) || JSON.stringify(this.state.tempSelected.sort()) !== JSON.stringify(prevState.tempSelected.sort())) {
      this.triggerOnChangeSelection();
    }

    if ((JSON.stringify(prevProps.selected.sort()) !== JSON.stringify(this.props.selected.sort()) && JSON.stringify(this.props.selected.sort()) !== JSON.stringify(this.getCombinedSelected().sort())) || this.props.forceSelection) {
      this.setState({ selected: this.props.selected ? this.props.selected : [] }, () => {
        /*Remove Term if Value changed and Dropdown is closed*/
        if (!this.state.isOpenDropdown) {
          this.onTermChange("");
        }
      });
      this.validateIsAllSelectedProp();
    }

    if (prevProps.minFetchLength !== this.props.minFetchLength) {
      this.setState({ minFetchLength: this.props.minFetchLength ?? 0 });
    }

    if (prevState.term !== this.state.term) {
      this.requestId++;
      var requestId = this.requestId;
      setTimeout(() => {
        if (requestId === this.requestId) {
          this.moveTempSelected();
          this.getOptions();
        }
      }, 250);
    }

    if (prevState.isOpenDropdown !== this.state.isOpenDropdown && this.state.isOpenDropdown === false) {
      this.onTermChange("");
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    document.removeEventListener("mousedown", this.handleOutsideClick);
  }

  getOptions = (callback) => {
    if (this.props.fetch) {
      if (this.state.term.length >= this.state.minFetchLength) {
        this.props
          .fetch({ ...this.props.fetchParams, term: this.state.term })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              // Catch
              if (response.status === 403 || response.status === 401) window.dispatchEvent(new Event("sendToLogin"));
            }
          })
          .then((data) => {
            this.setState({ options: data /*defaultData*/ }, callback);
          });
      } else {
        this.setState({ options: [] }, callback);
      }
    }
  };

  getCombinedSelected = (obj) => {
    return [...(this.state ?? obj).selected, ...(this.state ?? obj).tempSelected];
  };

  handleOutsideClick = (e) => {
    if (!this.nodeRef.current.contains(e.target)) this.setState({ isOpenDropdown: false });
  };

  validateIsAllSelectedProp = () => {
    if (this.props.isAllSelected === true) {
      this.setState({
        selected: this.state.options.map((v) => {
          return v.id;
        }),
      });
    }
  };

  triggerOnChangeSelection = () => {
    if (this.props.onChangeSelection) {
      this.props.onChangeSelection(this.getCombinedSelected());
    }
  };

  getDisplayString = () => {
    /*Add Custom Display String*/
    if (this.props.customDisplayString) {
      var event = {
        handled: false,
        selected: [...this.state.selected],
        displayString: null,
      };
      this.props.customDisplayString(event);
      if (event.handled) return event.displayString;
    }
    if (this.state.selected.length === 0) return "";

    let selectedJoin = this.state.selected
      .map((v) => {
        return v.name;
      })
      .join(", ");

    if (selectedJoin.length <= 30) return selectedJoin;

    return `${this.state.selected.length} ${this.state.selected.length > 1 ? this.translation.filters.multipleSelected : this.translation.filters.selected}`;
  };

  onTermChange = (value) => {
    this.setState({ term: value });
  };

  isAllSelected = (results) => {
    let resultsIds = results.map((v) => v.id);
    let filteredSelected = this.state.selected.filter((v) => {
      return resultsIds.includes(v.id);
    });
    return filteredSelected.length === results.length;
  };

  onSelectAllClick = () => {
    this.setState({ selected: [] });
  };

  onOptionClick = (v) => {
    let selectedIds = this.state.selected.map((s) => s.id);
    if (selectedIds.includes(v.id)) {
      this.setState({
        selected: this.state.selected.filter((item) => {
          return item.id !== v.id;
        }),
      });
    } else {
      this.setState({ selected: [...this.state.selected, ...[v]] });
    }
  };

  getTempFilteredResults = () => {
    let options = [];
    if (this.state.term.trim() !== "") {
      let upperTerm = this.state.term.trim().toUpperCase();
      options = this.state.options.filter((v) => {
        let upperName = v.name.trim().toUpperCase();
        return upperName.includes(upperTerm);
      });
    } else {
      options = this.state.options;
    }

    let selectedIds = this.state.selected.map((s) => s.id);
    return options.filter((f) => !selectedIds.includes(f.id));
  };

  isTempAllSelected = (results) => {
    let resultsIds = results.map((v) => v.id);
    let filteredSelected = this.state.tempSelected.filter((v) => {
      return resultsIds.includes(v.id);
    });
    return filteredSelected.length === results.length;
  };

  onTempSelectAllClick = () => {
    /*Get Filtered Results and ids */
    let results = this.getTempFilteredResults();
    let resultsIds = results.map((v) => v.id);
    /*Validate if is All Selected */
    if (this.isTempAllSelected(results)) {
      /*Get Ids Excluding current filtered results ids */
      let excludedSelected = this.state.selected.filter((v) => {
        return resultsIds.includes(v.id) === false;
      });
      this.setState({ tempSelected: excludedSelected });
    } else {
      /*Merge filtered results ids and state selected ids and Distinct */
      //   let selected = [...new Set([...results, ...this.state.selectedIds])];
      this.setState({
        tempSelected: results,
      });
    }
  };

  onTempOptionClick = (v) => {
    let selectedIds = this.state.tempSelected.map((s) => s.id);
    if (selectedIds.includes(v.id)) {
      this.setState({
        tempSelected: this.state.tempSelected.filter((item) => {
          return item.id !== v.id;
        }),
      });
    } else {
      this.setState({ tempSelected: [...this.state.tempSelected, ...[v]] });
    }
  };

  moveTempSelected = () => {
    if (this.state.tempSelected.length > 0) this.setState({ tempSelected: [], selected: [...this.state.selected, ...this.state.tempSelected] });
  };

  render() {
    let displayString = this.getDisplayString();
    let tempFilteredResults = this.getTempFilteredResults();
    return (
      <StyledFetchTreeNavigatorDropdown ref={this.nodeRef} displayString={displayString} indicator={this.state.selected.length > 0} disabled={this.props.disabled}>
        {this.state.options && (
          <NanoFlex className="dropDownWrapper" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
            {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}

            <Manager>
              <Reference>
                {(targetProps) => {
                  return (
                    <NanoFlex
                      ref={targetProps.ref}
                      className="inputDropDownWrapper"
                      justifyContent={"flex-start"}
                      alignItems={"flex-start"}
                      onClick={() => {
                        this.setState({ isOpenDropdown: !this.state.isOpenDropdown });
                      }}>
                      <NanoFlex className="infoDropDownWrapper" justifyContent={"flex-start"}>
                        <Type.p>{displayString ? displayString : this.translation.filters.selectOption}</Type.p>
                      </NanoFlex>
                      <NanoFlex className="iconDropDownWrapper">{this.state.isOpenDropdown ? <ArrowUp /> : <ArrowDown className="svgMargin" />}</NanoFlex>
                    </NanoFlex>
                  );
                }}
              </Reference>
              {this.state.isOpenDropdown && (
                <Popper placement={"bottom"}>
                  {({ ref, style, placement, arrowProps }) => (
                    <NanoFlex ref={ref} style={style} data-placement={placement} className="dropDownContentWrapper" flexDirection={"column"} justifyContent="flex-start" alignItems="flex-start">
                      <NanoFlex className="inputSearchWrapper">
                        <Input title={null} defaultValue={this.state.term} placeholder={this.translation.global.search + "..."} onChangeAction={this.onTermChange} />
                        <NanoFlex className="searchIconWrapper">
                          <SearchIcon />
                        </NanoFlex>
                      </NanoFlex>
                      <NanoFlex className="minFetchLengthInfo">
                        <Type.h6>{this.translation.filters.minFetchLength}</Type.h6>
                      </NanoFlex>

                      {this.state.selected.length > 0 && (
                        <TreeNavigator
                          indicator={true}
                          isOpen={this.state.openAllTrees}
                          main={
                            <NanoFlex className="dropDownLine" justifyContent="flex-start">
                              <Checkbox check toggle={true} label={this.translation.filters.selected} labelSize={"h4"} onChangeAction={this.onSelectAllClick} />
                            </NanoFlex>
                          }
                          content={
                            <div>
                              {this.state.selected.map((item) => {
                                return (
                                  <NanoFlex key={`Item_${item.id ?? (Math.random() + 1).toString(36).substring(7)}`} className="dropDownLine" justifyContent="flex-start" alignItems="flex-start">
                                    <Checkbox
                                      check
                                      toggle={this.state.selected ? this.state.selected.map((s) => s.id).includes(item.id) : false}
                                      label={item.name}
                                      labelSize={"h4"}
                                      onChangeAction={() => {
                                        this.onOptionClick(item);
                                      }}
                                    />
                                  </NanoFlex>
                                );
                              })}
                            </div>
                          }
                        />
                      )}

                      {this.state.term?.trim() !== "" && this.state.term?.trim()?.length >= this.state.minFetchLength && (
                        <NanoFlex className="resultsContainer" flexDirection="column" justifyContent="flex-start">
                          {tempFilteredResults.length > 0 && (
                            <NanoFlex className="dropDownLine" justifyContent="flex-start">
                              <Checkbox check toggle={tempFilteredResults.length > 0 ? this.isTempAllSelected(tempFilteredResults) : false} label={this.translation.filters.selectAll} labelSize={"h4"} onChangeAction={tempFilteredResults.length > 0 ? this.onTempSelectAllClick : () => {}} />
                            </NanoFlex>
                          )}
                          {tempFilteredResults.length > 0 &&
                            tempFilteredResults.map((item) => {
                              return (
                                <NanoFlex key={`Item_${item.id}`} className="dropDownLine" justifyContent="flex-start" alignItems="flex-start">
                                  <Checkbox
                                    check
                                    toggle={this.state.tempSelected ? this.state.tempSelected.map((s) => s.id).includes(item.id) : false}
                                    label={item.name}
                                    labelSize={"h4"}
                                    onChangeAction={() => {
                                      this.onTempOptionClick(item);
                                    }}
                                  />
                                </NanoFlex>
                              );
                            })}
                          {tempFilteredResults.length === 0 && (
                            <NanoFlex className="noResults">
                              <Type.p>{this.translation.global.noResults}</Type.p>
                            </NanoFlex>
                          )}
                        </NanoFlex>
                      )}
                      {/* <TreeNavigator
                        indicator={this.state.tempSelected.length > 0 ? true : false}
                        isOpen={this.state.openAllTrees}
                        main={
                          <NanoFlex className="dropDownLine" justifyContent="flex-start">
                            <Checkbox check toggle={tempFilteredResults.length > 0 ? this.isTempAllSelected(tempFilteredResults) : false} label={this.translation.filters.results} labelSize={"h4"} onChangeAction={tempFilteredResults.length > 0 ? this.onTempSelectAllClick : () => {}} />
                          </NanoFlex>
                        }
                        content={
                          <div>
                            {tempFilteredResults.length > 0 &&
                              tempFilteredResults.map((item) => {
                                return (
                                  <NanoFlex key={`Item_${item.id}`} className="dropDownLine" justifyContent="flex-start" alignItems="flex-start">
                                    <Checkbox
                                      check
                                      toggle={this.state.tempSelected ? this.state.tempSelected.map((s) => s.id).includes(item.id) : false}
                                      label={item.name}
                                      labelSize={"h4"}
                                      onChangeAction={() => {
                                        this.onTempOptionClick(item);
                                      }}
                                    />
                                  </NanoFlex>
                                );
                              })}
                            {tempFilteredResults.length === 0 && (
                              <NanoFlex className="noResults">
                                <Type.p>{this.translation.global.noResults}</Type.p>
                              </NanoFlex>
                            )}
                          </div>
                        }
                      /> */}
                    </NanoFlex>
                  )}
                </Popper>
              )}
            </Manager>
          </NanoFlex>
        )}
      
      </StyledFetchTreeNavigatorDropdown>
    );
  }
}

// Set default props
FetchTreeNavigatorDropdown.defaultProps = {
  options: null,
};

export default FetchTreeNavigatorDropdown;
