import React from "react";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";
import Type from "../../Typography.js";

const StyledButtonWithIcon = styled(NanoFlex)`
  height: auto;
  width: auto;
  .buttonWithIconWrapper {
    width: auto;
    height: auto;
    cursor: pointer;
    padding: 8px;
    user-select: none;
    font-family: ${(props) => props.theme.font};
    cursor: pointer;
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    .titleBtn {
      p {
        color: ${(props) => props.theme.color.main.font};
      }
    }
    .iconBtn {
      margin-left: 8px;
      img {
        height: 14px;
        width: auto;
      }
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;

const ButtonWithIcon = (props) => {
  return (
    <StyledButtonWithIcon className="buttonWithIcon">
      <NanoFlex className="buttonWithIconWrapper" justifyContent="flex-end" onClick={() => props.clickAction(props.id)}>
        <NanoFlex className="titleBtn">
          <Type.p>{props.label}</Type.p>
        </NanoFlex>
        <NanoFlex className="iconBtn">
          <img src={props.src} alt="icon" />
        </NanoFlex>
      </NanoFlex>
    </StyledButtonWithIcon>
  );
};

// Set default props
ButtonWithIcon.defaultProps = {
  id: 0,
  label: "",
  type: "submit",
  src: "",
  clickAction: () => null,
};

export default ButtonWithIcon;

/*
  Doc
  import ButtonCTA from './Buttons/ButtonCTA.js';
  
  <ButtonCTA label="Contacte-nos"/>

  */
