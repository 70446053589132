import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "../../NanoFlex.js";

//COMPONENTS
import Input from "./Input.js";
import ButtonIcon from "./../Buttons/ButtonIcon.js";

//Imgs
import Search from "../../icons/search.svg";

const StyledSearchBar = styled(NanoFlex)`
  width: auto;
  .inputSearchWrapper {
    .inputContainer {
      min-width: calc(240px - 35px);
    }
  }
  .searchBtn {
    .buttonWithIcon {
      .buttonWithIconWrapper {
        height: 35px;
        width: 35px;
        border: 1px solid ${(props) => props.theme.color.main.font};
        border-left: 0;
        cursor: default;
      }
    }
  }
`;

export class SearchBar extends BaseComponent {
  
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }
  render() {
    return (
      <StyledSearchBar>
        <NanoFlex className="inputSearchWrapper">
          <Input placeholder={this.translation.global.search} defaultValue={this.props.term} onChangeAction={this.props.onTermChange} />
        </NanoFlex>
        <NanoFlex className="searchBtn">
          <ButtonIcon src={Search} />
        </NanoFlex>
      </StyledSearchBar>
    );
  }
}
