import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import BaseComponent from "../../BaseComponent.js";
import NanoFlex from "../../Global/NanoFlex.js";
import Type from "../../Global/Typography.js";

//IMGS
import { ReactComponent as ChainesTv } from "../../Global/icons/chainesTv.svg";
import { ReactComponent as ProgrammesTv } from "../../Global/icons/programmesTv.svg";
import { ReactComponent as ReglesDemission } from "../../Global/icons/reglesDemission.svg";
import { ReactComponent as Genre } from "../../Global/icons/genre.svg";
import { ReactComponent as RapportsPreDefinis } from "../../Global/icons/rapportsPreDefinis.svg";
import { ReactComponent as Utilisateurs } from "../../Global/icons/utilisateurs.svg";
import { ReactComponent as Words } from "../../Global/icons/words.svg";

const StyledAdminNavBar = styled(NanoFlex)`
  min-width: 0;
  height: auto;
  user-select: none;
  .adminNavBarContent {
    background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
    padding: 16px;
    .itemAdminNav {
      text-decoration: none;
      margin-right: 8px;
      padding: 8px;
      /* width: 100%; */
      width: auto;
      .item {
        .iconWrapper {
          width: auto;
          margin-right: 8px;
          svg {
            width: 14px;
            height: auto;
            path {
              fill: ${(props) => props.theme.color.main.font};
            }
          }
        }
        .itemTitle {
          width: auto;
          h6 {
            text-transform: uppercase;
            line-height: 13px;
          }
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      }
    }
    .selectedItem {
      background-color: ${(props) => props.theme.color.main.white};
      pointer-events: none;
      border-bottom: 2px solid ${(props) => props.theme.color.main.green};
      .item {
        .iconWrapper {
          svg {
            path {
              fill: ${(props) => props.theme.color.main.green};
            }
          }
        }
      }
    }
  }
`;

export class AdminNavBar extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    return (
      <StyledAdminNavBar justifyContent="flex-start">
        <NanoFlex className="adminNavBarContent">
          {/* ITEM - CHAINES */}
          {this._user.IsAdmin && (
            <NavLink exact to="/admin" className={`itemAdminNav ${this.props.render.match.path === "/admin/chaines" ? "selectedItem" : ""}`} activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <ChainesTv />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.channelsTv}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
          {/* ITEM - PROGRAMMES TV */}
          {this._user.IsAdmin && (
            <NavLink to="/admin/programmes" className="itemAdminNav" activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <ProgrammesTv />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.programmesTv}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
          {/* ITEM - RÈGLES D'ÉMISSION */}
          {this._user.IsAdmin && (
            <NavLink to="/admin/regles" className="itemAdminNav" activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <ReglesDemission />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.emissionRules}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
          {/* ITEM - GENRE */}
          {this._user.IsAdmin && (
            <NavLink to="/admin/genre" className="itemAdminNav" activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <Genre />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.type}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
          {/* ITEM - RAPPORTS PRÉ DÉFINIS */}
          <NavLink to="/admin/reports" className="itemAdminNav" activeClassName="selectedItem">
            <NanoFlex className="item">
              <NanoFlex className="iconWrapper">
                <RapportsPreDefinis />
              </NanoFlex>
              <NanoFlex className="itemTitle">
                <Type.h6>{this.translation.admin.navbar.preDefineReports}</Type.h6>
              </NanoFlex>
            </NanoFlex>
          </NavLink>
          {/* ITEM - UTILISATEURS */}
          {this._user.IsAdmin && (
            <NavLink to="/admin/users" className="itemAdminNav" activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <Utilisateurs />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.users}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
          {/* ITEM - WORDCLOUD */}
          {this._user.IsAdmin && (
            <NavLink to="/admin/wordcloud" className="itemAdminNav" activeClassName="selectedItem">
              <NanoFlex className="item">
                <NanoFlex className="iconWrapper">
                  <Words />
                </NanoFlex>
                <NanoFlex className="itemTitle">
                  <Type.h6>{this.translation.admin.navbar.wordcloud}</Type.h6>
                </NanoFlex>
              </NanoFlex>
            </NavLink>
          )}
        </NanoFlex>
      </StyledAdminNavBar>
    );
  }
}
