import React from "react";
import { Component } from "react";
import styled from "styled-components";
import Type from "../../Typography.js";
import NanoFlex from "../../NanoFlex.js";

//COMPONENTS
import InputIcon from "./InputIcon.js";

const StyledInputNumberRange = styled(NanoFlex)`
  p {
    margin-bottom: 4px;
  }
  .inputsWrapper {
    .inputNumberWrapper {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

class InputNumberRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: props.start,
      end: props.end,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.start !== this.props.start) {
      this.setState({ start: this.props.start });
    }
    if (prevProps.end !== this.props.end) {
      this.setState({ end: this.props.end });
    }

    if (this.state.start !== prevState.start || this.state.end !== prevState.end) {
      if (this.props.onChange) {
        this.props.onChange({ start: this.state.start, end: this.state.end });
      }
    }
  }

  updateStartValue = (newValue) => {
    if (newValue !== null) {
      let start = newValue;
      let end = this.state.end;

      if (start > end && end) {
        this.setState({ start: newValue, end: newValue });
      } else {
        if (newValue !== this.state.start) {
          this.setState({ start: newValue });
        }
      }
    } else {
      this.setState({ start: newValue });
    }
  };

  updateEndValue = (newValue) => {
    if (newValue !== null) {
      let start = this.state.start;
      let end = newValue;

      if (start > end && start) {
        this.setState({ start: newValue, end: newValue });
      } else {
        if (newValue !== this.state.end) {
          this.setState({ end: newValue });
        }
      }
    } else {
      this.setState({ end: newValue });
    }
  };

  render() {
    return (
      <StyledInputNumberRange className="inputNumberRange" flexDirection="column" alignItems="flex-start">
        {this.props.title && <Type.p className="title">{this.props.title}</Type.p>}
        <NanoFlex className="inputsWrapper">
          <NanoFlex className="inputNumberWrapper">
            <InputIcon placeholder="0" defaultValue={this.state.start} onBlurAction={(newValue) => this.updateStartValue(newValue)} maxLength={3} />
          </NanoFlex>
          <NanoFlex className="inputNumberWrapper">
            <InputIcon less placeholder="0" defaultValue={this.state.end} onBlurAction={(newValue) => this.updateEndValue(newValue)} maxLength={3} />
          </NanoFlex>
        </NanoFlex>
      </StyledInputNumberRange>
    );
  }
}

export default InputNumberRange;
