/* eslint-disable react/jsx-pascal-case */
import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import ReactWordcloud from "react-wordcloud";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";

// COMPONENTS
import Type from "../../../Global/Typography.js";
import Loading from "../../../Global/Elements/Loading/Loading.js";

//IMGS
import { ReactComponent as Close } from "../../../Global/icons/close.svg";
import { ReactComponent as CheckIcon } from "../../../Global/icons/check.svg";

const StyledWordCloudBlock = styled(NanoFlex)`
  min-width: 0;
  margin-bottom: 16px;
  max-height: 248px;
  .wordcloudContent {
    .wordsWrapper {
      width: 240px;
      min-width: 240px;
      max-height: 248px;
      margin-right: 8px;
      border: 1px solid ${(props) => props.theme.color.main.font};
      height: auto;
      background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
      overflow: auto;
      padding: 8px;
      .wordsList {
        height: auto;
        .wordItem {
          height: auto;
          background-color: ${(props) => props.theme.color.main.white};
          margin-bottom: 4px;
          user-select: none;
          cursor: pointer;
          .wordWrapper {
            width: calc(100% - 28px);
            .valueWrapper {
              width: auto;
              width: 48px;
              background-color: ${(props) => props.theme.color.gradientGreen.green05};
              padding: 4px;
              margin-right: 8px;
              h6 {
              }
            }
            .word {
              width: calc(100% - 56px);
              p {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
          .closeIcon {
            padding: 4px;
            margin-left: 8px;
            svg {
              width: 8px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              opacity: 0.6;
            }
          }
          .checkIcon {
            display: none;
            padding: 4px;
            margin-left: 8px;
            svg {
              width: 10px;
              height: auto;
              path {
                fill: ${(props) => props.theme.color.main.font};
              }
            }
            &:hover {
              opacity: 0.6;
            }
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
        .selectedItem {
          opacity: 0.5;
          .closeIcon {
            display: none;
          }
          .checkIcon {
            display: flex;
          }
        }
      }
      .noSelectedWords {
        padding: 0 32px;
        text-align: center;
        p {
          text-transform: uppercase;
          color: ${(props) => props.theme.color.colorsPalette.grey};
        }
      }
    }
    .wordCloudWrapper {
      width: calc(100% - 240px);
    }
  }
`;

export class WordCloudBlock extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  render() {
    let showNoResults = !this.props.data || this.props.data?.length === 0;
    let wordsData = this.props.data?.length > 0 ? [...this.props.wordsSelected, ...this.props.data].sort((a,b) => (a.value > b.value) ? -1 : ((b.value > a.value) ? 1 : 0)) : [];

    return (
      <StyledWordCloudBlock alignItems="flex-start">
        <NanoFlex className="wordcloudContent" justifyContent="flex-start" alignItems="stretch" alignContent="stretch">
          {this.props.loading && (
            <NanoFlex className="verbatimWrapper">
              <Loading />
            </NanoFlex>
          )}
          {!this.props.loading && !showNoResults && (
            <NanoFlex className="wordsWrapper" justifyContent="flex-start" alignItems="flex-start">
              {this.props.data?.length > 0 && (
                <NanoFlex className="wordsList" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
                  {/* WORD ITEM */}
                  {wordsData.map((w) => (
                    <NanoFlex
                      key={"wordcloud_item_" + w.value + w.text}
                      className={this.props.wordsSelected?.length > 0 && this.props.wordsSelected.filter((f) => f.text === w.text).length > 0 ? "wordItem selectedItem" : "wordItem"}
                      justifyContent="space-between"
                      onClick={() => this.props.onWordsSelectedChange(this.props.wordsSelected.filter((f) => f.text === w.text).length > 0 ? this.props.wordsSelected.filter((f) => f.text !== w.text) : [{ text: w.text, value: w.value }, ...this.props.wordsSelected])}>
                      <NanoFlex className="wordWrapper" justifyContent="flex-start">
                        <NanoFlex className="valueWrapper">
                          <Type.h6>
                            <b>{this.props.wordsSelected?.length > 0 && this.props.wordsSelected.filter((f) => f.text === w.text).length > 0 ? 0 : w.value}</b>
                          </Type.h6>
                        </NanoFlex>
                        <NanoFlex className="word">
                          <Type.p>{w.text}</Type.p>
                        </NanoFlex>
                      </NanoFlex>
                      <NanoFlex className="closeIcon" justifyContent="flex-end">
                        <Close />
                      </NanoFlex>
                      <NanoFlex className="checkIcon" justifyContent="flex-end">
                        <CheckIcon />
                      </NanoFlex>
                    </NanoFlex>
                  ))}
                </NanoFlex>
              )}
            </NanoFlex>
          )}
          {!this.props.loading && !showNoResults && (
            <NanoFlex className="wordCloudWrapper">
              <ReactWordcloud
                words={this.props.data}
                options={{
                  fontFamily: "'Roboto', sans-serif",
                  colors: this.props.questionSelected === 1 ? ["#444444", "#58a291", "#99D7B6", "#93D04D", "#58A27D"] : ["#920000", "#E71616", "#FF554A", "#FF8A00", "#FFC634"],
                  fontSizes: [14, 60],
                  fontStyle: "normal",
                  fontWeight: "bold",
                  padding: 1,
                  rotations: 1,
                  rotationAngles: [0],
                  scale: "log",
                  transitionDuration: 1000,
                }}
                // callbacks={{
                //   onWordClick: (e) => this.props.onWordsSelectedChange([{ text: e.text, value: e.value }, ...this.props.wordsSelected]),
                // }}
              />
            </NanoFlex>
          )}
        </NanoFlex>
      </StyledWordCloudBlock>
    );
  }
}
