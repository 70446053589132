/* eslint-disable react/jsx-pascal-case */
import React from "react";
import styled from "styled-components";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import BaseComponent from "./../../../BaseComponent.js";

//IMGS
import { ReactComponent as Trophy } from "../../icons/trophy.svg";

const StyledTopButton = styled(NanoFlex)`
  height: auto;
  width: auto;
  .topButtonWrapper {
    background-color: ${(props) => props.theme.color.colorsPalette.yellow};
    padding: 4px 4px 4px 8px;
    border-radius: 60px;
    pointer-events: ${(props) => (props.disabled ? "none" : "unset")};
    opacity: ${(props) => (props.disabled ? "0.4" : "1")};

    .titleButtonWrapper {
      .iconWrapper {
        width: auto;
        margin-right: 4px;
        svg {
          width: 14px;
          height: auto;
        }
      }
      .titleWrapper {
        p {
          line-height: initial;
          text-transform: uppercase;
        }
      }
    }
    .listOptions {
      .optionBtn {
        margin-left: 8px;
        cursor: pointer;
        border-radius: 60px;
        width: 22px;
        height: 22px;
        background-color: rgba(255, 255, 255, 0.3);
        h6 {
          line-height: 12px;
        }
        &:hover {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }
      .selectedOption {
        background-color: ${(props) => props.theme.color.main.white};
        pointer-events: ${(props) => (props.allowNull ? "unset" : "none")};
        h6 {
          font-weight: bold;
        }
        &:hover {
          background-color: ${(props) => (props.allowNull ? props.theme.color.main.white : "rgba(255, 255, 255, 0.6)")};
        }
      }
    }
  }
`;

export default class TopButton extends BaseComponent {
  componentDidMount() {
    super.componentDidMount();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onOptionClick = (v) => {
    if (this.props.allowNull && this.props.selectedValue === v) {
      this.props.onSelectedValueChange(null);
    } else {
      this.props.onSelectedValueChange(v);
    }
  };

  render() {
    return (
      <StyledTopButton flexDirection="column" onClick={this.props.clickAction} allowNull={this.props.allowNull} disabled={this.props.disabled}>
        <NanoFlex className="topButtonWrapper">
          <NanoFlex className="titleButtonWrapper">
            <NanoFlex className="iconWrapper">
              <Trophy />
            </NanoFlex>
            <NanoFlex className="titleWrapper">
              <Type.p>
                <b>Top</b>
              </Type.p>
            </NanoFlex>
          </NanoFlex>
          <NanoFlex className="listOptions ">
            {this.props.values.map((v) => (
              <NanoFlex key={v} className={`optionBtn ${this.props.selectedValue === v ? "selectedOption" : ""}`} onClick={() => this.onOptionClick(v)}>
                <Type.h6>{v}</Type.h6>
              </NanoFlex>
            ))}
          </NanoFlex>
        </NanoFlex>
      </StyledTopButton>
    );
  }
}
