import React from "react";
import styled from "styled-components";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "../../../Global/NanoFlex.js";
import Type from "../../../Global/Typography.js";
import Settings from "../../../Global/Settings.json";
import moment from "moment";

//COMPONENTS
import MultipleDatePicker from "./MultipleDatePicker.js";
import CalendarRange from "./CalendarRange.js";
import MultipleSelectionDropdown from "./MultipleSelectionDropdown.js";

//IMGS
import { ReactComponent as CalendarDaysIcon } from "../../../Global/icons/calendarDays.svg";
import { ReactComponent as CalendarRangeIcon } from "../../../Global/icons/calendarRange.svg";
import { ReactComponent as CalendarWeekDaysIcon } from "../../../Global/icons/calendarWeek.svg";

const StyledDateFormat = styled(NanoFlex)`
  background-color: ${(props) => props.theme.color.colorsPalette.lighterGrey};
  padding: 8px;
  .dateFormatSelectWrapper {
    margin-bottom: 8px;
    .selectBtnWrapper {
      padding: 12px 4px;
      background-color: ${(props) => props.theme.color.colorsPalette.lightGrey};
      cursor: pointer;
      margin-right: 8px;
      /* border-radius: 12px; */
      .iconWrapper {
        svg {
          height: 24px;
          width: auto;
          rect {
            fill: ${(props) => props.theme.color.colorsPalette.grey};
          }
          .iconHightligt {
            fill: ${(props) => props.theme.color.main.green};
          }
        }
      }
      .labelWrapper {
        margin-top: 8px;
        h6 {
          text-align: center;
          text-transform: uppercase;
          font-size: 11px;
          line-height: 12px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: ${(props) => props.theme.color.gradientGreen.green03};
      }
    }
    .selectedBtn {
      background-color: ${(props) => props.theme.color.main.green};
      pointer-events: none;
      .iconWrapper {
        svg {
          path {
            fill: ${(props) => props.theme.color.main.white};
          }

          .iconHightligt {
            fill: ${(props) => props.theme.color.main.white};
          }
        }
      }

      .labelWrapper {
        h6 {
          color: ${(props) => props.theme.color.main.white};
        }
      }
    }
  }
  .formDateWrapper {
    .datePickerContainer {
      padding: 0;
    }
  }
`;

export class DateFormat extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      dateOption: this.props.dateOption ?? 0,
      startDate: this.props.startDate ?? null,
      endDate: this.props.endDate ?? null,
      weekdays: this.props.weekdays ?? [],
      selectedDates: this.props.selectedDates ?? [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.dateOption !== this.state.dateOption ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      JSON.stringify(prevState.weekdays) !== JSON.stringify(this.state.weekdays) ||
      JSON.stringify(prevState.selectedDates) !== JSON.stringify(this.state.selectedDates)
    ) {
      this.onDateChange();
    }

    if (prevProps.dateOption !== this.props.dateOption && this.state.dateOption !== this.props.dateOption) {
      this.setState({ dateOption: this.props.dateOption ?? 0 });
    }

    if (prevProps.startDate !== this.props.startDate && this.state.startDate !== this.props.startDate) {
      this.setState({ startDate: this.props.startDate ?? null });
    }

    if (prevProps.endDate !== this.props.endDate && this.state.endDate !== this.props.endDate) {
      this.setState({ endDate: this.props.endDate ?? null });
    }

    if (JSON.stringify(prevProps.weekdays) !== JSON.stringify(this.props.weekdays) && JSON.stringify(this.state.weekdays) !== JSON.stringify(this.props.weekdays)) {
      this.setState({ weekdays: this.props.weekdays ?? [] });
    }

    if (JSON.stringify(prevProps.selectedDates) !== JSON.stringify(this.props.selectedDates) && JSON.stringify(this.state.selectedDates) !== JSON.stringify(this.props.selectedDates)) {
      this.setState({ selectedDates: this.props.selectedDates ?? [] });
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  onDateChange = () => {
    if (this.props.onDateChange) {
      let defaultReturn = {
        dateOption: this.state.dateOption,
        startDate: null,
        endDate: null,
        weekdays: [],
        selectedDates: [],
      };
      switch (this.state.dateOption) {
        case 0:
          defaultReturn.startDate = this.state.startDate;
          defaultReturn.endDate = this.state.endDate;
          break;
        case 1:
          defaultReturn.selectedDates = this.state.selectedDates;
          break;
        case 2:
          defaultReturn.startDate = this.state.startDate;
          defaultReturn.endDate = this.state.endDate;
          defaultReturn.weekdays = this.state.weekdays;
          break;
      }
      this.props.onDateChange(defaultReturn);
    }
  };

  render() {
    return (
      <StyledDateFormat className="dateFormat" flexDirection="column">
        <NanoFlex className="dateFormatSelectWrapper">
          {/* BUTTON */}
          <NanoFlex className={`selectBtnWrapper ${this.state.dateOption === 0 ? "selectedBtn" : ""}`} flexDirection="column" onClick={() => this.setState({ dateOption: 0 })}>
            <NanoFlex className="iconWrapper">
              <CalendarRangeIcon />
            </NanoFlex>
            <NanoFlex className="labelWrapper">
              <Type.h6>{this.translation.filters.dateRange}</Type.h6>
            </NanoFlex>
          </NanoFlex>
          {/* BUTTON */}
          <NanoFlex className={`selectBtnWrapper ${this.state.dateOption === 1 ? "selectedBtn" : ""}`} flexDirection="column" onClick={() => this.setState({ dateOption: 1 })}>
            <NanoFlex className="iconWrapper">
              <CalendarDaysIcon />
            </NanoFlex>
            <NanoFlex className="labelWrapper">
              <Type.h6>{this.translation.filters.severalDays}</Type.h6>
            </NanoFlex>
          </NanoFlex>
          {/* BUTTON */}
          <NanoFlex className={`selectBtnWrapper ${this.state.dateOption === 2 ? "selectedBtn" : ""}`} flexDirection="column" onClick={() => this.setState({ dateOption: 2 })}>
            <NanoFlex className="iconWrapper">
              <CalendarWeekDaysIcon />
            </NanoFlex>
            <NanoFlex className="labelWrapper">
              <Type.h6>{this.translation.filters.dayOfWeek}</Type.h6>
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        {/* CALENDAR WEEKDAYS */}
        {(this.state.dateOption === 0 || this.state.dateOption === 2) && (
          <NanoFlex className="formDateWrapper" flexDirection="column">
            <NanoFlex className="formWrapper">
              <CalendarRange
                startDate={this.state.startDate ? moment(this.state.startDate, Settings.moment.serverDate).format(Settings.moment.date) : null}
                endDate={this.state.endDate ? moment(this.state.endDate, Settings.moment.serverDate).format(Settings.moment.date) : null}
                minDate={this.props.minDate}
                maxDate={this.props.maxDate}
                onChangeSelection={(date) => {
                  this.setState({
                    startDate: date?.startDate ? moment(date.startDate, Settings.moment.date).format(Settings.moment.serverDate) : null,
                    endDate: date?.endDate ? moment(date.endDate, Settings.moment.date).format(Settings.moment.serverDate) : null,
                  });
                }}
              />
            </NanoFlex>
            {this.state.dateOption === 2 && (
              <NanoFlex className="formWrapper" flexDirection="column" alignItems="flex-start">
                <Type.p className="titleForm">{this.translation.filters.days}</Type.p>
                <MultipleSelectionDropdown placeholder="Placeholder" options={Settings.filters.weekdays} selected={this.state.weekdays} onChangeSelection={(e) => this.setState({ weekdays: e.selected.map((i) => i.id) })} />
              </NanoFlex>
            )}
          </NanoFlex>
        )}
        {/* CALENDAR DAYS */}
        {this.state.dateOption === 1 && (
          <NanoFlex className="formDateWrapper">
            <MultipleDatePicker selectedDates={this.state.selectedDates} minDate={this.props.minDate} maxDate={this.props.maxDate} onDateChange={(dates) => this.setState({ selectedDates: dates })} />
          </NanoFlex>
        )}
      </StyledDateFormat>
    );
  }
}
