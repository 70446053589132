import React from "react";
import BaseComponent from "../../../BaseComponent.js";
import NanoFlex from "./../../../Global/NanoFlex.js";
import Type from "./../../../Global/Typography.js";
import { flattenByKey } from "./../../../Global/tools.js";

// COMPONENTS
import ButtonIcon from "../../../Global/Elements/Buttons/ButtonIcon.js";
import SortableTree from "../../../Global/Elements/Sortable/SortableTree.js";
import Modal from "../../../Global/Elements/Modals/Modal.js";
import { SelectVariablesModal } from "../../../Global/Elements/Modals/SelectVariablesModal.js";

// IMGS
import Plus from "./../../../Global/icons/plus.svg";
import Clean from "./../../../Global/icons/clean.svg";

export default class PivotConfigurationItem extends BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      openSelectVariables: false,
      selectedTreeItems: this.props.selectedTreeItems ?? [],
    };
  }

  componentDidMount() {
    super.componentDidMount();
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.selectedTreeItems) !== JSON.stringify(this.props.selectedTreeItems)) {
      this.setState({ selectedTreeItems: this.props.selectedTreeItems ?? [] });
    }
    if (JSON.stringify(prevState.selectedTreeItems) !== JSON.stringify(this.state.selectedTreeItems) && this.props.onSelectedChange) {
      this.props.onSelectedChange(this.state.selectedTreeItems);
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount();
  }

  getSelectedVariables = () => {
    return flattenByKey(this.state.selectedTreeItems, "children");
  };

  render() {
    return (
      <NanoFlex className="blockConfigurationWrapper" flexDirection="column" justifyContent="flex-start">
        <Modal
          show={this.state.openSelectVariables}
          content={
            <SelectVariablesModal
              selectedVariables={this.getSelectedVariables()}
              onSelectedChange={(selected) => {
                let selectedTreeItems = JSON.parse(JSON.stringify(this.state.selectedTreeItems));
                let selectedVariables = this.getSelectedVariables();
                let selectedVariablesNames = selectedVariables.map((m) => m.name);
                /*Remove*/
                selectedVariablesNames
                  .filter((s) => !selected.map((se) => se.name).includes(s))
                  .forEach((s) => {
                    selectedTreeItems = selectedTreeItems.filter(function f(o) {
                      if (o.children) o.children = o.children.filter(f);
                      return o.name.includes(s) === false;
                    });
                  });
                /*Add*/
                selected
                  .filter((s) => !selectedVariablesNames.includes(s.name))
                  .forEach((s) => {
                    selectedTreeItems.push({
                      title: s.name,
                      name: s.name,
                      code: s.code,
                      type: s.type,
                      expanded: true,
                      children: [],
                    });
                  });

                this.setState({ selectedTreeItems: selectedTreeItems });
              }}
              cancelAction={() => this.setState({ openSelectVariables: false })}
              nextAction={() => this.setState({ openSelectVariables: false })}
            />
          }
        />
        <NanoFlex className="headerBlock" justifyContent="space-between">
          <NanoFlex className="titleBlock" justifyContent="flex-start">
            <Type.p>
              <b>{this.props.title}</b>
            </Type.p>
          </NanoFlex>
          <NanoFlex className="optionsWrapper" justifyContent="flex-end">
            <NanoFlex className="optionBtnWrapper">
              <ButtonIcon clickAction={() => this.setState({ openSelectVariables: !this.state.openSelectVariables })} yellow src={Plus} />
            </NanoFlex>
            <NanoFlex className="optionBtnWrapper" onClick={() => this.setState({ selectedTreeItems: [] })}>
              <ButtonIcon src={Clean} />
            </NanoFlex>
          </NanoFlex>
        </NanoFlex>
        <NanoFlex className="blockListWrapper" justifyContent="flex-start" alignItems="flex-start">
          <SortableTree
            selectedTreeItems={this.state.selectedTreeItems}
            onSelectedChange={(selected) => {
              /*Remove Sort - Code Behavior can be improved!*/
              if (JSON.stringify(selected) !== JSON.stringify(this.state.selectedTreeItems)) {
                selected.forEach((s) => {
                  s.sortOrientation = null;
                  s.sortPosition = null;
                });
              }
              this.setState({ selectedTreeItems: selected });
            }}
          />
        </NanoFlex>
      </NanoFlex>
    );
  }
}
